export default {
  computed: {
    disableCompany() {
      return this.company === '';
    },
    plans() {
      let products = [];
      this.productSubscriptions.forEach(product => {
        product.plans.forEach(plan => {
          if(plan.interval === 'perpetual' && this.pricingInterval) {
            // Set plan as a top level for that interval
            product.plan = plan;
            products.push(product);
          } else if(plan.interval === 'year' && !this.pricingInterval){
            product.plan = plan;
            products.push(product);
          }
        });
      });
      // Sort by price.
      let sorted = products.sort((a, b) => {
        if (a && b) return a.UnitPrice > b.UnitPrice ? 1 : -1;
      });
      sorted.some((product, index) => {
        if (product.metadata.display_on_website ) {
          return sorted
        }
      });
      return sorted;
    },
    intervalDisplay() {
      if (this.activePlan) {
        if (this.activePlan.plan.interval === 'year') return '/ year';
        return 'one time';
      }
    },
  },
  data() {
    return {
      planSteps: 0,
      
      activeName:0,
      domain: '',
      domainField: [],
      domainObj: {
        newDomain: '',
      },
      selectedPlan: null,
      selectedSubscription: null,
      dialogDomainForm: {
        dialogNewDomain: '',
        dialogNewSubdomain: '',
      },
      dialogNewDomains: [],
      dialogNewSubdomains: [],
      company: '',
      coupon: null,
      updateDomainSubscription: null,
      subscribeLoading: false,
      licensePurchasedDialogVisible: false,
      
      //Billing
      hasCard: false,
    };
  },
  methods: {
    addSubscription(planSku, domains, subdomains, datalayerObj) {
      this.subscribeLoading = true;
      // Add subscription
      this.$api('subscription/add', {
        planSku,
        domains,
        subdomains,
        coupon: this.coupon,
      })
      .then((result) => {
        if (result.status === 202) {
          this.$message({
            message: 'Subscription added, but some domains/subdomains were not registered.',
            showClose: true,
            type: 'warning',
          });
        } else {
          this.$message({
            message: 'Subscription added!',
            showClose: true,
            type: 'success',
          });
        }

        // Push data to datalayer
        datalayerObj['purchase-status'] = 'success';
        datalayerObj['transaction-id'] = result.data.Id;
        window.dataLayer.push(datalayerObj);

        // End process by displaying confirmation page
        this.endSubscriptionProcess(datalayerObj);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          this.$message({
            message: `Subscription added. ${error.response.data}`,
            showClose: true,
            type: 'warning',
          });

          // Push data to datalayer
          datalayerObj['purchase-status'] = 'fail';
          window.dataLayer.push(datalayerObj);

          this.endSubscriptionProcess(datalayerObj);
        } else {
          this.$message({
            duration: 10000,
            message: `Cannot subscribe. ${error.response.data}`,
            showClose: true,
            type: 'error',
          });

          // Push data to datalayer
          datalayerObj['purchase-status'] = 'fail';
          window.dataLayer.push(datalayerObj);
        }
        this.subscribeLoading = false;
        // this.planDialogVisible = false;
      });
    },
    renewSubscription(renewalName, renewalSku, sku, datalayerObj, noDataObject) {
      return new Promise((resolve, reject) => {
        this.subscribeLoading = true;
        // Add subscription
        this.$api('subscription/renew', {
          renewalName,
          renewalSku,
          sku,
        })
        .then((result) => {
          this.$message({
            message: 'License renewed!',
            showClose: true,
            type: 'success',
          });

          // Push data to datalayer
          if (datalayerObj && !noDataObject) {
            datalayerObj['purchase-status'] = 'success';
            datalayerObj['transaction-id'] = result.data.Id;
            window.dataLayer.push(datalayerObj);

            // End process by displaying confirmation page
            this.endSubscriptionProcess(datalayerObj, noDataObject);
          };
          resolve(true);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 400) {
            this.$message({
              message: `License renewed. ${error.response.data}`,
              showClose: true,
              type: 'warning',
            });

            // Push data to datalayer
            if (datalayerObj) {
              datalayerObj['purchase-status'] = 'fail';
              window.dataLayer.push(datalayerObj);

              this.endSubscriptionProcess(datalayerObj, noDataObject);
            } else {
              this.placingOrder = false;
            };
            reject(error);
          } else {
            this.$message({
              duration: 10000,
              message: `Cannot renew. ${error.response.data}`,
              showClose: true,
              type: 'error',
            });

            // Push data to datalayer
            if (datalayerObj) {
              datalayerObj['purchase-status'] = 'fail';
              window.dataLayer.push(datalayerObj);
            } else {
              this.placingOrder = false;
            };
          }
          this.subscribeLoading = false;
          reject(error);
        });
      });
    },
    nextStep1() {
      // Advance to next form
      this.planSteps++;

      // Push data to datalayer
      window.dataLayer.push({
        'event': 'subscriptionCheckout',
        'checkout-step': '1',
        'checkout-name': 'plan',
        'product-name': this.expandProductType(),
        'license-id': this.activePlan.Sku.toUpperCase(),
        'license-interval':  this.pricingInterval ? 'perpetual' : 'yearly',
        'license-type': this.activePlan.name.toLowerCase(),
        'license-price': this.activePlan.plan.UnitPrice,
        'user-id': this.userId,
      });
    },
    nextStep2() {
      // Update user company info (if available) and 
      this.updateCompany();
      
      // Advance to next step
      this.planSteps++;

      // Push data to datalayer
      window.dataLayer.push({
        'event': 'subscriptionCheckout',
        'checkout-step': '2',
        'checkout-name': 'subscription-info',
        'product-name': this.expandProductType(),
        'license-id': this.activePlan.Sku.toUpperCase(),
        'license-interval': this.pricingInterval ? 'perpetual' : 'yearly',
        'license-type': this.activePlan.name.toLowerCase(),
        'license-price': this.activePlan.plan.UnitPrice,
        'license-domains-count': this.dialogNewDomains.length,
        'license-domains-name': JSON.parse(JSON.stringify(this.dialogNewDomains)),
        'license-subdomains-count': this.dialogNewSubdomains.length,
        'license-subdomains-name': JSON.parse(JSON.stringify(this.dialogNewSubdomains)),
        'license-company': this.company,
        'user-id': this.userId,
      });
    },
    nextStep3(sku, planName, domains, subdomains, forcePurchase) {
      let datalayerObj = {
        'event': 'subscriptionCheckout',
        'checkout-step': '3',
        'checkout-name': 'subscribe',
        'product-name': this.expandProductType(),
        'license-id': this.activePlan.Sku.toUpperCase(),
        'license-interval': this.pricingInterval ? 'perpetual' : 'yearly',
        'license-type': this.activePlan.name.toLowerCase(),
        'license-price': this.activePlan.plan.UnitPrice,
        'license-domains-count': this.dialogNewDomains.length,
        'license-domains-name': JSON.parse(JSON.stringify(this.dialogNewDomains)),
        'license-subdomains-count': this.dialogNewSubdomains.length,
        'license-subdomains-name': JSON.parse(JSON.stringify(this.dialogNewSubdomains)),
        'license-company': this.company,
        'card_type': this.cardBrand,
        'purchase-status': null,
        'transaction-id': null,
        'user-id': this.userId,
      };

      if (forcePurchase) {
        // Already did check in "else" case and customer confirm purchase
        this.addSubscription(sku, domains, subdomains, datalayerObj);
      } else {
        // Check if user already subscribed to annual license
        this.$api('subscription/list')
          .then((result) => {
            let subscriptions = (result.data || []).map((subscription) => subscription.planName.split(':').pop());
            let duplicate = (subscriptions.indexOf(planName)) !== -1;
            let isAnnual = planName.endsWith('-A');

            if (duplicate && isAnnual) {
              // Prompt user if they want to purchase or pay renewal
              this.licensePurchasedDialogVisible = true;
            } else {
              // Create new subscription and push data to datalayer
              this.addSubscription(sku, domains, subdomains, datalayerObj);
            };
          });
      };
    },
    renewalStep(sku, planName, noDataObject) {
      return new Promise((resolve, reject) => {
        // Get renewal version of annual license
        let renewalSku = sku.replace('-S-', '-').replace(/-A$/g, '-R');
        let renewalName = planName.replace(/-A$/g, '-R');

        let datalayerObj = noDataObject
          ? null 
          : {
            'event': 'subscriptionCheckout',
            'checkout-step': '3',
            'checkout-name': 'subscribe',
            'product-name': this.expandProductType(),
            'license-id': renewalSku.toUpperCase(),
            'license-interval': 'yearly',
            'license-type': renewalName.toLowerCase(),
            'license-price': this.activePlan.plan.UnitPrice,
            'license-domains-count': this.dialogNewDomains.length,
            'license-domains-name': JSON.parse(JSON.stringify(this.dialogNewDomains)),
            'license-subdomains-count': this.dialogNewSubdomains.length,
            'license-subdomains-name': JSON.parse(JSON.stringify(this.dialogNewSubdomains)),
            'license-company': this.company,
            'card_type': this.cardBrand,
            'purchase-status': null,
            'transaction-id': null,
            'user-id': this.userId,
          };

        this.renewSubscription(renewalName, renewalSku, sku, datalayerObj, noDataObject)
          .then((response) => {
            resolve(response);

          }).catch((error) => {
            reject(error)
          });
      });
    },
    /**
     * @description Ends subscription process by updating user subscription view, increment
     * subscription step, and reset variables
     */
    endSubscriptionProcess(datalayerObj, noDataObject) {
      // Update subscriptions (to display newly purchased)
      this.getUserSubscriptions();
      // Display confirmation page
      if (this.planSteps) this.planSteps++;
      if (this.view) this.view++;
      // Clear flags
      this.coupon = null;
      this.subscribeLoading = false;

      if (!noDataObject) {
        // Push data to datalayer
        let newDatalaterObj = Object.assign({}, datalayerObj, {
          'event': 'subscriptionCheckout',
          'checkout-step': '4',
          'checkout-name': 'finish',
        });
        window.dataLayer.push(newDatalaterObj);
      };
    },
    expandProductType() {
      return this.productType === 'ZC' ? 'zingchart' : 'zinggrid';
    },
    openPlanDialog(type) {
      // Open dialog
      this.planDialogVisible = true;
      // Determine which products to display
      this.productType = type;
      // Set active plan index to highlight first product
      this.activePlanIndex = type === 'ZC' ? 0 : 1;
      // Push data to dataLayer
      window.dataLayer.push({
        'event': 'clickSubscription',
        'checkout-step': '0',
        'product-name': this.expandProductType(),
        'user-id': this.userId,
      });
    },
    updateCard(val) {
      this.cardBrand = val;
    },
    updateCompany() {
     // Update company info (if given)
      if (this.company) {
        this.$api('user/update', {company: this.company})
        .then((res) => {
          this.$store.dispatch('refresh_state');
        })
        .catch((err) => {
          this.$message({
            duration: 10000,
            message: 'Could not update company',
            showClose: true,
            type: 'error',
          });
        });
      };
    },
  }
};