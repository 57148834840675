var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.adminAccess
    ? _c(
        "section",
        { staticClass: "content--wide", attrs: { "account-settings": "" } },
        [
          _c("toggle-grid", {
            attrs: {
              "allow-hide": "",
              caption: "Your Permissions",
              heading: "2",
              "hide-list": "",
              paging: "",
              "set-all": "",
              "request-detail": "/api/feature/get/list",
              "request-set": "/api/feature/set",
              type: "permissions_user",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }