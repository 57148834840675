var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", { attrs: { gridBody: true } }, [
    _c(
      "span",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _vm._v("\n    Gallery Metadata\n    "),
        _c("metadata-selector", {
          attrs: {
            type: "metadata",
            metadataBy: _vm.metadataType,
            "default-value": "chartType",
          },
          on: {
            "update:metadataBy": function ($event) {
              _vm.metadataType = $event
            },
            "update:metadata-by": function ($event) {
              _vm.metadataType = $event
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { attrs: { slot: "header__right" }, slot: "header__right" }, [
      _c(
        "span",
        { staticClass: "input-wrapper" },
        [
          _c("el-input", {
            attrs: { placeholder: "Search", search: "" },
            on: { change: _vm.searchGrid },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "el-input-icon",
            attrs: { icon: "search2", fill: "var(--color-primary-gray)" },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c(
        "section",
        { staticClass: "zingnetCard--row no-border" },
        [
          _c(
            "zing-grid",
            {
              attrs: {
                caption: _vm.gridCaption,
                data: _vm.gridData,
                "editor-controls": "",
                "cell-editor": "disabled",
                filter: "",
                height: "650",
                "metadata-list": "grid",
                selector: "",
                search: "",
                sort: "",
              },
            },
            [
              _c(
                "zg-colgroup",
                [
                  _c("zg-column", {
                    attrs: {
                      index: "metadata_uid",
                      hidden: "",
                      editor: "disabled",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", { attrs: { index: "name" } }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: { hidden: _vm.noContent, index: "content" },
                  }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      index: "demo_type",
                      type: "select",
                      "type-select-options": "zingchart,zinggrid",
                      "type-select-multiple": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", { attrs: { type: "editor" } }),
                  _vm._v(" "),
                  _c("zg-column", { attrs: { type: "remover" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }