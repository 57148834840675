var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _vm.adminAccess
      ? _c(
          "aside",
          { staticClass: "zingNetSidebar sidebar--secondary" },
          _vm._l(_vm.sidebar, function (item) {
            return _c(
              "nav",
              {
                key: `znsb_${item.text}`,
                class: `sidebar-item sidebar-item--secondaryNav${
                  item.data ? " sidebar-item--expandable" : ""
                }`,
                attrs: {
                  "sidebar-id": `zingNet_${item.text
                    .replace(" ", "")
                    .toLowerCase()}`,
                },
              },
              [
                _c("div", { staticClass: "sidebar-item--flex" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sidebar-item--flex sidebar-item__inner__top",
                      },
                      [
                        item.data
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "container sidebar-item__angle--secondary",
                                attrs: { icon: "angle" },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticStyle: {
                                      fill: "var(--color-tertiary-7)",
                                      width: "8px",
                                    },
                                    attrs: {
                                      width: "10",
                                      height: "7",
                                      viewBox: "0 0 10 7",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M1.175 0.589966L5 4.40663L8.825 0.589966L10 1.76497L5 6.76497L0 1.76497L1.175 0.589966Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sidebar-item__label" }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sidebar-item__link" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: item.url,
                                target: "_blank",
                                rel: "noopener noreferrer",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "external-link-alt"],
                                  size: "1x",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(item.data, function (item_lvl2) {
                        return _c(
                          "li",
                          { key: `znsb2_${item_lvl2.text}` },
                          [
                            item_lvl2.data
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "sidebar-item__link" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item_lvl2.url,
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item_lvl2.text) +
                                              "\n                    "
                                          ),
                                          item_lvl2.id
                                            ? [
                                                _c("span", {
                                                  attrs: { id: item_lvl2.id },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: [
                                                "fas",
                                                "external-link-alt",
                                              ],
                                              size: "1x",
                                            },
                                          }),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      item_lvl2.data,
                                      function (item_lvl3) {
                                        return _c(
                                          "li",
                                          {
                                            key: `znsb3_${item_lvl2.text}-${item_lvl3.text}`,
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item_lvl3.text) +
                                                ":\n                    "
                                            ),
                                            _c(
                                              "span",
                                              { attrs: { id: item_lvl3.id } },
                                              [_vm._v(_vm._s(item_lvl3.value))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : item_lvl2.url
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "sidebar-item__link" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item_lvl2.url,
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item_lvl2.text) +
                                              "\n                    "
                                          ),
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: [
                                                "fas",
                                                "external-link-alt",
                                              ],
                                              size: "1x",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item_lvl2.text) +
                                      ":\n                "
                                  ),
                                  _c("span", { attrs: { id: item_lvl2.id } }, [
                                    _vm._v(_vm._s(item_lvl2.value)),
                                  ]),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.adminAccess
      ? _c("section", { staticClass: "content--narrow" }, [
          _c("header", { staticClass: "content__header" }, [
            _c("h1", { staticClass: "content__header" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "content__header content__header--justify-content__start",
              },
              [
                _vm._l(_vm.tabs, function (item, index) {
                  return [
                    _c(
                      "span",
                      {
                        key: item.id,
                        class: `tab${index === 0 ? " active" : ""}`,
                        attrs: { tab: item.id },
                        on: {
                          click: function ($event) {
                            return _vm.updateTab(item.id, item.label)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "main",
            [
              _c("analytics", { class: _vm.showPage("analytics") }),
              _vm._v(" "),
              _c("development", { class: _vm.showPage("development") }),
              _vm._v(" "),
              _c("operations", { class: _vm.showPage("operations") }),
              _vm._v(" "),
              _c("sales", { class: _vm.showPage("sales") }),
              _vm._v(" "),
              _c("support", { class: _vm.showPage("support") }),
              _vm._v(" "),
              _c("users", { class: _vm.showPage("users") }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }