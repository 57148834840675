var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { attrs: { "zingnet-date-range": "" } },
    [
      _c(
        "div",
        { staticClass: "el-select-wrapper" },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select", "date-range": "" },
              model: {
                value: _vm.selectVal,
                callback: function ($$v) {
                  _vm.selectVal = $$v
                },
                expression: "selectVal",
              },
            },
            [
              !_vm.groupName
                ? _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.additionalItems && !_vm.groupName
                ? _vm._l(_vm.additionalItems, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.additionalItems && _vm.groupName
                ? [
                    _c(
                      "el-option-group",
                      { key: "default", attrs: { label: "Default" } },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-option-group",
                      { key: _vm.groupName, attrs: { label: _vm.groupName } },
                      _vm._l(_vm.additionalItems, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "el-select-icon",
            attrs: { icon: "angle" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectVal === "range" || typeof _vm.selectVal === "object"
        ? _c("el-date-picker", {
            attrs: {
              type: "daterange",
              "range-separator": "To",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              "value-format": "yyyy-MM-dd",
            },
            on: { change: _vm.updateCustomRange },
            model: {
              value: _vm.customDateRange,
              callback: function ($$v) {
                _vm.customDateRange = $$v
              },
              expression: "customDateRange",
            },
          })
        : _c("p", [_vm._v(_vm._s(_vm.dateLabel))]),
      _vm._v(" "),
      _c("p", { attrs: { hidden: "" } }, [_vm._v(_vm._s(_vm.range))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }