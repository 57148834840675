var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm._l(_vm.connections, function (connection) {
        return [
          _c(
            "div",
            { key: connection.provider, staticClass: "social-option" },
            [
              _c(
                "section",
                { staticClass: "social-option--icon" },
                [
                  _vm.accounts[connection.provider]
                    ? _c("font-awesome-icon", {
                        staticStyle: { color: "#f44336" },
                        attrs: { icon: ["fab", connection.icon], size: "3x" },
                      })
                    : _c("font-awesome-icon", {
                        staticStyle: { color: "#DDD" },
                        attrs: { icon: ["fab", connection.icon], size: "3x" },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c("section", [
                _c("div", { staticClass: "social-option--name" }, [
                  _vm._v(_vm._s(connection.name)),
                ]),
                _vm._v(" "),
                _vm.accounts[connection.provider]
                  ? _c("div", [
                      _vm._v(
                        _vm._s(_vm.accounts[connection.provider].email) +
                          "\n            "
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.unlink(connection.provider)
                            },
                          },
                        },
                        [_vm._v("Unlink")]
                      ),
                    ])
                  : _c("div", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.link(connection.provider)
                            },
                          },
                        },
                        [_vm._v("Link")]
                      ),
                    ]),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }