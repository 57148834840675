var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "secondary-layout",
    { attrs: { slim: "true", unsuccessful: "", isAuthing: _vm.isAuthing } },
    [
      _c("section", { staticClass: "content__wrap content__wrap--login" }, [
        _c(
          "div",
          {
            staticClass: "content content--narrow",
            attrs: { unsuccessful: "container" },
          },
          [
            _c(
              "entry-container",
              { attrs: { slim: "true", unsuccessful: "center" } },
              [
                _c(
                  "h3",
                  { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                  [_vm._v("Unsuccessful login")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: {
                      slot: "entryBody",
                      unsuccessful: "container__body",
                    },
                    slot: "entryBody",
                  },
                  [
                    _c("h1", [
                      _vm._v("Unable to log into"),
                      _c("br"),
                      _vm._v("ZingSoft studio"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("An authentication error has occurred"),
                      _c("br"),
                      _vm._v("during the process."),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("Please contact "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.zinggrid.com/contact",
                            target: "_blank",
                            unsuccessful: "link",
                          },
                        },
                        [_vm._v("support")]
                      ),
                      _vm._v(" or try to log in again."),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "button button--bottom button--center",
                        on: { click: _vm.goHome },
                      },
                      [_c("p", [_c("span", [_vm._v("Retry")])])]
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }