var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zingnet-card",
    { attrs: { gridBody: true } },
    [
      _c(
        "span",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v("\n    Site Monitoring\n    "),
          _c("date-range", { on: { updateDateRange: _vm.updateDateRange } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { slot: "header__right" },
          on: { click: _vm.openPingdom },
          slot: "header__right",
        },
        [_vm._v("View in Pingdom")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { slot: "body" },
          slot: "body",
        },
        [
          _c(
            "section",
            { staticClass: "zingnetCard--row no-border" },
            [
              _c(
                "zing-grid",
                { attrs: { id: "grid-siteUptime" } },
                [
                  _c(
                    "zg-colgroup",
                    [
                      _c("zg-column", {
                        attrs: { index: "status", renderer: "_renderStatus" },
                      }),
                      _vm._v(" "),
                      _c(
                        "zg-column",
                        {
                          attrs: {
                            index: "name, hostname",
                            header: "Site Name",
                          },
                        },
                        [
                          _c("a", { attrs: { href: "[[hostname]]" } }, [
                            _vm._v("[[index.name]]"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("zg-column", {
                        attrs: {
                          index: "summary",
                          header: "Uptime",
                          renderer: "_renderUptime",
                        },
                      }),
                      _vm._v(" "),
                      _c("zg-column", {
                        attrs: {
                          index: "lasterrortime",
                          header: "Up Since",
                          type: "date",
                          "type-date-from-now": "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "zg-column",
                        {
                          attrs: {
                            index: "lastresponsetime",
                            header: "Last response time",
                          },
                        },
                        [
                          _vm._v(
                            "\n            [[index.lastresponsetime]] ms\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "zg-column",
                        {
                          attrs: {
                            index: "summary",
                            header: "Response Time / Outages",
                            renderer: "_renderSummary",
                          },
                        },
                        [_c("div", { staticClass: "chart-siteUptime" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }