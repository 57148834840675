var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.type, attrs: { callout: "" } },
    [
      _vm.icon
        ? _c("svg-icon", {
            attrs: { icon: _vm.icon, height: "18", width: "18" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }