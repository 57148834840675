var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "section",
      { staticClass: "content--narrow", attrs: { notifications: "" } },
      [
        _c(
          "header",
          { staticClass: "content__header content__header--expanded" },
          [_c("h2", [_vm._v("Notifications")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { notifications: "gridContainer" } },
          [
            _c(
              "zing-grid",
              {
                attrs: {
                  caption: "Notifications",
                  layout: "row",
                  notifications: "list",
                  "row-class": "markRead",
                  data: '[\n          { "id": "1", "id_from": "123", "from": "john@zingsoft.com", "date": "May 15", "read": true, "needs_response": true, "type": "companyInvite", "company": "Company X", "permission": null },\n          { "id": "2", "id_from": "123", "from": "jane@zingsoft.com", "date": "May 14", "read": false, "needs_response": false, "type": "companyInvite", "company": "Company X", "permission": null },\n          { "id": "3", "id_from": "123", "from": "joe@zingsoft.com", "date": "May 13", "read": false, "needs_response": true, "type": "requestPermission", "company": "Company Y", "permission": "read" },\n          { "id": "4", "id_from": "123", "from": "jill@zingsoft.com", "date": "May 12", "read": true, "needs_response": false, "type": "requestPermission", "company": "Company Y", "permission": "write" },\n          { "id": "5", "id_from": "123", "from": "jack@zingsoft.com", "date": "May 11", "read": true, "needs_response": true, "type": "requestCompany", "company": "Company X", "permission": null },\n          { "id": "6", "id_from": "123", "from": "jim@zingsoft.com", "date": "May 10", "read": false, "needs_response": false, "type": "requestCompany", "company": "Company X", "permission": null }\n        ]',
                },
              },
              [
                _c(
                  "zg-colgroup",
                  [
                    _c("zg-column", { attrs: { index: "id", hidden: "" } }),
                    _vm._v(" "),
                    _c("zg-column", { attrs: { index: "date", width: "125" } }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "from, type, company, permission",
                        header: "Subject",
                        renderer: "renderSubject",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "needs_response",
                        header: "Responded",
                        renderer: "renderResponded",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "btn",
                        header: " ",
                        renderer: "renderViewBtn",
                        width: "180",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "zing-grid",
              {
                staticClass: "hide",
                attrs: { layout: "row", notifications: "detail" },
              },
              [
                _c(
                  "zg-caption",
                  [
                    _vm._v("\n          Notifications\n          "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          support: "openTicketBtn",
                          type: "primary",
                        },
                        on: { click: _vm.hideDetails },
                      },
                      [_vm._v("Back")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "zg-colgroup",
                  [
                    _c("zg-column", {
                      attrs: {
                        index:
                          "id, id_from, date, from, needs_response, type, company, permission",
                        header: "Notification Details",
                        renderer: "renderDetails",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }