export default {
  data() {
    return {
      domainToDelete: null,
      updateDomainDialogVisible: false,
    };
  },
  methods: {
    handlePlanDialogClose() {
      // Clear validation errors in domain forms
      if (this.$refs['domainFormCreate']) this.$refs['domainFormCreate'].clearState();
      if (this.$refs['domainFormUpdate']) this.$refs['domainFormUpdate'].clearState();

      // Clear variables
      this.dialogNewDomains = [];
      this.dialogNewSubdomains = [];
      this.dialogDomainForm.dialogNewDomain = '';
      this.dialogDomainForm.dialogNewSubdomain = '';
      this.activePlanIndex = 0;
      this.planSteps = 0;
      this.renewalProcess = false;
    },
    removeNewDomain(domainToDelete, sub) {
      let target = sub ? 'dialogNewSubdomains' : 'dialogNewDomains';
      this[target] = this[target].filter(domain => domain !== domainToDelete);
    },
    updateSubscription() {
      this.$api('subscription/update', {
        slug: this.selectedSubscription,
        domains: [this.domain],
        subdomains: [this.subdomain],
      })
      .then((result) => {
        this.$message({
          message: 'Subscription updated!',
          showClose: true,
          type: 'success',
        });
        this.getUserSubscriptions();
        this.subscriptionUpdateDialogVisible = false;
      })
      .catch((error) => {
        this.$message({
          duration: 10000,
          message: `Cannot subscribe. ${error.response.data.message}.`,
          showClose: true,
          type: 'error',
        });
        this.subscriptionUpdateDialogVisible = false;
      });
    },
  }
};