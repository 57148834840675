var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form__item" },
    [
      _c(
        "label",
        { staticClass: "form__label", attrs: { id: "phoneNumber" } },
        [_vm._v("Phone number")]
      ),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "form__field",
        attrs: {
          for: "phoneNumber",
          placeholder: "(xxx) xxx - xxxx",
          type: "tel",
        },
        on: {
          input: function ($event) {
            return _vm.format(_vm.phoneNumber_)
          },
        },
        model: {
          value: _vm.phoneNumber_,
          callback: function ($$v) {
            _vm.phoneNumber_ = $$v
          },
          expression: "phoneNumber_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }