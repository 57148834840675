var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer__wrap", attrs: { footer: _vm.footer } },
    [
      _c("div", { staticStyle: { flex: "1" } }),
      _vm._v(" "),
      _c("footer", { staticClass: "content__footer" }, [
        _c("div", { staticClass: "flex-item flex-item--left" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "content__footer__links" }, [
            _c("span", [_vm._v("ZingSoft Sites:")]),
            _vm._v(" "),
            _c(
              "a",
              { attrs: { href: "https://www.zingchart.com/", target: "_" } },
              [
                _c("svg-icon", {
                  attrs: {
                    icon: `${
                      _vm.footer == "secondary"
                        ? "zingchart-logomark"
                        : "zingchart-logomark-light"
                    }`,
                  },
                }),
                _vm._v("ZingChart"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              { attrs: { href: "https://www.zinggrid.com/", target: "_" } },
              [
                _c("svg-icon", {
                  attrs: {
                    icon: `${
                      _vm.footer == "secondary"
                        ? "zinggrid-logomark"
                        : "zinggrid-logomark-light"
                    }`,
                  },
                }),
                _vm._v("ZingGrid"),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__footer--right" }, [
          _c("div", [
            _vm._v(
              "© Copyright 2009-" +
                _vm._s(new Date().getFullYear()) +
                " ZingSoft, Inc. "
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "a",
        { attrs: { href: "https://www.zinggrid.com/about", target: "_" } },
        [_vm._v("About Us")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "a",
        { attrs: { href: "https://www.zinggrid.com/privacy", target: "_" } },
        [_vm._v("Privacy")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "content__footer__links__separator" }, [
        _vm._v("|"),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "#", target: "_" } }, [_vm._v("Legal")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }