var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `filter_wrapper${
        _vm.prefix ? ` filter_prefix--${_vm.prefix}` : ""
      }`,
      attrs: { scrollable: _vm.scrollable },
    },
    [
      _c(
        "el-select",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authenticated,
              expression: "authenticated",
            },
          ],
          attrs: {
            "allow-create": _vm.allowCreate,
            clearable: _vm.clearable,
            "collapse-tags": _vm.collapsible,
            filterable: _vm.filterable,
            multiple: _vm.multiple,
            placeholder: _vm.placeholder,
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _vm._l(_vm.options, function (filter) {
            return _c(
              "el-option",
              {
                key: filter.id,
                attrs: {
                  label: filter.name,
                  value: filter.value || filter.name,
                },
              },
              [
                _c("div", { staticClass: "filter__option" }, [
                  _c("div", { staticClass: "filter__name" }, [
                    _vm._v(_vm._s(filter.name)),
                  ]),
                  _vm._v(" "),
                  filter.count
                    ? _c("div", { staticClass: "filter__count" }, [
                        _vm._v(_vm._s(filter.count)),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.prefix
            ? _c("template", { slot: "prefix" }, [
                _vm._v(_vm._s(_vm.prefixText)),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("svg-icon", {
        attrs: { icon: "close--circle", height: "1rem", width: "1rem" },
      }),
      _vm._v(" "),
      _c("svg-icon", {
        staticClass: "el-input-icon",
        attrs: { icon: "angle" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }