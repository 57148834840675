var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-container",
      attrs: { "secondary-sidebar": "open" },
    },
    [
      _c("nav", { staticClass: "sidebar sidebar--primary" }),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "sidebar sidebar--primary real" },
        [
          _vm._l(_vm.sidebars, function (tab) {
            return [
              _c(
                "div",
                {
                  key: tab.id,
                  staticClass: "sidebar-item sidebar-item--primary trigger",
                  class: _vm.isActivePrimary(tab.id),
                  attrs: { "primary-id": tab.id },
                  on: {
                    click: function ($event) {
                      return _vm.openSecondary(tab.id, true)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "sidebar-item__icon zoom",
                    attrs: { icon: tab.id },
                  }),
                  _vm._v(" "),
                  _c("svg-icon", {
                    staticClass: "sidebar-item__icon--open",
                    attrs: { icon: "angle", width: "7" },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("nav", { staticClass: "sidebar sidebar--secondary" }),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "sidebar sidebar--secondary real" },
        [
          _vm._l(_vm.secondary, function (item, index) {
            return [
              index == 0
                ? [
                    _c(
                      "div",
                      {
                        key: `${item.id}-top`,
                        staticClass:
                          "sidebar-item sidebar-item--secondary sidebar-item--top",
                        on: {
                          click: function ($event) {
                            return _vm.closeSecondary(true)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "sidebar-item__label sidebar-item__label--top",
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "sidebar-item__angle active" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                icon: "angle",
                                fill: "var(--color-primary-1)",
                                width: "8",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nav",
                      {
                        key: `${item.id}-nav`,
                        staticClass: "sidebar sidebar--tertiary sidebar--top",
                      },
                      _vm._l(item.nav, function (navItem) {
                        return _c(
                          "router-link",
                          {
                            key: navItem.id,
                            staticClass: "sidebar__link",
                            attrs: { to: _vm.linkType("fork", navItem.id) },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sidebar-item--tertiary" },
                              [_vm._v(_vm._s(navItem.name))]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("hr", { key: item.id, staticClass: "sidebar__hr" }),
                  ]
                : _c(
                    "nav",
                    {
                      key: item.id,
                      staticClass: "sidebar-item sidebar-item--secondary",
                      class: _vm.addSecondaryClass(item.id, item.nav),
                      attrs: { "sidebar-id": item.id },
                      on: {
                        click: function ($event) {
                          return _vm.handleSecondaryClick(
                            item.id,
                            true,
                            true,
                            item
                          )
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "sidebar-item--flex" }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "sidebar-item--flex",
                              class: `${
                                _vm.isInnerNav ? "sidebar-item__inner__top" : ""
                              }${item.nonInteractive ? " no-hover" : ""}`,
                            },
                            [
                              _c("svg-icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.nav && item.nav.length > 0,
                                    expression:
                                      "item.nav && item.nav.length > 0",
                                  },
                                ],
                                staticClass: "sidebar-item__angle--secondary",
                                attrs: {
                                  icon: "angle",
                                  fill: "var(--color-tertiary-7)",
                                  width: "8",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "sidebar-item__label" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _vm._v(" "),
                              _c("transition", { attrs: { name: "fade" } }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.count || item.count == 0,
                                        expression:
                                          "item.count || item.count == 0",
                                      },
                                    ],
                                    staticClass: "sidebar-item__count",
                                  },
                                  [_vm._v(_vm._s(item.count))]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "nav",
                            { staticClass: "sidebar sidebar--tertiary" },
                            _vm._l(item.nav, function (navItem) {
                              return _c(
                                "router-link",
                                {
                                  key: navItem.id,
                                  staticClass: "sidebar__link",
                                  attrs: {
                                    to: _vm.linkType("fork", navItem.id),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "sidebar-item--tertiary" },
                                    [_vm._v(_vm._s(navItem.name))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sidebar-item__bar" }),
                      ]),
                    ]
                  ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }