var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      staticClass: "button",
      attrs: { lockButton: "", size: _vm.size, type: _vm.type },
      on: { click: _vm.login },
    },
    [
      _vm._t("default", function () {
        return [_vm._v("Login")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }