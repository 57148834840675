var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form__item" },
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "label",
            {
              staticClass: "form__label",
              staticStyle: { "margin-right": "0.5rem" },
            },
            [_vm._v("E-mail")]
          ),
          _vm._v(" "),
          !_vm.email_verified
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "Resend Verification E-mail",
                    placement: "top",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-secondary text-secondary--warning",
                      staticStyle: {
                        "text-decoration": "underline",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resendVerification()
                        },
                      },
                    },
                    [_vm._v("( Unverified )")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "form__field",
        attrs: { label: "New E-mail" },
        model: {
          value: _vm.email_,
          callback: function ($$v) {
            _vm.email_ = $$v
          },
          expression: "email_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }