var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "zingnetCardSection" }, [
    _c(
      "div",
      { staticClass: "zingnetCardRow" },
      [
        _c("cdn-tracker"),
        _vm._v(" "),
        _c("page-speed"),
        _vm._v(" "),
        _c("page-visitors", { attrs: { type: "Studio" } }),
        _vm._v(" "),
        _c("page-visitors", { attrs: { type: "ZingChart" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }