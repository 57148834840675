var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "zingnetCardSection" },
    [
      _c("sales-summary", { on: { openQuickbooks: _vm.openQuickbooks } }),
      _vm._v(" "),
      _c("sale-transactions", { on: { openQuickbooks: _vm.openQuickbooks } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }