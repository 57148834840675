var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav" }, [
    _c("nav", { staticClass: "nav__wrap nav__wrap--main" }, [
      _c(
        "div",
        { staticClass: "nav__logo nav__logo--secondary" },
        [_c("svg-icon", { attrs: { icon: "zingsoft-logomark" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nav__sign-in" }, [
        _c(
          "span",
          { staticClass: "mobile--hide" },
          [
            _vm.isSignup
              ? [_vm._v("Already have an account?")]
              : [_vm._v("Don't have an account?")],
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "nav__sign-in__link" }, [
          _vm.isSignup
            ? _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("/")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "text--underline" }, [
                    _vm._v("Log in"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        viewBox: "0 0 18 18",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
                        _c("path", {
                          attrs: {
                            d: "M8.778 0a9.336 9.336 0 0 0-5.284 1.623A9.033 9.033 0 0 0 .164 5.78L0 6.23h2.026l.084-.217c.364-.77.86-1.466 1.486-2.077a7.372 7.372 0 0 1 5.182-2.094c1.96 0 3.8.744 5.183 2.095a7.02 7.02 0 0 1 2.146 5.058 7.027 7.027 0 0 1-2.146 5.058 7.372 7.372 0 0 1-5.183 2.094 7.388 7.388 0 0 1-5.182-2.094A7.167 7.167 0 0 1 2.11 11.98l-.084-.216H0l.16.45a9.033 9.033 0 0 0 3.33 4.158A9.288 9.288 0 0 0 8.777 18C13.864 18 18 13.963 18 9s-4.136-9-9.222-9z",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M0 9.892h7.672l-1.764 1.862L7.118 13 11 9 7.118 5 5.864 6.246l1.808 1.862H0v1.784z",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              )
            : _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.launch("/signup")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "text--underline" }, [
                    _vm._v("Create account"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        viewBox: "0 0 18 18",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
                        _c("path", {
                          attrs: {
                            d: "M8.778 0a9.336 9.336 0 0 0-5.284 1.623A9.033 9.033 0 0 0 .164 5.78L0 6.23h2.026l.084-.217c.364-.77.86-1.466 1.486-2.077a7.372 7.372 0 0 1 5.182-2.094c1.96 0 3.8.744 5.183 2.095a7.02 7.02 0 0 1 2.146 5.058 7.027 7.027 0 0 1-2.146 5.058 7.372 7.372 0 0 1-5.183 2.094 7.388 7.388 0 0 1-5.182-2.094A7.167 7.167 0 0 1 2.11 11.98l-.084-.216H0l.16.45a9.033 9.033 0 0 0 3.33 4.158A9.288 9.288 0 0 0 8.777 18C13.864 18 18 13.963 18 9s-4.136-9-9.222-9z",
                          },
                        }),
                        _c("path", {
                          attrs: {
                            d: "M0 9.892h7.672l-1.764 1.862L7.118 13 11 9 7.118 5 5.864 6.246l1.808 1.862H0v1.784z",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }