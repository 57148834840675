var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { attrs: { "create-sidebar": _vm.show, open: _vm.sidebarOpen } },
    [
      _c("app-sidebar", {
        attrs: {
          "active-primary": _vm.activePrimary,
          open: _vm.sidebarOpen,
          sidebars: _vm.sidebarPrimary,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }