var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.authenticated && _vm.hasUser
    ? _c(
        "default-layout",
        { attrs: { isAuthing: _vm.localStorageAuthState } },
        [_c("warning")],
        1
      )
    : _c(
        "secondary-layout",
        { attrs: { isAuthing: _vm.localStorageAuthState, noJustify: true } },
        [_c("warning", { attrs: { light: "" } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }