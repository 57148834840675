var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", { attrs: { gridBody: true } }, [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Status Badges"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "zingBadges", attrs: { slot: "body" }, slot: "body" },
      [
        _c(
          "div",
          { staticClass: "zingnetCard--row no-border" },
          [
            _c(
              "zing-grid",
              {
                attrs: {
                  id: "zingNet_badge",
                  "cell-class": "renderNA",
                  data: '[{"repo": null, "branch": null, "src": null}]',
                  filter: "",
                  height: "1515",
                  "no-border": "",
                  selector: "",
                  sort: "",
                },
              },
              [
                _c(
                  "zg-colgroup",
                  [
                    _c("zg-column", {
                      attrs: {
                        index: "repo",
                        "sort-asc": "",
                        type: "url",
                        "type-url-src":
                          "https://github.com/zingsoftinc/[[index.repo]]",
                        width: "30%",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "branch",
                        "default-display": "N/A",
                        width: "25%",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "src",
                        header: "Badge Status",
                        type: "image",
                        width: "45%",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }