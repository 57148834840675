var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zingnet-card",
    { attrs: { "grid-body": true } },
    [
      _c(
        "span",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v("\n    Recent Sales\n    "),
          _c("date-range", { on: { updateDateRange: _vm.updateDateRange } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { slot: "header__right" },
          on: { click: _vm.openQuickbooks },
          slot: "header__right",
        },
        [_vm._v("View in Quickbooks")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c(
            "zing-grid",
            {
              attrs: {
                "cell-class": "renderNA",
                "default-display": "N/A",
                layout: "row",
                "layout-controls": "disabled",
                "no-border": "",
                pager: "",
                "page-size": "10",
                selector: "",
              },
            },
            [
              _c(
                "zg-colgroup",
                [
                  _c("zg-column", {
                    attrs: {
                      index: "transactionDate, type, id",
                      header: "Transaction Date",
                      renderer: "renderTransactionDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: { index: "amount", type: "currency" },
                  }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      index: "client, clientId",
                      header: "Client",
                      renderer: "renderClient",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", { attrs: { index: "license" } }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      index: "zingBrand",
                      renderer: "renderProductType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-data",
                [
                  _c("zg-param", {
                    attrs: {
                      name: "headers",
                      value: JSON.stringify(_vm.headerVal),
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "src", value: _vm.srcValue },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }