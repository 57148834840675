var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("filter-selector", {
    ref: "metadataSelector",
    attrs: {
      allowCreate: true,
      clearable: true,
      defaultValue: _vm.defaultValue,
      filterable: true,
      multiple: _vm.multiple,
      options: _vm.options,
      placeholder: _vm.placeholder,
      scrollable: _vm.scrollable,
    },
    on: {
      filterBy: _vm.filter,
      "update:options": function ($event) {
        _vm.options = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }