var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scorecard-group" },
    [
      _vm._t("card_one"),
      _vm._v(" "),
      _c("p", [_vm._v("vs")]),
      _vm._v(" "),
      _vm._t("card_two"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }