var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav" }, [
    _c("nav", { staticClass: "nav__wrap nav__wrap--main" }, [
      _c(
        "section",
        {
          staticClass: "nav__content nav__content--main",
          class: _vm.appWidthClass,
        },
        [
          _c("div", { staticClass: "nav__content" }, [
            _c(
              "div",
              { staticClass: "nav__logo" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/" } },
                  [
                    _c("svg-icon", {
                      attrs: { icon: "zingsoft-logomark-light" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isMobile,
                  expression: "!isMobile",
                },
              ],
              staticClass:
                "nav__item-container nav__content--wide nav__content--right",
            },
            [
              _c(
                "el-dropdown",
                {
                  staticClass: "trigger trigger--relative",
                  attrs: { trigger: "click" },
                  on: {
                    command: _vm.navigate,
                    "visible-change": _vm.navActive,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "el-dropdown-link" },
                    [
                      _vm.avatar !== "#"
                        ? _c("img", {
                            staticClass: "nav__avatar",
                            attrs: { src: _vm.avatar },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "el-dropdown-link__text" }, [
                        _vm._v(_vm._s(_vm.displayName)),
                      ]),
                      _c("svg-icon", {
                        staticClass: "nav__icon--angle",
                        attrs: { icon: "angle", fill: "#fff", width: "7" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass:
                            "nav__menu-item nav__menu-item--highlight",
                          attrs: { command: "/account" },
                        },
                        [
                          _vm.avatar !== "#"
                            ? _c("img", {
                                staticClass: "nav__avatar--mobile",
                                attrs: { src: _vm.avatar },
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(_vm.displayName))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass:
                            "nav__menu-item nav__menu-item--secondary",
                          attrs: { command: "/" },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "nav__menu-item__icon",
                            attrs: {
                              icon: "dashboard",
                              width: "12",
                              height: "12",
                            },
                          }),
                          _vm._v("Dashboard"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass:
                            "nav__menu-item nav__menu-item--secondary",
                          attrs: { command: "/account/support" },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "nav__menu-item__icon",
                            attrs: {
                              icon: "support",
                              width: "14",
                              height: "15",
                            },
                          }),
                          _vm._v("Support"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "nav__menu-item",
                          attrs: { command: "/account" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nav__menu-item__icon" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "cog"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v("Account"),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "nav__menu-item",
                          attrs: { command: "/account/subscriptions" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nav__menu-item__icon" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "clock"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v("Subscriptions"),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.adminDashboardView
                        ? _c(
                            "el-dropdown-item",
                            {
                              staticClass: "nav__menu-item",
                              attrs: { command: "/admin" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "nav__menu-item__icon" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "lock"] },
                                  }),
                                ],
                                1
                              ),
                              _vm._v("ZingNet"),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "nav__menu-item",
                          attrs: { command: "logout" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nav__menu-item__icon" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "sign-out-alt"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v("Logout"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isMobile,
            expression: "isMobile",
          },
        ],
        staticClass: "nav__mobile-icon",
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isMobile,
              expression: "isMobile",
            },
          ],
          staticClass: "nav__mobile-icon",
        },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "trigger trigger--relative",
              attrs: { trigger: "click" },
              on: { command: _vm.navigate, "visible-change": _vm.navActive },
            },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _c(
                  "span",
                  { staticClass: "nav__mobile__text" },
                  [
                    _vm._v("Menu "),
                    _c("svg-icon", {
                      staticClass: "nav__icon--angle",
                      attrs: { icon: "angle", fill: "#fff", width: "9" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "nav__menu-item nav__menu-item--highlight",
                      attrs: { command: "/account" },
                    },
                    [
                      _vm.avatar !== "#"
                        ? _c("img", {
                            staticClass: "nav__avatar--mobile",
                            attrs: { src: _vm.avatar },
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.displayName))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "nav__menu-item",
                      attrs: { command: "/account" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav__menu-item__icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "cog"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v("Account"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "nav__menu-item",
                      attrs: { command: "/account/subscriptions" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav__menu-item__icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "clock"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v("Subscriptions"),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.adminDashboardView
                    ? _c(
                        "el-dropdown-item",
                        {
                          staticClass: "nav__menu-item",
                          attrs: { command: "/admin" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nav__menu-item__icon" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "lock"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v("ZingNet"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "nav__menu-item",
                      attrs: { command: "logout" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav__menu-item__icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "sign-out-alt"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v("Logout"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "nav__menu-item nav__menu-item--secondary",
                      attrs: { command: "/" },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "nav__menu-item__icon",
                        attrs: { icon: "dashboard", width: "12", height: "12" },
                      }),
                      _vm._v("Dashboard"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "nav__menu-item nav__menu-item--secondary",
                      attrs: { command: "/account/support" },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "nav__menu-item__icon",
                        attrs: { icon: "support", width: "14", height: "15" },
                      }),
                      _vm._v("Support"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }