var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form__item" },
    [
      _c("label", { staticClass: "form__label", attrs: { id: "full-name" } }, [
        _vm._v("Full Name"),
      ]),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "form__field",
        attrs: { placeholder: "John", for: "full-name", maxlength: "75" },
        model: {
          value: _vm.name_,
          callback: function ($$v) {
            _vm.name_ = $$v
          },
          expression: "name_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }