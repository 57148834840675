var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    { attrs: { editorTheme: _vm.editorTheme } },
    [
      _c(
        "el-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showNotice,
              expression: "showNotice",
            },
          ],
          staticClass: "editor__alert",
          attrs: {
            title: "[Warning: Not Forking]",
            type: "warning",
            center: "",
            effect: "dark",
            "show-icon": "",
          },
        },
        [
          _c("p", { staticClass: "el-alert__description" }, [
            _vm._v(
              "Admin, you are editing demo directly because you are coming from the ZingChart/ZingGrid site.\n      "
            ),
            _c("a", { attrs: { href: _vm.demoUrl } }, [
              _vm._v("Click here to fork demo."),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("header", { staticClass: "editor__controls" }, [
        _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isMobile,
                expression: "!isMobile",
              },
            ],
            staticClass: "editor__controls--left",
          },
          [
            _c("el-input", {
              attrs: {
                placeholder: "Untitled Demo",
                size: "mini",
                maxlength: "75",
              },
              model: {
                value: _vm.demoTitle,
                callback: function ($$v) {
                  _vm.demoTitle = $$v
                },
                expression: "demoTitle",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "editor__controls--right" },
          [
            _c("toggle-editorPane", { on: { update: _vm.updateControls } }),
            _vm._v(" "),
            _c("editor-settings", {
              attrs: { type: "primary", size: "mini", uid: _vm.uid },
              on: { update: _vm.updateEditorSettings },
            }),
            _vm._v(" "),
            _vm.authenticated
              ? [
                  _c(
                    "el-dropdown",
                    {
                      attrs: {
                        "split-button": "",
                        type: "primary",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveDemo(null)
                        },
                        command: _vm.selectSaveOption,
                      },
                    },
                    [
                      _c("span", { staticClass: "editor__save__icon" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "action-text" }, [
                        _vm._v("SAVE"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "personal" } },
                            [_vm._v("Personal")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.adminSaveOverride,
                                  expression: "adminSaveOverride",
                                },
                              ],
                              attrs: { command: "updateOwner" },
                            },
                            [_vm._v("Update Owner")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { disabled: "", divided: "" } },
                            [_vm._v("Save to Group:")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.myGroups, function (group) {
                            return _c(
                              "el-dropdown-item",
                              { key: group.id, attrs: { command: group.id } },
                              [_vm._v(_vm._s(group.name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "lock",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { beforeLogin: _vm.saveLocally },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "save"], size: "1x" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "action-text" }, [
                        _vm._v("Sign Up to Save"),
                      ]),
                    ],
                    1
                  ),
                ],
            _vm._v(" "),
            _c(
              "el-button-group",
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "cmd(ctrl) + p",
                      placement: "top-start",
                      "open-delay": 1000,
                    },
                  },
                  [
                    !_vm.autoUpdate
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.createPreview(null, null, true)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "play"], size: "1x" },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "action-text" }, [
                              _vm._v("RUN"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.authenticated,
                        expression: "authenticated",
                      },
                    ],
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        !_vm.uid || _vm.isForking
                          ? "Please save this demo before forking it"
                          : "Please wait until demo ready to be forked",
                      disabled:
                        _vm.uid && !_vm.isForking && _vm.forkReady
                          ? true
                          : false,
                      placement: "top-start",
                      "open-delay": 1000,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        class:
                          _vm.uid && !_vm.isForking && _vm.forkReady
                            ? ""
                            : "is-disabled",
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.forkToSave()
                          },
                        },
                      },
                      [
                        !_vm.uid || _vm.isForking
                          ? [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "code-branch"],
                                  size: "1x",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "action-text" }, [
                                _vm._v("FORK"),
                              ]),
                            ]
                          : !_vm.forkReady
                          ? [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "spinner"],
                                  spin: "",
                                  size: "1x",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "action-text" }, [
                                _vm._v("FORK"),
                              ]),
                            ]
                          : [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "code-branch"],
                                  size: "1x",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "action-text" }, [
                                _vm._v("FORK"),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.assets && _vm.assets.length !== 0
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.openAssets },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "file-code"], size: "1x" },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "action-text" }, [
                          _vm._v("ASSETS"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.authenticated,
                        expression: "authenticated",
                      },
                    ],
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.settingsVisible = true
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "cog"], size: "1x" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "action-text" }, [
                      _vm._v("SETTINGS"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex content__wrap--sidebar" },
        [
          _c("create-sidebar", {
            attrs: { uid: _vm.demo.uid, show: _vm.showSidebar },
            on: {
              resize: function ($event) {
                return _vm.handleResize()
              },
              show: function ($event) {
                return _vm.toggleSidebar()
              },
              "update:show": function ($event) {
                _vm.showSidebar = $event
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.contentClass },
            [
              _c("section", { ref: "editor", staticClass: "editor" }, [
                _c("section", { attrs: { editorLayout: _vm.editorLayout } }, [
                  _c("section", { staticClass: "editor__top" }, [
                    _c("section", { staticClass: "editor__code-container" }, [
                      _c(
                        "div",
                        {
                          ref: "html",
                          staticClass: "editor__code editor__code--html",
                          style: _vm.htmlStyle,
                        },
                        [
                          _c("header", { staticClass: "editor__code-header" }, [
                            _c("div", [_vm._v("HTML")]),
                            _vm._v(" "),
                            !_vm.isMobile
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "editor__maximize-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.maximizeWindow("html")
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["fas", "window-maximize"],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            {
                              ref: "codeHTMLWrap",
                              staticClass: "editor__codewrap",
                            },
                            [
                              _c("studio-editor", {
                                ref: "codeHTML",
                                attrs: {
                                  autocomplete:
                                    _vm.autoComplete.indexOf("html") !== -1,
                                  code: _vm.htmlCode,
                                  "font-family": _vm.editorFontFamily,
                                  "font-size": _vm.editorFontSize,
                                  language: "html",
                                  theme: _vm.editorTheme,
                                },
                                on: { update: _vm.updateCode },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.showHTML && (_vm.showCSS || _vm.showJS),
                              expression: "showHTML && (showCSS || showJS)",
                            },
                          ],
                          staticClass: "editor__resizer editor__resizer--code",
                          on: {
                            mousedown: function ($event) {
                              return _vm.setActiveResizer("html", $event)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "ellipsis-v"], size: "1x" },
                          }),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "ellipsis-h"], size: "1x" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "css",
                          staticClass: "editor__code editor__code--css",
                          style: _vm.cssStyle,
                        },
                        [
                          _c("header", { staticClass: "editor__code-header" }, [
                            _c("div", [_vm._v("CSS")]),
                            _vm._v(" "),
                            !_vm.isMobile
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "editor__maximize-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.maximizeWindow("css")
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["fas", "window-maximize"],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            {
                              ref: "codeCSSWrap",
                              staticClass: "editor__codewrap",
                            },
                            [
                              _c("studio-editor", {
                                ref: "codeCSS",
                                attrs: {
                                  autocomplete:
                                    _vm.autoComplete.indexOf("css") !== -1,
                                  code: _vm.cssCode,
                                  "font-family": _vm.editorFontFamily,
                                  "font-size": _vm.editorFontSize,
                                  language: "css",
                                  theme: _vm.editorTheme,
                                },
                                on: { update: _vm.updateCode },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showCSS && _vm.showJS
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "editor__resizer editor__resizer--code",
                              on: {
                                mousedown: function ($event) {
                                  return _vm.setActiveResizer("css", $event)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "ellipsis-v"],
                                  size: "1x",
                                },
                              }),
                              _vm._v(" "),
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "ellipsis-h"],
                                  size: "1x",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "js",
                          staticClass: "editor__code editor__code--js",
                          style: _vm.jsStyle,
                        },
                        [
                          _c("header", { staticClass: "editor__code-header" }, [
                            _c("div", [_vm._v("JS")]),
                            _vm._v(" "),
                            !_vm.isMobile
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "editor__maximize-icon",
                                    on: {
                                      click: function ($event) {
                                        return _vm.maximizeWindow("js")
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["fas", "window-maximize"],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            {
                              ref: "codeJSWrap",
                              staticClass: "editor__codewrap",
                            },
                            [
                              _c("studio-editor", {
                                ref: "codeJS",
                                attrs: {
                                  autocomplete:
                                    _vm.autoComplete.indexOf("js") !== -1,
                                  code: _vm.jsCode,
                                  "font-family": _vm.editorFontFamily,
                                  "font-size": _vm.editorFontSize,
                                  language: "javascript",
                                  theme: _vm.editorTheme,
                                },
                                on: { update: _vm.updateCode },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      staticClass: "editor__resizer editor__resizer--preview",
                      on: {
                        mousedown: function ($event) {
                          return _vm.setActiveResizer("preview", $event)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "ellipsis-h"], size: "1x" },
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "ellipsis-v"], size: "1x" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentlyResizing
                    ? _c("div", { staticClass: "iframe-mask" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.previewLoading,
                          expression: "previewLoading",
                        },
                      ],
                      ref: "previewWrap",
                      staticClass: "editor__bottom",
                      style: _vm.previewStyle,
                    },
                    [
                      _vm.displayRunOnClick
                        ? _c(
                            "div",
                            {
                              staticClass: "editor__bottom__runOnClick",
                              style: _vm.previewStyle,
                              on: {
                                click: function ($event) {
                                  return _vm.createPreview(null, null, true)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "container" }, [
                                _c(
                                  "div",
                                  { staticClass: "clickToRun" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["fas", "play"],
                                        size: "6x",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", [_vm._v("Click to Run")]),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        : _c(
                            "iframe",
                            {
                              ref: "preview",
                              staticClass: "preview",
                              style: _vm.previewStyle,
                              attrs: { id: "preview" },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Your browser does not support iframes."
                                ),
                              ]),
                            ]
                          ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("demo-settings", {
                attrs: {
                  demo: _vm.demo,
                  settings: ["all"],
                  settingsVisible: _vm.settingsVisible,
                },
                on: {
                  save: _vm.saveDemo,
                  "toggle-visibility": function ($event) {
                    _vm.settingsVisible = !_vm.settingsVisible
                  },
                  "update-demo": _vm.updateDemoData,
                  "update:demo": function ($event) {
                    _vm.demo = $event
                  },
                  "update:settingsVisible": function ($event) {
                    _vm.settingsVisible = $event
                  },
                  "update:settings-visible": function ($event) {
                    _vm.settingsVisible = $event
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticClass: "template__dialog",
                  attrs: {
                    title: "Create a Demo",
                    visible: _vm.createDemoVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.createDemoVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "section",
                    { staticClass: "template__container" },
                    [
                      _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeTemplateTab,
                            callback: function ($$v) {
                              _vm.activeTemplateTab = $$v
                            },
                            expression: "activeTemplateTab",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "ZingChart Templates",
                                name: "zingchart",
                              },
                            },
                            [
                              _c(
                                "section",
                                { staticClass: "template__list" },
                                _vm._l(
                                  _vm.zingchartTemplates,
                                  function (template, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "template__item",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "template__title" },
                                          [_vm._v(_vm._s(template.title))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "template__image__wrapper",
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "template__image",
                                              style:
                                                template.title != "Blank Grid"
                                                  ? _vm.getImageStyle(
                                                      template.image
                                                    )
                                                  : null,
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  template.title == "Blank Grid"
                                                    ? _vm.blankGrid
                                                    : null
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "template__description",
                                          },
                                          [_vm._v(_vm._s(template.description))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "template__button",
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setupTemplate(
                                                  template
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Create")]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "ZingGrid Templates",
                                name: "zinggrid",
                              },
                            },
                            [
                              _c(
                                "section",
                                { staticClass: "template__list" },
                                _vm._l(
                                  _vm.zinggridTemplates,
                                  function (template, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "template__item",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "template__title" },
                                          [_vm._v(_vm._s(template.title))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "template__image__wrapper",
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "template__image",
                                              style:
                                                template.title != "Blank Grid"
                                                  ? _vm.getImageStyle(
                                                      template.image
                                                    )
                                                  : null,
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  template.title == "Blank Grid"
                                                    ? _vm.blankGrid
                                                    : null
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "template__description",
                                          },
                                          [_vm._v(_vm._s(template.description))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "template__button",
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setupTemplate(
                                                  template
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Create")]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "My Templates",
                                name: "myTemplates",
                              },
                            },
                            [
                              _vm.myTemplates && _vm.myTemplates.length == 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "1rem 2.3rem" },
                                      },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            "You have not created any templates!"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            "You can convert a demo into a template in the demo's settings dialog."
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "section",
                                      { staticClass: "template__list" },
                                      _vm._l(
                                        _vm.myTemplates,
                                        function (template, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "template__item",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "template__title",
                                                },
                                                [_vm._v(_vm._s(template.title))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "template__image__wrapper",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "template__image",
                                                    style: _vm.getImageStyle(
                                                      template.image
                                                    ),
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "template__description",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(template.description)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "template__button",
                                                  attrs: {
                                                    size: "mini",
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setupTemplate(
                                                        template
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Create")]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    dialog: "assets",
                    short: "",
                    title: "Assets",
                    visible: _vm.assetsVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.assetsVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { model: _vm.assetsForm, "label-width": "120px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "ZingGrid" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.assetsForm.zinggrid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.assetsForm, "zinggrid", $$v)
                                },
                                expression: "assetsForm.zinggrid",
                              },
                            },
                            [
                              _vm._l(_vm.assets_zinggrid, function (asset) {
                                return [
                                  asset.public != 0 ||
                                  (asset.public == 0 && _vm.adminAssetsAccess)
                                    ? _c("el-option", {
                                        key: asset.name,
                                        attrs: {
                                          label: asset.name,
                                          value: asset.id,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "None", value: "None" },
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("svg-icon", {
                            staticClass: "el-input-icon",
                            attrs: { icon: "angle" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "ZingChart" } },
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.clearZingChartAsset },
                              model: {
                                value: _vm.assetsForm.zingchart,
                                callback: function ($$v) {
                                  _vm.$set(_vm.assetsForm, "zingchart", $$v)
                                },
                                expression: "assetsForm.zingchart",
                              },
                            },
                            [
                              _vm._l(_vm.assets_zingchart, function (asset) {
                                return [
                                  asset.public != 0 ||
                                  (asset.public == 0 && _vm.adminAssetsAccess)
                                    ? _c("el-option", {
                                        key: asset.name,
                                        attrs: {
                                          label: asset.name,
                                          value: asset.id,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "None", value: "None" },
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("svg-icon", {
                            staticClass: "el-input-icon",
                            attrs: { icon: "angle" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.assetsForm.zingchart !== "None" &&
                      _vm.assetsForm.zingchart !== "3"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "Version" } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.assetsForm.zingchartVersion,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.assetsForm,
                                        "zingchartVersion",
                                        $$v
                                      )
                                    },
                                    expression: "assetsForm.zingchartVersion",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.assets_zingchart[
                                      _vm.assetsForm.zingchart
                                    ].versions,
                                    function (asset) {
                                      return [
                                        asset.public != 0 ||
                                        (asset.public == 0 &&
                                          _vm.adminAssetsAccess)
                                          ? _c("el-option", {
                                              key: asset,
                                              attrs: {
                                                label: asset,
                                                value: asset,
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("svg-icon", {
                                staticClass: "el-input-icon",
                                attrs: { icon: "angle" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.assetsForm.zingchart !== "None" &&
                      _vm.assetsForm.zingchart !== "3"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "Modules" } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.assetsForm.zingchartModule,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.assetsForm,
                                        "zingchartModule",
                                        $$v
                                      )
                                    },
                                    expression: "assetsForm.zingchartModule",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.assets_zingchart[
                                      _vm.assetsForm.zingchart
                                    ].modules[_vm.assetsForm.zingchartVersion],
                                    function (asset) {
                                      return [
                                        asset.public != 0 ||
                                        (asset.public == 0 &&
                                          _vm.adminAssetsAccess)
                                          ? _c("el-option", {
                                              key: asset,
                                              attrs: {
                                                label: asset,
                                                value: asset,
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "None", value: "None" },
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("svg-icon", {
                                staticClass: "el-input-icon",
                                attrs: { icon: "angle" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          staticClass: "settings__entry",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end",
                            "align-items": "flex-end",
                            flex: "1",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.assetsVisible = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.updateAsset },
                            },
                            [_vm._v("Update")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticClass: "el-dialog__premium",
                  attrs: {
                    short: "",
                    title: "Signup to Access",
                    visible: _vm.premiumVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.premiumVisible = $event
                    },
                  },
                },
                [
                  _c("p", [_vm._v("This is a premium demo!")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Login or Signup to gain access to this demo!"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      _c(
                        "lock",
                        {
                          attrs: { type: "primary" },
                          on: { beforeLogin: _vm.saveLocally },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "save"], size: "1x" },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "action-text" }, [
                            _vm._v("Login / Signup"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    short: "",
                    forkDemo: "",
                    title: "Fork Demo to Save.",
                    visible: _vm.promptForkVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.promptForkVisible = $event
                    },
                  },
                },
                [
                  _c("p", [_vm._v("This demo is not owned by you .")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Please fork demo to save your current changes."),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dialog_container" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.forkToSave($event)
                            },
                          },
                        },
                        [_vm._v("Fork Demo")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.adminSaveOverride,
                              expression: "adminSaveOverride",
                            },
                          ],
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.saveDemo(null, null, true)
                            },
                          },
                        },
                        [_vm._v("Confirm Save")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.adminSaveOverride,
                          expression: "adminSaveOverride",
                        },
                      ],
                      attrs: {
                        type: "warning",
                        title: "Note to admin!",
                        closable: false,
                        "show-icon": "",
                      },
                    },
                    [
                      _vm._v(
                        "\n          Saving demo does not update owner of demo.\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "my-dialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    type: "danger",
                    visible: _vm.saveWarningVisible,
                  },
                  on: {
                    close: function ($event) {
                      return _vm.closeDialog("saveWarningVisible")
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c("p", [
                      _vm._v(
                        "You will lose all unsaved progress if you leave this page."
                      ),
                      _c("br"),
                      _c("b", [_vm._v("Are you sure you want to proceed?")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "options" }, slot: "options" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", type: "danger" },
                          on: { click: _vm.leavePage },
                        },
                        [_vm._v("Leave Page")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.saveWarningVisible = false
                            },
                          },
                        },
                        [_vm._v("Stay on Page")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    short: "",
                    title: "Update Owner of Demo",
                    visible: _vm.updateOwnerVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.updateOwnerVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    { attrs: { "label-width": "120px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Select User" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "Enter user's email",
                              },
                              model: {
                                value: _vm.newOwnerId,
                                callback: function ($$v) {
                                  _vm.newOwnerId = $$v
                                },
                                expression: "newOwnerId",
                              },
                            },
                            _vm._l(_vm.userOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: _vm.userLabel(item),
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "section",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end",
                                "margin-top": "1.5rem",
                                "align-items": "flex-end",
                                flex: "1",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.updateOwnerVisible = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: !this.newOwnerId,
                                  },
                                  on: { click: _vm.updateDemoOwner },
                                },
                                [_vm._v("Update")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }