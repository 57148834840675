var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zingnet-card",
    [
      _c(
        "span",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v("\n    Sales Summary\n    "),
          _c("date-range", { on: { updateDateRange: _vm.updateDateRange } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { slot: "header__right" },
          on: { click: _vm.openQuickbooks },
          slot: "header__right",
        },
        [_vm._v("View in Quickbooks")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { slot: "body" },
          slot: "body",
        },
        [
          _c(
            "section",
            { staticClass: "zingnetCard--row" },
            _vm._l(_vm.counts, function (item) {
              return _c(
                "section",
                {
                  key: item.title.replace(/\s/g, ""),
                  staticClass: "zingnetCard--column",
                },
                [
                  _c(
                    "h3",
                    { staticClass: "zingnetCard--header" },
                    [
                      _vm._v(_vm._s(item.title) + " "),
                      _c("bubble-count", {
                        attrs: {
                          "comparison-cur": item.after.count,
                          "comparison-prev": item.before.count,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "zingnetCard--row" },
                    [
                      _c(
                        "score-card-group",
                        [
                          _c("score-card", {
                            attrs: {
                              slot: "card_one",
                              currency: item.currency,
                              label: _vm.label,
                              value: item.after.count,
                              title: `in last ${_vm.daysDiff} days`,
                            },
                            slot: "card_one",
                          }),
                          _vm._v(" "),
                          _c("score-card", {
                            attrs: {
                              slot: "card_two",
                              currency: item.currency,
                              label: _vm.label,
                              value: item.before.count,
                              title: `in previous ${_vm.daysDiff} days`,
                              secondary: true,
                            },
                            slot: "card_two",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "zingnetCard--row no-border" }, [
                    _c("div", {
                      attrs: { id: `chart-${item.title.replace(/\s/g, "")}` },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }