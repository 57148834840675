import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=62a49aa6&scoped=true"
import script from "./Admin.vue?vue&type=script&lang=js"
export * from "./Admin.vue?vue&type=script&lang=js"
import style0 from "./Admin.vue?vue&type=style&index=0&id=62a49aa6&scoped=true&lang=css"
import style1 from "./Admin.vue?vue&type=style&index=1&id=62a49aa6&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a49aa6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62a49aa6')) {
      api.createRecord('62a49aa6', component.options)
    } else {
      api.reload('62a49aa6', component.options)
    }
    module.hot.accept("./Admin.vue?vue&type=template&id=62a49aa6&scoped=true", function () {
      api.rerender('62a49aa6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/src/views/admin/Admin.vue"
export default component.exports