var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Test Coverage"),
    ]),
    _vm._v(" "),
    _c(
      "span",
      { attrs: { slot: "header__right" }, slot: "header__right" },
      [
        _c("el-button", { on: { click: _vm.openTestCoverage } }, [
          _vm._v("View in CodeClimate"),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("section", { staticClass: "zingnetCard--row" }, [
        _c("section", { staticClass: "zingnetCard--column" }, [
          _c("div", { attrs: { id: "chart-testCoverage" } }),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "zingnetCard--column" },
          [
            _c("zing-grid", {
              attrs: {
                "rounded-borders": "",
                data: JSON.stringify(_vm.grid_codeClimate),
                layout: "row",
                "layout-controls": "disabled",
                selector: "",
                sort: "",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }