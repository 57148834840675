var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Deployment"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c(
          "div",
          { staticClass: "zingnetCard--row flex" },
          [
            _c("deploy-item", {
              attrs: {
                title: "ZingChart Dev Gallery",
                sitename: "zingchart",
                branch: "dev",
              },
              on: { deploy: _vm.startDeployment },
            }),
            _vm._v(" "),
            _c("deploy-item", {
              attrs: {
                title: "ZingChart Main Gallery",
                sitename: "zingchart",
                branch: "master",
              },
              on: { deploy: _vm.startDeployment },
            }),
            _vm._v(" "),
            _c("deploy-progress", {
              ref: "zingchartProgress",
              attrs: {
                title: "ZingChart Deployment Progress",
                sitename: "zingchart",
                branch: _vm.zingchartBranch,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "zingnetCard--row flex" },
          [
            _c("deploy-item", {
              attrs: {
                title: "ZingGrid Dev Gallery",
                sitename: "zinggrid",
                branch: "dev",
              },
              on: { deploy: _vm.startDeployment },
            }),
            _vm._v(" "),
            _c("deploy-item", {
              attrs: {
                title: "ZingGrid Main Gallery",
                sitename: "zinggrid",
                branch: "master",
              },
              on: { deploy: _vm.startDeployment },
            }),
            _vm._v(" "),
            _c("deploy-progress", {
              ref: "zinggridProgress",
              attrs: {
                title: "ZingGrid Deployment Progress",
                sitename: "zinggrid",
                branch: _vm.zinggridBranch,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("portal-target", { attrs: { name: "deployment-error" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }