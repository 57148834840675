var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "content--wide", attrs: { "group-none": "" } },
    [
      _c("p", [
        _vm._v(
          "Create your own group or request an invitation to join an existing group."
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.groupCapped,
              expression: "groupCapped",
            },
          ],
          staticClass: "note red",
        },
        [
          _vm._v("You currently are a part of "),
          _c("b", [_vm._v(_vm._s(_vm.myGroups.length))]),
          _vm._v(" groups. Please delete or leave a group to create another."),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.groupingCreate,
              expression: "!groupingCreate",
            },
          ],
          staticClass: "note red",
        },
        [_vm._v("You do not have permission to create groups!")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: {
            disabled: _vm.groupCapped || !_vm.groupingCreate,
            type: "primary",
          },
          on: {
            click: function ($event) {
              _vm.createVisible = true
            },
          },
        },
        [_vm._v("Create")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              _vm.requestVisible = true
            },
          },
        },
        [_vm._v("Request")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog--condense dialog--padded",
          attrs: {
            "group-none": "createDialog",
            title: "Create a Group",
            visible: _vm.createVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createForm",
              attrs: {
                "label-width": "150px",
                model: _vm.createForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Group Name", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.createForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.createForm, "name", $$v)
                      },
                      expression: "createForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.createForm.members, function (member, index) {
                return _c(
                  "el-form-item",
                  {
                    key: member.key,
                    attrs: {
                      "group-none": "inputRow",
                      label: "Member " + parseInt(index + 1),
                      prop: "members." + index + ".email",
                      rules: [
                        {
                          required: true,
                          message: "Please input email address",
                          trigger: "blur",
                        },
                        {
                          type: "email",
                          message: "Please input correct email address",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "email" },
                      model: {
                        value: member.email,
                        callback: function ($$v) {
                          _vm.$set(member, "email", $$v)
                        },
                        expression: "member.email",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeMember(member)
                          },
                        },
                      },
                      [_vm._v("Remove")]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "button-align": "right" } },
                [
                  _c("el-button", { on: { click: _vm.addMember } }, [
                    _vm._v("Add Member"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateAndSubmit("createForm")
                        },
                      },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog--condense dialog--padded",
          attrs: {
            "group-none": "requestDialog",
            title: "Request Invitation",
            visible: _vm.requestVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.requestVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "requestForm",
              attrs: {
                "label-width": "150px",
                model: _vm.requestForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Group Name", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { type: "name" },
                    model: {
                      value: _vm.requestForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.requestForm, "name", $$v)
                      },
                      expression: "requestForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "button-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateAndSubmit("requestForm")
                        },
                      },
                    },
                    [_vm._v("Request")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }