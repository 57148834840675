var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "my-dialog": "",
        "show-close": _vm.showClose,
        type: _vm.type,
        visible: _vm.isVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
      },
    },
    [
      _vm.type == "danger"
        ? _c(
            "div",
            { staticClass: "dialog__icon" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"], size: "2x" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "success"
        ? _c(
            "div",
            { staticClass: "dialog__icon" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "check-circle"], size: "2x" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm._t("content"),
      _vm._v(" "),
      _c("section", { staticClass: "dialog__options" }, [_vm._t("options")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }