var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "content--narrow",
      attrs: { light: _vm.light ? true : false },
    },
    [
      _vm.is401
        ? [
            _c("h1", [_vm._v("401")]),
            _vm._v(" "),
            _c("h2", [_vm._v("Unauthorized")]),
          ]
        : _vm.is429
        ? [
            _c("h1", [_vm._v("429")]),
            _vm._v(" "),
            _c("h2", [_vm._v("Too Many Requests")]),
          ]
        : [
            _c("h1", [_vm._v("404")]),
            _vm._v(" "),
            _c("h2", [_vm._v("Page Not Found")]),
          ],
      _vm._v(" "),
      _c("div", { attrs: { id: "sadSVG" } }, [
        _c(
          "svg",
          {
            attrs: {
              width: "100%",
              height: "333px",
              viewBox: "0 0 546 333",
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
            },
          },
          [
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      x1: "57721%",
                      y1: "45304%",
                      x2: "57721%",
                      y2: "40671%",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.25",
                        offset: "0%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.12",
                        offset: "54%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.1",
                        offset: "100%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      x1: "66897%",
                      y1: "45304%",
                      x2: "66897%",
                      y2: "40671%",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.25",
                        offset: "0%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.12",
                        offset: "54%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.1",
                        offset: "100%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      x1: "76073%",
                      y1: "45304%",
                      x2: "76073%",
                      y2: "40671%",
                    },
                  },
                  [
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.25",
                        offset: "0%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.12",
                        offset: "54%",
                      },
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: {
                        "stop-color": "#808080",
                        "stop-opacity": "0.1",
                        offset: "100%",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "g",
              {
                attrs: {
                  stroke: "none",
                  "stroke-width": "1",
                  fill: "none",
                  "fill-rule": "evenodd",
                  opacity: "0.479506341",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      transform: "translate(-1025.000000, -453.000000)",
                      "fill-rule": "nonzero",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "translate(1025.000000, 453.000000)",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: {
                            fill: "#FFFFFF",
                            x: "5",
                            y: "5",
                            width: "536",
                            height: "323",
                            rx: "8",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#00384A",
                            x: "37",
                            y: "44",
                            width: "473",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#D0F1FC",
                            x: "37",
                            y: "84",
                            width: "473",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#C4DDEB",
                            x: "37",
                            y: "204",
                            width: "473",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#C4DDEB",
                            x: "37",
                            y: "124",
                            width: "473",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#50C6E3",
                            x: "107",
                            y: "124",
                            width: "90",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#50C6E3",
                            x: "350",
                            y: "124",
                            width: "90",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#D0F1FC",
                            x: "37",
                            y: "244",
                            width: "473",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "#D0F1FC",
                            x: "37",
                            y: "164",
                            width: "473",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "url(#linearGradient-1)",
                            opacity: "0.5",
                            x: "342",
                            y: "51",
                            width: "44",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "url(#linearGradient-2)",
                            opacity: "0.5",
                            x: "401",
                            y: "51",
                            width: "44",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            fill: "url(#linearGradient-3)",
                            opacity: "0.5",
                            x: "460",
                            y: "51",
                            width: "44",
                            height: "30",
                          },
                        }),
                        _vm._v(" "),
                        _c("polygon", {
                          attrs: {
                            fill: "#FFB1D6",
                            points:
                              "167 154 167 276.832031 137 276.832031 137 154",
                          },
                        }),
                        _vm._v(" "),
                        _c("polygon", {
                          attrs: {
                            fill: "#FFB1D6",
                            points:
                              "410 154 410 276.832031 380 276.832031 380 154",
                          },
                        }),
                        _vm._v(" "),
                        _c("polygon", {
                          attrs: {
                            fill: "#00384A",
                            points:
                              "226 204 275.029877 204 323 204 323 244 274.5 244 226 244",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }