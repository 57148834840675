var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("secondary-layout", { attrs: { isAuthing: _vm.isAuthing } }, [
    _c(
      "section",
      { staticClass: "content__wrap content__wrap--login" },
      [
        _vm.social
          ? [
              _c(
                "div",
                {
                  staticClass: "content content--narrow",
                  attrs: { authVerify: "" },
                },
                [
                  _c(
                    "entry-container",
                    { attrs: { form: "false", button: "false", slim: "true" } },
                    [
                      _c(
                        "h3",
                        { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                        [_vm._v("Account created")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: {
                            slot: "entryBody",
                            entryContainer: "entry__content__inner",
                          },
                          slot: "entryBody",
                        },
                        [
                          _c("h1", [_vm._v("Welcome to ZingSoft!")]),
                          _vm._v(" "),
                          _vm.name
                            ? _c("h3", [_vm._v("Hi " + _vm._s(_vm.name) + "!")])
                            : _c("h3", [_vm._v("Hi!")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "To complete your account setup, please log back in."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.loginGoogle($event, true)
                                },
                              },
                            },
                            [
                              _c(
                                "google-entry",
                                {
                                  attrs: {
                                    "regenerate-session": _vm.regenerateSession,
                                  },
                                },
                                [_vm._v("Log In With Google")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          : [
              _c(
                "div",
                { staticClass: "content content--narrow" },
                [
                  _vm.planId && _vm.planInterval
                    ? _c("div", { staticClass: "purchaseNotification" }, [
                        _vm._v(
                          "\n          Please login or sign up to continue your purchase.\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("entry-container", { attrs: { slim: _vm.slim } }, [
                    _c(
                      "h3",
                      { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                      [_vm._v("Log in to your account")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { slot: "entryBody" }, slot: "entryBody" },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.loginGoogle($event, false)
                              },
                            },
                          },
                          [
                            _c(
                              "google-entry",
                              {
                                attrs: {
                                  "regenerate-session": _vm.regenerateSession,
                                },
                              },
                              [_vm._v("Log in with Google")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "margin--small--laptop" }, [
                          _vm._v("or"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            ref: "loginForm",
                            attrs: { model: _vm.form },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _c("input-email", {
                              attrs: { form: "form" },
                              model: {
                                value: _vm.form.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "email", $$v)
                                },
                                expression: "form.email",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "password",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input password",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      tabindex: "2",
                                      type: "password",
                                      placeholder: "Enter your password",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.validateForm("password")
                                      },
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.login.apply(null, arguments)
                                      },
                                    },
                                    model: {
                                      value: _vm.form.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "password", $$v)
                                      },
                                      expression: "form.password",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "auth0-lock-icon auth0-lock-icon-box",
                                          attrs: {
                                            width: "11",
                                            height: "14",
                                            viewBox: "0 0 13 16",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex-container" },
                              [
                                _c(
                                  "p",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link--blue",
                                        attrs: { to: "/forgot" },
                                      },
                                      [_vm._v("Forgot password?")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "button button--bottom",
                                        attrs: {
                                          type: "primary",
                                          disabled: !_vm.validLogin,
                                        },
                                        on: { click: _vm.login },
                                      },
                                      [_vm._v("Log In")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "p--terms" }, [
                          _vm._v("\n              View our\n              "),
                          _c(
                            "a",
                            {
                              staticClass: "term_link",
                              attrs: { href: "https://www.zinggrid.com/legal" },
                            },
                            [_vm._v("Terms of Service")]
                          ),
                          _vm._v("\n              and \n              "),
                          _c(
                            "a",
                            {
                              staticClass: "term_link",
                              attrs: {
                                href: "https://www.zinggrid.com/privacy",
                              },
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "signup__container" }, [
                    _c("p", { staticClass: "margin--small--laptop" }, [
                      _vm._v(
                        "\n            Don't have an account yet?\n            "
                      ),
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.passQueryParam(false)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "signup__link" }, [
                            _vm._v("Create account."),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }