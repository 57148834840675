<template>
  <el-dialog
    my-dialog
    licensePurchaseDialog
    :visible.sync="isVisible">
    <div v-if="phase1">
      <h2>License Already Purchased</h2>
      <p>It looks like you already own a {{ planName }} license.</p>
      <p>Are you trying to renew your existing license or purchase another one?</p>

      <div class="dialog__controls">
        <el-button @click="buySubscription()">Continue Purchase</el-button>
        <el-button type="primary" @click="continueRenewSubscription()">Renew</el-button>
      </div>
    </div>
    <div v-else>
      <h2>Renew License</h2>
      <p>Your purchase has been updated to a renewal.</p>
      <p>In the next view, review the updated information and click the "Renew" button.</p>

      <div class="dialog__controls">
        <el-button type="primary" @click="continueRenewal()">Continue Renewal</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import MixinPurchaseSteps from '../subscriptionMixins/PurchaseSteps.js';
  import MixinSubscriptions from '../subscriptionMixins/Subscriptions.js';

  export default {
    mixins: [MixinPurchaseSteps, MixinSubscriptions],
    props: {
      showClose: { type: Boolean, default: true, },
      visible: { type: Boolean, default: false },
      plan: { type: Object, default: null },
      domains: { type: Array, default: null },
      subdomains: { type: Array, default: null },
      userId: { type: Number, default: null },
    },
    data() {
      return {
        // If true, prompts user if they want to purchase or renew
        // else informs user that purchase converted to a renewal
        phase1: true,
      };
    },
    computed: {
      isVisible: {
        get() {
          this.phase1 = true;
          return this.visible;
        },
        set(newVal) {
          this.$emit('close');
        },
      },
      planName() {
        return this.plan ? this.plan.Name : null;
      },
      planSku() {
        return this.plan ? this.plan.Sku : null;
      },
      planPrice() {
        return this.plan ? this.plan.UnitPrice : null;
      }
    },
    methods: {
      buySubscription() {
        // Close dialog
        this.isVisible = false;

        // Continue subscription process
        this.$emit('purchase');
      },
      continueRenewSubscription() {
        // Update text to inform user information updated
        this.phase1 = false;
      },
      continueRenewal() {
        // Close dialog
        this.isVisible = false;

        // Continue subscription process
        this.$emit('renew');
      }
    },
  }
</script>

<style scoped>
  [licensePurchaseDialog] h2 { text-align: center; }
  [licensePurchaseDialog] .el-button.el-button--default {
    border-color: #DCDFE6 !important;
    color: rgb(96, 98, 102) !important;
  }
</style>