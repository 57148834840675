var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "secondary-layout",
    { attrs: { slim: "true", resetPassword: "" } },
    [
      _c("section", { staticClass: "content__wrap content__wrap--login" }, [
        _c(
          "div",
          { staticClass: "content content--narrow" },
          [_c("reset-modal")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }