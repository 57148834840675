var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isRedirecting
    ? _c("default-layout", [
        _c(
          "section",
          { staticClass: "content--narrow" },
          [
            _c(
              "header",
              { staticClass: "content__header content__header--expanded" },
              [
                _c("h2", [_vm._v("My Most Recent Demos")]),
                _vm._v(" "),
                _c("div", { staticClass: "content__header-more" }, [
                  _c(
                    "a",
                    { attrs: { href: "/demos" } },
                    [
                      _vm._v("All Demos "),
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "arrow-right"] },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("demo-viewer", { attrs: { add: true } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("section", { staticClass: "content--narrow" }, [
          _c(
            "header",
            { staticClass: "content__header content__header--expanded" },
            [
              _c("h2", [_vm._v("Updates")]),
              _vm._v(" "),
              _c("div", { staticClass: "content__header-more" }, [
                _c(
                  "a",
                  { attrs: { href: "https://blog.zingchart.com/" } },
                  [
                    _vm._v("View more updates "),
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "arrow-right"] },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "blog__feed" },
            _vm._l(_vm.blogPosts, function (post, index) {
              return _c(
                "article",
                { key: index, staticClass: "blog__article" },
                [
                  _c("a", { attrs: { href: post.url } }, [
                    _c("div", {
                      staticClass: "blog__image",
                      style: { "background-image": "url(" + post.image + ")" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "blog__content" }, [
                    _c("header", { staticClass: "blog__header" }, [
                      _c("h3", { staticClass: "blog__title" }, [
                        _c("a", { attrs: { href: post.url } }, [
                          _vm._v(_vm._s(post.title)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("section", { staticClass: "blog__summary" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(post.summary) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "blog__subheading" }, [
                        _c("div", { staticClass: "blog__date" }, [
                          _vm._v(_vm._s(post.date)),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }