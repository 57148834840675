var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", { attrs: { gridBody: true } }, [
    _c(
      "span",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _vm._v("\n    " + _vm._s(_vm.type) + " Site Analytics\n    "),
        _c("date-range", {
          attrs: { "value-format": "yyyy-MM-dd" },
          on: { updateDateRange: _vm.updateDateRange },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "el-select-wrapper",
            attrs: { "page-visitor-select": "" },
          },
          [
            _c(
              "el-select",
              {
                on: { change: _vm.updateTable },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              },
              _vm._l(_vm.selectOptions, function (group) {
                return _c(
                  "el-option-group",
                  { key: group.label, attrs: { label: group.label } },
                  _vm._l(group.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("svg-icon", {
              staticClass: "el-select-icon",
              attrs: { icon: "angle" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "span",
      { attrs: { slot: "header__right" }, slot: "header__right" },
      [
        _c("el-button", { on: { click: _vm.openMatomo } }, [
          _vm._v("Open Matomo"),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "section",
          { staticClass: "zingnetCard--row no-border" },
          [
            _c(
              "el-button",
              {
                attrs: { "subtable-back": "", disabled: _vm.disableBack },
                on: { click: _vm.back },
              },
              [_vm._v("Back")]
            ),
            _vm._v(" "),
            _c(
              "zing-grid",
              {
                attrs: {
                  "cell-break": "ellipsis",
                  "cell-class": "renderNA",
                  "column-controls": "",
                  filter: "",
                  height: "725",
                  main: "",
                  "no-border": "",
                  pager: "",
                  "page-size": "10",
                  selector: "",
                  sort: "",
                },
              },
              [
                _c(
                  "zg-colgroup",
                  [
                    _c("zg-column", {
                      attrs: {
                        index: "subtable",
                        sort: "disabled",
                        filter: "disabled",
                        renderer: "_renderSubtable",
                        width: "118px",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "label,url",
                        header: "Label",
                        width: "200px",
                        type: "custom",
                        renderer: "_renderUrl",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: { index: "nb_hits", header: "Pageviews" },
                    }),
                    _vm._v(" "),
                    _c("zg-column", { attrs: { index: "bounce_rate" } }),
                    _vm._v(" "),
                    _c("zg-column", { attrs: { index: "avg_time_on_page" } }),
                    _vm._v(" "),
                    _c("zg-column", { attrs: { index: "exit_rate" } }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: { index: "avg_time_generation" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }