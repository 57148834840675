var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.adminAccess
    ? _c(
        "section",
        { attrs: { "api-key": "" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "zing-grid",
            {
              attrs: {
                caption: "Existing keys",
                data: _vm.existingKeysData,
                layout: "row",
                "layout-controls": "disabled",
                pager: "",
                "page-size": "5",
              },
            },
            [
              _c(
                "zg-colgroup",
                [
                  _c("zg-column", {
                    attrs: { index: "key", header: "Key Prefix" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.generateKey } },
            [_vm._v("Generate Key")]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Create New API Key",
                visible: _vm.dialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  title: "API key will only be displayed now.",
                  type: "info",
                  closable: false,
                  "show-icon": "",
                },
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      Please store somewhere safe because this key cannot be generated or\n      retrieved again.\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { "api-key": "key-container" } },
                [
                  _c("el-input", {
                    ref: "apiKeyInput",
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.apiKey,
                      callback: function ($$v) {
                        _vm.apiKey = $$v
                      },
                      expression: "apiKey",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        content: "Copied!",
                        placement: "right",
                        effect: "light",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            size: "mini",
                            trigger: "click",
                            type: "primary",
                          },
                          on: { click: _vm.copyKey },
                          slot: "reference",
                        },
                        [_vm._v("Copy")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "content__header" }, [
      _c("h2", { staticClass: "header__title" }, [_vm._v("Profile")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }