var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "section",
      { staticClass: "content--narrow", attrs: { support: "" } },
      [
        _c("header", { staticClass: "content__header" }, [
          _c("h2", [_vm._v("Support")]),
        ]),
        _vm._v(" "),
        _c(
          "el-form",
          {
            attrs: {
              "label-position": _vm.isMobile ? "top" : "left",
              "label-width": "75px",
              model: _vm.form,
            },
          },
          [
            _c("el-form-item", { attrs: { label: "Email" } }, [
              _vm._v("\n        " + _vm._s(this.email) + "\n      "),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Subject" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Support Subject" },
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: {
                        label: "Trouble Ticket",
                        value: "troubleTicket",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "Sales Inquiry", value: "salesInquiry" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "Licensing", value: "licensing" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "Installation", value: "installation" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: {
                        label: "Troubleshooting",
                        value: "troubleshooting",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "Other", value: "other" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Product" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "medium" },
                    on: { change: _vm.handleRadioChange },
                    model: {
                      value: _vm.form.product,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "product", $$v)
                      },
                      expression: "form.product",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "zinggrid" } }, [
                      _vm._v("ZingGrid"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "zingchart" } }, [
                      _vm._v("ZingChart"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Title" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: _vm.MAX_TITLE,
                    placeholder: "Your Title Here",
                  },
                  model: {
                    value: _vm.form.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "title", $$v)
                    },
                    expression: "form.title",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Comment" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: _vm.MAX_BODY,
                    placeholder: "Your Comment Here",
                    rows: "10",
                    type: "textarea",
                  },
                  model: {
                    value: _vm.form.comment,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "comment", $$v)
                    },
                    expression: "form.comment",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", [
              _c("form", { attrs: { support: "form" } }, [
                _c("input", {
                  attrs: {
                    support: "createFile",
                    accept: _vm.acceptedFiletypes.toString(),
                    multiple: "",
                    type: "file",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.filesChange($event.target.files)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", { attrs: { support: "uploadText" } }, [
                _vm._v(
                  "The maximum you can upload is 10 files at 5MB. You have uploaded "
                ),
                _c("span", { staticStyle: { "font-weight": "600" } }, [
                  _vm._v(_vm._s(_vm.attachmentAmount) + "/10"),
                ]),
                _vm._v(" files at "),
                _c("span", { staticStyle: { "font-weight": "600" } }, [
                  _vm._v(_vm._s(_vm.attachmentSize) + "MB"),
                ]),
                _vm._v("."),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { "button-align": "right", size: "large" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled:
                        !_vm.createValidated ||
                        _vm.attachmentAmount > 10 ||
                        _vm.attachmentSize > 5,
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onSubmit()
                      },
                    },
                  },
                  [_vm._v("Create")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "my-dialog",
          {
            attrs: {
              title: "Message Sent",
              type: "success",
              visible: _vm.submitVisible,
            },
            on: {
              close: function ($event) {
                _vm.submitVisible = false
              },
            },
          },
          [
            _c("div", { attrs: { slot: "content" }, slot: "content" }, [
              _c("p", [
                _vm._v(
                  "Your message has been sent to support. We usually respond within 24 hours."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "options" }, slot: "options" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.submitVisible = false
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }