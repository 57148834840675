export default {
  data() {
    return {
      toggleSubscriptionDialogVisible: false,
      toggleRenewSubscriptionDialogVisible: false,
      toggleSubscriptionText: '',
      renewalAmount: '',
      renewalPlan: null,
      renewalInvoiceId: '',
      renewLoading: false,
      startRenew: true,
    };
  },
  computed: {
    disableRenewButton() {
      return !this.hasCard;
    },
  },
  methods: {
    closeRenewalDialog() {
      this.renewalAmount = '';
      this.renewalPlan = null;
      this.renewalInvoiceId = '';

      this.startRenew = true;
      this.renewLoading = false;
      this.toggleRenewSubscriptionDialogVisible = false;
    },
    setupRenewalDialogTokens() {},
    renewSubscriptionById() {
      let planName = this.renewalPlan.planName.split(':').pop();
      let sku = this.products.filter(p => p.Name === planName)[0].Sku;
      let renewalName = planName.replace('-A', '-R');
      let renewalSku = sku.replace('-A', '-R').replace('-S', '');

      // Renew button set to "loading"
      this.renewLoading = true;

      this.$api('subscription/renew', {
        invoiceId: this.renewalInvoiceId,
        renewalName,
        renewalSku,
        sku,
      })
      .then((result) => {
        this.renewLoading = false;
        this.startRenew = false;
        this.$message({
          message: 'Subscription renewed!',
          showClose: true,
          type: 'success',
        });
        this.getUserSubscriptions();
      })
      .catch((error) => {
        this.renewLoading = false;
        this.$message({
          duration: 10000,
          message: 'Cannot renew',
          showClose: true,
          type: 'error',
        });
      });
    },
  }
};