var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.activeTab == "createRequest" ? _c("group-create") : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.myGroups, function (group) {
        return _c(
          "div",
          {
            key: group.id,
            attrs: { label: group.name, name: `${group.id.toString()}` },
          },
          [
            _vm.activeTab == group.id
              ? [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.routeToDemos(group.id)
                        },
                      },
                    },
                    [_vm._v("View Demos")]
                  ),
                  _vm._v(" "),
                  _c("toggle-grid", {
                    attrs: {
                      caption: "Your Group Permissions",
                      "group-id": group.id,
                      "hide-list": "",
                      paging: "",
                      "set-all": "",
                      "request-detail": "/api/feature/get/list",
                      "request-set": "/api/feature/set",
                      type: "permissions_group",
                    },
                  }),
                  _vm._v(" "),
                  _c("toggle-grid", {
                    attrs: {
                      "group-admin": "",
                      "button-text": "Edit / View",
                      caption: "Group Admin Dashboard",
                      paging: "",
                      "group-id": group.id,
                      "remove-text": "Remove",
                      "request-detail": "/api/feature/list/user",
                      "request-delete": "TODO",
                      "request-list": "/api/grouping/list/users",
                      "request-set": "/api/feature/set/user",
                      "set-all": "",
                      "set-role": "",
                      type: "permissions_group_admin",
                    },
                  }),
                  _vm._v(" "),
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.deleteVisible = true
                        },
                      },
                    },
                    [_vm._v("Delete Group")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.leaveVisible = true
                        },
                      },
                    },
                    [_vm._v("Leave Group")]
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog--condense",
          attrs: { visible: _vm.deleteVisible },
          on: {
            "update:visible": function ($event) {
              _vm.deleteVisible = $event
            },
          },
        },
        [
          _c(
            "h2",
            { staticClass: "dialog__icon", staticStyle: { color: "#f56c6c" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"], size: "3x" },
              }),
              _c("br"),
            ],
            1
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Are you sure you want to delete this group?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You will not be able to revert your changes once deletion is confirmed."
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog__controls" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDelete()
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog--condense",
          attrs: { visible: _vm.leaveVisible },
          on: {
            "update:visible": function ($event) {
              _vm.leaveVisible = $event
            },
          },
        },
        [
          _c(
            "h2",
            { staticClass: "dialog__icon", staticStyle: { color: "#f56c6c" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"], size: "3x" },
              }),
              _c("br"),
            ],
            1
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Are you sure you want to leave this group?")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog__controls" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.leaveVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.confirmLeave } },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "content__header" }, [
      _c("h2", [_vm._v("Group Demos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "content__header" }, [
      _c("h2", [_vm._v("Group Settings")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }