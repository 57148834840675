var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "section",
      { staticClass: "content--narrow" },
      [
        _c("header", { staticClass: "content__header" }, [
          _c("h2", { staticClass: "header__title" }, [_vm._v("Profile")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "header__items default__setting" },
            [
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.isMobile ? "Default" : "Default Homepage") + ": "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  on: { change: _vm.updateReferrer },
                  model: {
                    value: _vm.defaultHomepage,
                    callback: function ($$v) {
                      _vm.defaultHomepage = $$v
                    },
                    expression: "defaultHomepage",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "zingchart" } }, [
                    _vm._v(_vm._s(_vm.isMobile ? "ZC" : "ZingChart")),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "zinggrid" } }, [
                    _vm._v(_vm._s(_vm.isMobile ? "ZG" : "ZingGrid")),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "form__collection form__collection--reverse" },
          [
            _c("div", { staticClass: "form__col" }, [
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c("name-field", {
                    attrs: { value: _vm.name },
                    on: { change: _vm.bufferProfile },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c("company-field", {
                    attrs: { value: _vm.company },
                    on: { change: _vm.bufferProfile },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c("phone-field", {
                    attrs: { value: _vm.phone },
                    on: { change: _vm.bufferProfile },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c("location-field", {
                    attrs: { value: _vm.location },
                    on: { change: _vm.bufferProfile },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c("billing-field", {
                    attrs: { value: _vm.billing },
                    on: { change: _vm.bufferProfile },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.isProfileDirty, type: "primary" },
                      on: { click: _vm.updateUserInfo },
                    },
                    [_vm._v("Save Profile")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form__col" }),
          ]
        ),
        _vm._v(" "),
        _c("header", { staticClass: "content__header" }, [
          _c("h2", [_vm._v("Authentication")]),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "form__collection form__collection--reverse" },
          [
            _c("div", { staticClass: "form__col" }, [
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c("email-form", {
                    attrs: { value: _vm.userEmail },
                    on: {
                      verify: _vm.emailVerification,
                      change: _vm.bufferEmail,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form__row" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.isUsernameOrEmail,
                        type: "primary",
                      },
                      on: { click: _vm.delegateAuthCalls },
                    },
                    [_vm._v("Change E-mail")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form__col" }),
          ]
        ),
        _vm._v(" "),
        _c("header", { staticClass: "content__header" }, [
          _c("h2", [_vm._v("Password")]),
        ]),
        _vm._v(" "),
        _c("password-form", {
          attrs: { email: this.userEmail },
          on: { change: _vm.passwordUpdate },
        }),
        _vm._v(" "),
        _c("header", { staticClass: "content__header" }, [
          _c("h2", { staticClass: "header__title" }, [_vm._v("Billing")]),
        ]),
        _vm._v(" "),
        _c("credit-card"),
        _vm._v(" "),
        _c("api-key", {
          attrs: { adminAccess: _vm.adminAccess },
          on: {
            "update:adminAccess": function ($event) {
              _vm.adminAccess = $event
            },
            "update:admin-access": function ($event) {
              _vm.adminAccess = $event
            },
          },
        }),
        _vm._v(" "),
        _c("account-settings", {
          attrs: { adminAccess: _vm.adminAccess },
          on: {
            "update:adminAccess": function ($event) {
              _vm.adminAccess = $event
            },
            "update:admin-access": function ($event) {
              _vm.adminAccess = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }