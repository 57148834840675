var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      { staticClass: "flex content__wrap--sidebar" },
      [
        _c("app-sidebar", {
          attrs: {
            "active-primary": _vm.activePrimary,
            "active-secondary": _vm.activeSecondary,
            open: _vm.sidebarOpen,
            sidebars: _vm.sidebarPrimary,
          },
          on: {
            "update-secondary": _vm.updateTab,
            "update-primary": _vm.updateView,
          },
        }),
        _vm._v(" "),
        _c("section", { staticClass: "content--hasSidebar" }, [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.authenticated,
                expression: "authenticated",
              },
            ],
          }),
          _vm._v(" "),
          _c("header", { attrs: { groups: _vm.activePrimary == "groups" } }, [
            _c("h1", { staticClass: "content__header" }, [
              _vm._v(_vm._s(_vm.header)),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "header-container" }, [
              _c(
                "div",
                { staticClass: "tab-scroller mobile--hide" },
                [
                  _vm._l(_vm.sidebarSecondary, function (item, index) {
                    return [
                      index !== 0
                        ? _c(
                            "span",
                            {
                              staticClass: "tab",
                              class: `${
                                item.id == _vm.activeSecondary ? "active" : ""
                              }`,
                              attrs: { tab: item.id },
                              on: {
                                click: function ($event) {
                                  return _vm.updateTab(item.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activePrimary != "groups",
                      expression: "activePrimary != 'groups'",
                    },
                  ],
                  staticClass: "header-container__right",
                },
                [
                  _c("div", { staticClass: "tab-group tab-group--view" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab tab--small trigger",
                        class: _vm.activeViewMode("list"),
                        attrs: { name: "list" },
                        on: {
                          click: function ($event) {
                            return _vm.updateViewMode("list")
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "zoom",
                          attrs: { icon: ["fas", "th-list"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab tab--small trigger",
                        class: _vm.activeViewMode("grid"),
                        attrs: { name: "grid" },
                        on: {
                          click: function ($event) {
                            return _vm.updateViewMode("grid")
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "zoom",
                          attrs: { icon: ["fas", "th"] },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "main",
            [
              _vm.activePrimary != "groups"
                ? [
                    _vm.activeSecondary && _vm.IEorEdge
                      ? _c("demo-viewer2", {
                          ref: _vm.activeSecondary,
                          attrs: {
                            "demo-type":
                              _vm.activePrimary != "groups"
                                ? _vm.activeSecondary
                                : null,
                            group:
                              _vm.activePrimary == "groups"
                                ? _vm.activeSecondary
                                : false,
                            mode: _vm.viewMode,
                            pagination: true,
                            search: true,
                            template: _vm.activePrimary == "create",
                          },
                          on: { "update-view": _vm.updateView },
                        })
                      : _vm.activeSecondary
                      ? _c("demo-viewer", {
                          ref: _vm.activeSecondary,
                          attrs: {
                            "demo-type": !(
                              _vm.activePrimary == "groups" ||
                              _vm.activeSecondary == "templates"
                            )
                              ? _vm.activeSecondary
                              : null,
                            mode: _vm.viewMode,
                            pagination: true,
                            search: true,
                            template: _vm.activePrimary == "create",
                            "user-id": _vm.userId,
                          },
                          on: { "update-view": _vm.updateView },
                        })
                      : _vm._e(),
                  ]
                : _c("my-groups", {
                    attrs: { "active-tab": _vm.activeSecondary },
                    on: { "update-view": _vm.updateView },
                  }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }