var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form__item" },
    [
      _c("label", { staticClass: "form__label", attrs: { id: "company" } }, [
        _vm._v("Company"),
      ]),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "form__field",
        attrs: { placeholder: "Your Company", for: "company", maxlength: "75" },
        model: {
          value: _vm.company_,
          callback: function ($$v) {
            _vm.company_ = $$v
          },
          expression: "company_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }