var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", { attrs: { gridBody: true } }, [
    _c(
      "span",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _vm._v("\n    CDN Tracker for Library Usage\n    "),
        _c("date-range", {
          attrs: {
            "additional-items": _vm.dateRangeItems,
            "group-name": _vm.dateRangeGroupName,
          },
          on: { updateDateRange: _vm.updateDateRange },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c("section", { staticClass: "zingnetCard--row" }, [
          _c("section", { staticClass: "zingnetCard--column" }, [
            _c("h3", { staticClass: "zingnetCard--header" }, [
              _vm._v("ZingChart CDN Top Referer Domains"),
            ]),
            _vm._v(" "),
            _vm.cdnData
              ? _c(
                  "ol",
                  { staticClass: "zingnetCard--list" },
                  _vm._l(_vm.cdnData.zingchartDomains, function (li) {
                    return _c(
                      "li",
                      { key: li.id },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "zingnetCard--list--item",
                            on: {
                              click: function ($event) {
                                return _vm.openModal(li)
                              },
                            },
                          },
                          [_vm._v(_vm._s(li.domain))]
                        ),
                        _vm._v(" "),
                        _c("bubble-count", { attrs: { count: li.hits } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `https://${li.domain}`,
                              target: "_blank",
                              rel: "noreferrer noopener",
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "domain-link": "",
                                icon: "open",
                                height: _vm.outLinkSize,
                                width: _vm.outLinkSize,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "zingnetCard--column" }, [
            _c("h3", { staticClass: "zingnetCard--header" }, [
              _vm._v("ZingGrid CDN Top Referer Domains"),
            ]),
            _vm._v(" "),
            _vm.cdnData
              ? _c(
                  "ol",
                  { staticClass: "zingnetCard--list" },
                  _vm._l(_vm.cdnData.zinggridDomains, function (li) {
                    return _c(
                      "li",
                      { key: li.id },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "zingnetCard--list--item",
                            on: {
                              click: function ($event) {
                                return _vm.openModal(li)
                              },
                            },
                          },
                          [_vm._v(_vm._s(li.domain))]
                        ),
                        _vm._v(" "),
                        _c("bubble-count", { attrs: { count: li.hits } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: `https://${li.domain}`,
                              target: "_blank",
                              rel: "noreferrer noopener",
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "domain-link": "",
                                icon: "open",
                                height: _vm.outLinkSize,
                                width: _vm.outLinkSize,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "zingnetCard--column" }, [
            _c(
              "h3",
              { staticClass: "zingnetCard--header" },
              [
                _vm._v("CDN Usage "),
                _vm.cdnData
                  ? _c("bubble-count", {
                      attrs: {
                        "comparison-cur": _vm.cdnData.weekHits,
                        "comparison-prev": _vm.cdnData.prevWeekHits,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "zingnetCard--row" },
              [
                _vm.cdnData
                  ? _c(
                      "score-card-group",
                      [
                        _c("score-card", {
                          attrs: {
                            slot: "card_one",
                            value: _vm.cdnData.weekHits,
                            title: `hits in last ${_vm.daysDiff} days`,
                          },
                          slot: "card_one",
                        }),
                        _vm._v(" "),
                        _c("score-card", {
                          attrs: {
                            slot: "card_two",
                            value: _vm.cdnData.prevWeekHits,
                            title: `hits in previous ${_vm.daysDiff} days`,
                            secondary: true,
                          },
                          slot: "card_two",
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "zingnetCard--row no-border" }, [
              _c("div", { attrs: { id: "chart-cdnUsage" } }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "zingnetCard--row no-border" },
          [
            _c(
              "zing-grid",
              {
                attrs: {
                  id: "zingNet_cdnTracker",
                  data: '[{"hits": null, "domain": null, "uris": null}]',
                  filter: "",
                  height: "650",
                  pager: "",
                  "page-size": "10",
                  selector: "",
                  sort: "",
                },
              },
              [
                _c(
                  "zg-colgroup",
                  [
                    _c("zg-column", {
                      attrs: {
                        index: "hits",
                        filter: "disabled",
                        type: "number",
                        width: "10%",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "domain",
                        renderer: "renderDomain",
                        width: "30%",
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "uris",
                        header: "URI",
                        width: "60%",
                        hidden: _vm.hiddenUri,
                      },
                    }),
                    _vm._v(" "),
                    _c("zg-column", {
                      attrs: {
                        index: "first_seen",
                        type: "date",
                        width: "60%",
                        hidden: _vm.hiddenSeen,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("domain-modal", {
          attrs: { "domain-obj": _vm.domainObj, visible: _vm.visible },
          on: {
            openUserModal: _vm.showUserDetails,
            close: function ($event) {
              _vm.visible = false
            },
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        }),
        _vm._v(" "),
        _c("user-modal", {
          attrs: {
            company: _vm.company,
            email: _vm.email,
            id: _vm.userId,
            "quickbooks-id": _vm.quickbooksId,
            visible: _vm.userModalVisible,
          },
          on: {
            close: function ($event) {
              _vm.userModalVisible = false
            },
            "update:visible": function ($event) {
              _vm.userModalVisible = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }