var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("filter-selector", {
    ref: "filterSelector",
    attrs: {
      clearable: true,
      options: _vm.possibleTags,
      placeholder: "Filter By Tag...",
    },
    on: {
      authenticated: _vm.getTags,
      filterBy: _vm.filter,
      "update:options": function ($event) {
        _vm.possibleTags = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }