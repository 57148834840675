var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { "domain-modal": "", visible: _vm.visible, width: "1200px" },
      on: { open: _vm.getData, close: _vm.triggerClose },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("Referrer info for\n      "),
          _vm.domainObj
            ? _c(
                "a",
                {
                  staticClass: "title--link",
                  attrs: {
                    href: `https://${_vm.domainObj.domain}`,
                    target: "_blank",
                    rel: "noreferrer noopener",
                  },
                },
                [_vm._v(_vm._s(_vm.domainObj.domain))]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c("div", [
          _c("section", [
            _c("p", { staticClass: "zingnetCard--header" }, [
              _vm._v("Hit Frequency"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("CDN received "),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.domainObj ? _vm.domainObj.hits : "") + " hits"
                ),
              ]),
              _vm._v(" from this referrer in this time period."),
            ]),
          ]),
          _vm._v(" "),
          _c("section", [
            _c("p", { staticClass: "zingnetCard--header" }, [
              _vm._v("Licensing"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                _vm.licenses && _vm.licenses.size > 0
                  ? [
                      _c(
                        "ul",
                        [
                          _vm._l(_vm.licenses, function (license) {
                            return [
                              _c(
                                "a",
                                {
                                  key: license,
                                  attrs: {
                                    href: `https://c11.qbo.intuit.com/app/customerdetail?nameId=${_vm.quickbooksId}`,
                                    target: "_blank",
                                  },
                                },
                                [_c("li", [_vm._v(_vm._s(license))])]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c(
                        "callout",
                        { attrs: { type: "danger", icon: "info" } },
                        [
                          _c(
                            "p",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "This domain is not associated with a ZingChart or ZingGrid license"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.userId || _vm.quickbooksId
            ? _c("section", [
                _c("p", { staticClass: "zingnetCard--header" }, [
                  _vm._v("Associated User"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "text--link",
                    on: {
                      click: function ($event) {
                        return _vm.openUserModal(_vm.userId, _vm.quickbooksId)
                      },
                    },
                  },
                  [_vm._v("More details")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section",
            [
              _c("p", { staticClass: "zingnetCard--header" }, [
                _vm._v("Add a Note about this referrer"),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "Add your note here.",
                },
                model: {
                  value: _vm.newNote,
                  callback: function ($$v) {
                    _vm.newNote = $$v
                  },
                  expression: "newNote",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { span: "" }, on: { click: _vm.addNote } },
                [_vm._v("Add Note")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("section", [
            _c(
              "p",
              { staticClass: "zingnetCard--header" },
              [
                _vm._v("\n          Notes about this referrer\n          "),
                _c("bubble-count", {
                  attrs: { count: _vm.notesCount, after: true },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "domainNote--container" },
              _vm._l(_vm.notes, function (note) {
                return _c("div", { key: note.id, staticClass: "domainNote" }, [
                  _c("div", { staticClass: "domainNote--header" }, [
                    _vm._v(
                      "Written " + _vm._s(_vm.dateFromNow(note.created_at, ""))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "domainNote--body" }, [
                    _vm._v(_vm._s(note.note)),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }