var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("secondary-layout", { attrs: { slim: "true" } }, [
    _c("section", { staticClass: "content__wrap content__wrap--login" }, [
      _c(
        "div",
        { staticClass: "content content--narrow" },
        [
          _c(
            "entry-container",
            { attrs: { form: "false", slim: "true", verify: "" } },
            [
              _c(
                "h3",
                { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                [_vm._v("Email Verified")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  attrs: {
                    slot: "entryBody",
                    entryContainer: "entry__content__inner",
                  },
                  slot: "entryBody",
                },
                [
                  _c(
                    "h1",
                    { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                    [_vm._v("Welcome to ZingSoft!")]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("Thank you for creating an account.")]),
                  _vm._v(" "),
                  _c("p", [_vm._v("To continue, please verify your email.")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "button button--bottom",
                      on: { click: _vm.goHome },
                    },
                    [_c("p", [_vm._v("Verify Email")])]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }