var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c(
      "span",
      { attrs: { slot: "header" }, slot: "header" },
      [
        _vm._v("\n    User Summary\n    "),
        _c("date-range", { on: { updateDateRange: _vm.updateDateRange } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c("section", { staticClass: "zingnetCard--row" }, [
          _c(
            "section",
            { staticClass: "zingnetCard--column" },
            [
              _c("h3", { staticClass: "zingnetCard--header" }, [
                _vm._v("New users"),
              ]),
              _vm._v(" "),
              _vm.newUsers
                ? _c(
                    "score-card-group",
                    [
                      _c("score-card", {
                        attrs: {
                          slot: "card_one",
                          value: _vm.newUsers.cur,
                          title: `in last ${_vm.daysDiff} days`,
                        },
                        slot: "card_one",
                      }),
                      _vm._v(" "),
                      _c("score-card", {
                        attrs: {
                          slot: "card_two",
                          value: _vm.newUsers.prev,
                          title: `in previous ${_vm.daysDiff} days`,
                          secondary: true,
                        },
                        slot: "card_two",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "zingnetCard--row no-border" }, [
                _c("div", { attrs: { id: "chart-newUsers" } }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("section", { staticClass: "zingnetCard--column" }, [
            _c("div", { attrs: { id: "chart-userSubscriptions" } }),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "zingnetCard--column" },
            [
              _c("h3", { staticClass: "zingnetCard--header" }, [
                _vm._v("Total users"),
              ]),
              _vm._v(" "),
              _vm.totalUsers
                ? _c(
                    "score-card-group",
                    [
                      _c("score-card", {
                        attrs: {
                          slot: "card_one",
                          value: _vm.totalUsers.cur,
                          title: `in last ${_vm.daysDiff} days`,
                        },
                        slot: "card_one",
                      }),
                      _vm._v(" "),
                      _c("score-card", {
                        attrs: {
                          slot: "card_two",
                          value: _vm.totalUsers.prev,
                          title: `in previous ${_vm.daysDiff} days`,
                          secondary: true,
                        },
                        slot: "card_two",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "zingnetCard--row no-border" }, [
                _c("div", { attrs: { id: "chart-totalUsers" } }),
              ]),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }