<template>
  <zingnet-card>
    <span slot="header">Quickbooks Linking</span>
    <p slot="subtitle">
      Lookup, update, or link customer's Studio account to Quickbooks client. <br/>
      This allows customers to view licenses on their Studio account.
    </p>
    <div slot="body">
      <section class="zingnetCard--row" quickbooks-link>
        <section class="zingnetCard--column">

          <!-- Form -->
          <el-form v-model="quickbooksLinkForm">
            <section>
              <h3 class="zingnetCard--header">Lookup</h3>
              <p>Select way to lookup customer information.</p>
              <el-select v-model="userInput" @change="clearUserInput" class="margin">
                <el-option key="user" label="Studio User ID" value="id"></el-option>
                <el-option key="email" label="Studio Email" value="email"></el-option>
                <el-option key="quickbooks" label="Quickbooks ID" value="quickbooks"></el-option>
              </el-select>
              <el-input v-show="userInput === 'id'" v-model="quickbooksLinkForm.id" placeholder="Studio User ID"></el-input>
              <el-input v-show="userInput === 'email'" v-model="quickbooksLinkForm.email" placeholder="Studio Email"></el-input>
              <el-input v-show="userInput === 'quickbooks'" v-model="quickbooksLinkForm.quickbooks" placeholder="QuickBooks ID"></el-input>
            </section>
            <section>
              <el-button @click="getLinkInfo" :disabled="disable || fetching">Get Information</el-button>
            </section>
            <section>
            </section>
          </el-form>
        </section>
        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">User Information</h3>
          <!-- Results -->
          <section>
            <zing-grid ref="linkGrid"
              default-display="N/A"
              editor="modal"
              :editor-controls="displayResults ? 'editor' : 'disabled'"
              layout="card"
              layout-controls="disabled">
              <zg-colgroup>
                <zg-column index="id" header="Studio User ID" editor="disabled" type="number" type-number-formatting="disabled"></zg-column>
                <zg-column index="email" header="Studio Email" editor="disabled"></zg-column>
                <zg-column index="quickbooks" header="Quickbooks ID" type="url" validator="text" type-url-src="https://app.qbo.intuit.com/app/customerdetail?nameId=[[index.quickbooks]]"></zg-column>
              </zg-colgroup>
            </zing-grid>
          </section>
        </section>
      </section>
    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    ZingnetCard,
  },
  data() {
    return {
      displayResults: false,
      fetching: false,
      userInput: 'email',
      quickbooksLinkForm: {
        email: null,
        quickbooks: null,
        id: null,
      },
      response: [{
        email: null,
        quickbooks: null,
        id: null,
      }],
      zgRef: null,
    };
  },
  computed: {
    disable() {
      return !this.quickbooksLinkForm.email && !this.quickbooksLinkForm.quickbooks && !this.quickbooksLinkForm.id;
    },
    headerVal() {
      return `{"Authorization": "Bearer ${this.$store.state.auth.idToken}"}`;
    },
  },
  mounted() {
    this.zgRef = this.$refs.linkGrid.setData(this.response);

    this.zgRef.addEventListener('data:cell:change', (e) => {
      this.linkAccount(e);
    });

    this.zgRef.addEventListener('data:record:change', (e) => {
      this.linkAccount(e);
    });
  },
  methods: {
    clearUserInput() {
      this.quickbooksLinkForm.email = null;
      this.quickbooksLinkForm.quickbooks = null;
      this.quickbooksLinkForm.id = null;
    },
    getLinkInfo() {
      this.fetching = true;

      axios({
        url: '/api/admin/user/linked',
        method: 'POST',
        headers: JSON.parse(this.headerVal),
        data: this.quickbooksLinkForm
      })
      .then((response) => {
        // Success message
        this.$message({
          message: 'Information Retrieved!',
          type: 'success',
        });

        this.$nextTick(() => {
          this.fetching = false;
          this.displayResults = true;
          this.clearUserInput();
          this.zgRef.setData([response.data]);
        });
      }).catch((err) => {
        this.fetching = false;

        // Error message
        let errMessage = `Error occurred while getting information: ${err}`;
        this.$message({
          message: errMessage,
          type: 'error',
        });
      });
    },
    linkAccount(e) {
      axios({
        url: '/api/admin/user/linked',
        method: 'PATCH',
        headers: JSON.parse(this.headerVal),
        data: e.detail.ZGData.data
      })
      .then((response) => {
        // Success message
        this.$message({
          message: 'Updated!',
          type: 'success',
        });
      }).catch((err) => {
        // Error message
        let errMessage = `Error occurred while updating: ${err}`;
        this.$message({
          message: errMessage,
          type: 'error',
        });
      });
    },
  }
}
</script>

<style scoped>
  [quickbooks-link] .el-select ~ .el-input {
    margin-top: 1rem;
  }
</style>
