var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "form__collection", staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "form__col" }, [
        _c(
          "div",
          { staticClass: "form__row" },
          [
            _c(
              "el-form",
              { staticClass: "form__item" },
              [
                _c(
                  "label",
                  { staticClass: "form__label", attrs: { for: "newPass" } },
                  [_vm._v("New password")]
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "form__field",
                  attrs: {
                    type: _vm.isPassword,
                    placeholder: "New password",
                    for: "new-password",
                  },
                  model: {
                    value: _vm.newPassword,
                    callback: function ($$v) {
                      _vm.newPassword = $$v
                    },
                    expression: "newPassword",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.newPassword !== "",
                expression: "newPassword !== ''",
              },
            ],
            staticClass: "form__row",
          },
          [
            _c(
              "el-form",
              { staticClass: "form__item" },
              [
                _c(
                  "label",
                  { staticClass: "form__label", attrs: { for: "confirmPass" } },
                  [_vm._v("Confirm new password")]
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "form__field",
                  attrs: {
                    type: _vm.isPassword,
                    placeholder: "Confirm new password",
                    for: "new-password",
                  },
                  model: {
                    value: _vm.confirmPassword,
                    callback: function ($$v) {
                      _vm.confirmPassword = $$v
                    },
                    expression: "confirmPassword",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__row" },
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.showPassword,
                  callback: function ($$v) {
                    _vm.showPassword = $$v
                  },
                  expression: "showPassword",
                },
              },
              [_vm._v("show password")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.resetPassword
          ? _c(
              "div",
              { staticClass: "form__row" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { disabled: !_vm.validPassword, type: "primary" },
                    on: { click: _vm.updatePassword },
                  },
                  [_vm._v("Reset Password")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form__col", staticStyle: { "min-width": "175px" } },
        [
          _vm.success
            ? _c("div", { staticClass: "status success" }, [
                _vm._v(
                  "We've sent a verification email to the new e-mail address provided."
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.failure
            ? _c("div", { staticClass: "status failure" }, [
                _vm._v("An error occurred: " + _vm._s(_vm.message)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showMismatch
            ? _c("span", { staticStyle: { color: "#f44336" } }, [
                _vm._v("Passwords do not match"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newPassword !== "",
                  expression: "newPassword !== ''",
                },
              ],
            },
            [
              _vm._v("\n      Password must:\n      "),
              _c("ul", { staticClass: "password-validation" }, [
                _c(
                  "li",
                  {
                    staticClass: "password",
                    class: _vm.validClass("atLeast8Chars"),
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: _vm.validIcon("atLeast8Chars") },
                    }),
                    _vm._v(" Be a minimum of 8 characters"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "password",
                    class: _vm.validClass("atLeast1Lower"),
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: _vm.validIcon("atLeast1Lower") },
                    }),
                    _vm._v(" Contain at least lower case letter"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "password",
                    class: _vm.validClass("atLeast1Upper"),
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: _vm.validIcon("atLeast1Upper") },
                    }),
                    _vm._v(" Contain at least 1 upper case letter"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "password",
                    class: _vm.validClass("atLeast1Number"),
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: _vm.validIcon("atLeast1Number") },
                    }),
                    _vm._v(" Contain at least 1 number"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "password",
                    class: _vm.validClass("notEmail"),
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: _vm.validIcon("notEmail") },
                    }),
                    _vm._v(" Not match email"),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }