var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        title: "Add Gallery Metadata",
        visible: _vm.visible,
        width: "50%",
      },
      on: { close: _vm.closeGalleryMetadata },
    },
    [
      _c(
        "el-form",
        { ref: "metadataForm", attrs: { model: _vm.galleryForm } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Metadata", "label-width": _vm.labelWidth } },
            [
              _c(
                "el-select",
                {
                  attrs: { "allow-create": "", filterable: "" },
                  on: {
                    change: function ($event) {
                      return _vm.clearGalleryForm(true)
                    },
                  },
                  model: {
                    value: _vm.galleryForm.metadata,
                    callback: function ($$v) {
                      _vm.$set(_vm.galleryForm, "metadata", $$v)
                    },
                    expression: "galleryForm.metadata",
                  },
                },
                _vm._l(_vm.galleryMetadataOptions, function (item) {
                  return _c("el-option", {
                    key: item.index,
                    attrs: {
                      disabled: _vm.metadataDisabled(item.value),
                      label: item.name,
                      value: item.value,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.galleryForm.metadata &&
          _vm.galleryForm.metadata != "" &&
          _vm.galleryForm.newOptions.length === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "Value", "label-width": _vm.labelWidth } },
                [
                  _vm.valueType("select", true)
                    ? _c(
                        "el-select",
                        {
                          attrs: {
                            "allow-create":
                              _vm.valueType("select") &&
                              _vm.galleryMetadata[_vm.galleryForm.metadata]
                                .addOption,
                            filterable: "",
                          },
                          on: { change: _vm.isCreateNew },
                          model: {
                            value: _vm.galleryForm.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.galleryForm, "value", $$v)
                            },
                            expression: "galleryForm.value",
                          },
                        },
                        _vm._l(
                          _vm.getMetadataOptions(_vm.galleryForm.metadata),
                          function (item) {
                            return _c("el-option", {
                              key: `${_vm.galleryForm.metadata}-${item.value}`,
                              attrs: {
                                label: _vm.getMetadataName(item),
                                value: `{"value": "${item.value}", "metadataUid": "${item.metadata_uid}"}`,
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm.valueType("url")
                    ? _c("el-input", {
                        attrs: { type: "url" },
                        model: {
                          value: _vm.galleryForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.galleryForm, "value", $$v)
                          },
                          expression: "galleryForm.value",
                        },
                      })
                    : _vm.valueType("boolean")
                    ? _c("el-checkbox", {
                        attrs: {
                          checked: "",
                          "true-label": "1",
                          "false-label": "0",
                        },
                        model: {
                          value: _vm.galleryForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.galleryForm, "value", $$v)
                          },
                          expression: "galleryForm.value",
                        },
                      })
                    : _c("el-input", {
                        model: {
                          value: _vm.galleryForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.galleryForm, "value", $$v)
                          },
                          expression: "galleryForm.value",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.valueType("select-url")
            ? [
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.galleryForm.value,
                        expression: "galleryForm.value",
                      },
                    ],
                    attrs: { label: "Pathname", "label-width": _vm.labelWidth },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { name: _vm.galleryForm.data, type: "text" },
                        model: {
                          value: _vm.galleryForm.data,
                          callback: function ($$v) {
                            _vm.$set(_vm.galleryForm, "data", $$v)
                          },
                          expression: "galleryForm.data",
                        },
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getRelatedDocsUrl(_vm.galleryForm.metadataUid)
                            )
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.valueType("select") &&
          _vm.galleryMetadata[_vm.galleryForm.metadata].addOption &&
          _vm.createSelect
            ? _c(
                "el-form-item",
                { attrs: { label: "Type", "label-width": _vm.labelWidth } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.galleryForm.demoType,
                        callback: function ($$v) {
                          _vm.$set(_vm.galleryForm, "demoType", $$v)
                        },
                        expression: "galleryForm.demoType",
                      },
                    },
                    _vm._l(_vm.demoTypeOptions, function (type) {
                      return _c(
                        "el-checkbox-button",
                        { key: type, attrs: { label: type } },
                        [_vm._v(_vm._s(type))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.valueType("select-url")
            ? [
                _c("el-divider"),
                _vm._v(" "),
                _vm._l(_vm.galleryForm.newOptions, function (newOption) {
                  return _c(
                    "el-form-item",
                    {
                      key: newOption.key,
                      attrs: {
                        label: `New Option`,
                        "label-width": _vm.labelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Label/Value" },
                        model: {
                          value: newOption.value,
                          callback: function ($$v) {
                            _vm.$set(newOption, "value", $$v)
                          },
                          expression: "newOption.value",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Url", type: "url" },
                        model: {
                          value: newOption.content,
                          callback: function ($$v) {
                            _vm.$set(newOption, "content", $$v)
                          },
                          expression: "newOption.content",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Pathname", type: "text" },
                        model: {
                          value: newOption.data,
                          callback: function ($$v) {
                            _vm.$set(newOption, "data", $$v)
                          },
                          expression: "newOption.data",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.galleryForm.demoType,
                            callback: function ($$v) {
                              _vm.$set(_vm.galleryForm, "demoType", $$v)
                            },
                            expression: "galleryForm.demoType",
                          },
                        },
                        _vm._l(_vm.demoTypeOptions, function (type) {
                          return _c(
                            "el-checkbox-button",
                            { key: type, attrs: { label: type } },
                            [_vm._v(_vm._s(type))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeNewOption(newOption)
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.galleryForm.newOptions < 1
                  ? _c("el-button", { on: { click: _vm.addFormField } }, [
                      _vm._v("Add new option"),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeGalleryMetadata } }, [
            _vm._v("Cancel"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.missingRequiredField },
              on: { click: _vm.addGalleryMetadata },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }