var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Library Releases"),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("section", { staticClass: "zingnetCard--row" }, [
        _c(
          "div",
          { staticClass: "zingnetCard--column column-45 flex no-padding" },
          [
            _c(
              "div",
              { staticClass: "zingnetCard--column" },
              [
                _c("h3", { staticClass: "zingnetCard--header" }, [
                  _vm._v("Latest ZingChart Release"),
                ]),
                _vm._v(" "),
                _c("score-card", {
                  attrs: {
                    value: _vm.zingchartVersion,
                    icon: "zingchart-logomark",
                    "icon-size": "29",
                    title: `Released on ${_vm.formatDate(
                      _vm.zingchartReleaseDate,
                      null,
                      "MMMM D, YYYY"
                    )}`,
                  },
                }),
                _vm._v(" "),
                _c("h3", { staticClass: "zingnetCard--header" }, [
                  _vm._v("# of Changelog Releases"),
                ]),
                _vm._v(" "),
                _c("score-card", {
                  attrs: { value: _vm.zingchartChangelog, title: "since 2015" },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openChangelog("zingchart")
                      },
                    },
                  },
                  [_vm._v("View Changelog")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "zingnetCard--column column-50" },
              [
                _c("h3", { staticClass: "zingnetCard--header" }, [
                  _vm._v("Latest ZingGrid Release"),
                ]),
                _vm._v(" "),
                _c("score-card", {
                  attrs: {
                    value: _vm.zinggridVersion,
                    icon: "zinggrid-logomark",
                    "icon-size": "29",
                    title: `Released on ${_vm.formatDate(
                      _vm.zinggridReleaseDate,
                      null,
                      "MMMM D, YYYY"
                    )}`,
                  },
                }),
                _vm._v(" "),
                _c("h3", { staticClass: "zingnetCard--header" }, [
                  _vm._v("# of Changelog Releases"),
                ]),
                _vm._v(" "),
                _c("score-card", {
                  attrs: { value: _vm.zinggridChangelog, title: "since 2018" },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openChangelog("zingchart")
                      },
                    },
                  },
                  [_vm._v("View Changelog")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "zingnetCard--column column-55",
          attrs: { id: "chart-versionBuilds" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }