var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        editorSetting: "",
        placement: "bottom",
        title: "View",
        trigger: "click",
      },
      on: { "after-leave": _vm.updateEditorSetting },
    },
    [
      !_vm.isMobile
        ? [
            _c(
              "div",
              { attrs: { editorSetting: "setting" } },
              [
                _c("p", { attrs: { editorSetting: "setting__title" } }, [
                  _vm._v("Layout:"),
                ]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: _vm.size },
                    on: { change: _vm.handleUpdate },
                    model: {
                      value: _vm.curLayout,
                      callback: function ($$v) {
                        _vm.curLayout = $$v
                      },
                      expression: "curLayout",
                    },
                  },
                  _vm._l(_vm.layoutOptions, function (option) {
                    return _c(
                      "el-radio-button",
                      { key: option, attrs: { label: option } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.renderLabel(option)) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-divider"),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { editorSetting: "setting" } },
        [
          _c("p", { attrs: { editorSetting: "setting__title" } }, [
            _vm._v("Font Family:"),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select" },
              on: { change: _vm.handleUpdate },
              model: {
                value: _vm.curFontFamily,
                callback: function ($$v) {
                  _vm.curFontFamily = $$v
                },
                expression: "curFontFamily",
              },
            },
            _vm._l(_vm.fontOptions, function (option) {
              return _c("el-option", {
                key: option,
                attrs: { label: _vm.renderLabel(option), value: option },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("p", [_vm._v("Font Size:")]),
          _vm._v(" "),
          _c("el-input-number", {
            attrs: { min: 1, size: _vm.size },
            on: { change: _vm.handleUpdate },
            model: {
              value: _vm.curFontSize,
              callback: function ($$v) {
                _vm.curFontSize = $$v
              },
              expression: "curFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { editorSetting: "setting" } },
        [
          _c("p", { attrs: { editorSetting: "setting__title" } }, [
            _vm._v("Theme:"),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "Select" },
              on: { change: _vm.handleUpdate },
              model: {
                value: _vm.curTheme,
                callback: function ($$v) {
                  _vm.curTheme = $$v
                },
                expression: "curTheme",
              },
            },
            _vm._l(_vm.themeOptions, function (option) {
              return _c("el-option", {
                key: option,
                attrs: { label: _vm.renderLabel(option), value: option },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { editorSetting: "setting" } },
        [
          _c("p", { attrs: { editorSetting: "setting__title" } }, [
            _vm._v("Previews:"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.tabOptions, function (option) {
            return [
              _c(
                "el-button",
                {
                  key: option,
                  attrs: { size: _vm.size },
                  on: {
                    click: function ($event) {
                      return _vm.handleUpdate(option)
                    },
                  },
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.renderLabel(option))),
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: ["fas", "external-link-alt"],
                      "layout-dropdown": "externalLink",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { slot: "reference", type: _vm.type, size: _vm.size },
          slot: "reference",
        },
        [
          _vm._v("View"),
          _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }