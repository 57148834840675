var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile
    ? _c(
        "el-dropdown",
        {
          attrs: { size: "mini", trigger: "click" },
          on: { command: _vm.handleUpdate },
        },
        [
          _vm.isMobile
            ? _c(
                "el-button",
                { attrs: { type: "primary", size: "mini" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "window-maximize"], size: "sm" },
                  }),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.showControlsOptions, function (option) {
              return _c(
                "el-dropdown-item",
                {
                  key: option,
                  attrs: { command: option, disabled: option === _vm.disabled },
                },
                [_vm._v(_vm._s(option.toUpperCase()))]
              )
            }),
            1
          ),
        ],
        1
      )
    : _c(
        "el-checkbox-group",
        {
          attrs: { min: 1, size: "mini" },
          on: { change: _vm.handleUpdate },
          model: {
            value: _vm.showControls,
            callback: function ($$v) {
              _vm.showControls = $$v
            },
            expression: "showControls",
          },
        },
        _vm._l(_vm.showControlsOptions, function (toggle, index) {
          return _c(
            "el-checkbox-button",
            { key: index, attrs: { label: toggle, size: "mini" } },
            [_vm._v("\n    " + _vm._s(toggle.toUpperCase()) + " \n  ")]
          )
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }