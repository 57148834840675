var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "demo-cards", attrs: { mode: _vm.mode } },
    [
      _c("header", { staticClass: "demo-controls" }, [
        _c("div", { staticClass: "demo-controls__left" }, [
          _c(
            "div",
            { staticClass: "demo-control__item" },
            [
              _c("tag-selector", {
                attrs: {
                  filterBy: _vm.tag,
                  group: _vm.group,
                  type: _vm.demoType,
                },
                on: {
                  "update:filterBy": function ($event) {
                    _vm.tag = $event
                  },
                  "update:filter-by": function ($event) {
                    _vm.tag = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "el-input-icon",
                attrs: { icon: "angle" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "demo-control__item" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.sortBy,
                    callback: function ($$v) {
                      _vm.sortBy = $$v
                    },
                    expression: "sortBy",
                  },
                },
                _vm._l(_vm.sortOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "el-input-icon",
                attrs: { icon: "angle" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "demo-controls__right" }, [
          _c(
            "div",
            { staticClass: "demo-control__item" },
            [
              _c("el-input", {
                attrs: {
                  search: "",
                  placeholder: "Search By...",
                  maxlength: "75",
                },
                model: {
                  value: _vm.watchSearch,
                  callback: function ($$v) {
                    _vm.watchSearch = $$v
                  },
                  expression: "watchSearch",
                },
              }),
              _vm._v(" "),
              _c("svg-icon", {
                staticClass: "el-input-icon",
                attrs: { icon: "search" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authenticated && _vm.loaded,
              expression: "authenticated && loaded",
            },
          ],
          staticClass: "demos",
          attrs: { template: _vm.template ? _vm.template : false },
        },
        [
          _vm.pagedDemos && _vm.pagedDemos.length > 0 && _vm.add
            ? [
                _vm._l(_vm.pagedDemos, function (demo, index) {
                  return [
                    _c("demo-card", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index < 5,
                          expression: "index < 5",
                        },
                      ],
                      key: demo.id,
                      attrs: {
                        demo: demo,
                        mode: _vm.mode,
                        template: _vm.template,
                      },
                      on: { delete: _vm.refreshDemos },
                    }),
                  ]
                }),
                _vm._v(" "),
                _c("demo-card", {
                  attrs: {
                    "demo-type": _vm.demoType,
                    dummy: "true",
                    template: _vm.template,
                  },
                }),
              ]
            : _vm.pagedDemos && _vm.pagedDemos.length > 0
            ? [
                _vm._l(_vm.pagedDemos, function (demo) {
                  return _c("demo-card", {
                    key: demo.id,
                    attrs: {
                      demo: demo,
                      mode: _vm.mode,
                      template: _vm.template,
                    },
                    on: { delete: _vm.refreshDemos },
                  })
                }),
                _vm._v(" "),
                _vm.pagedDemos &&
                _vm.pagedDemos.length < 6 &&
                _vm.group === false &&
                _vm.template === false
                  ? _c("demo-card", {
                      attrs: {
                        "demo-type": _vm.demoType,
                        dummy: "true",
                        mode: _vm.mode,
                        template: _vm.template,
                      },
                    })
                  : _vm._e(),
              ]
            : _vm.template && _vm.pagedDemos.length == 0
            ? [_vm._m(0)]
            : [
                _c(
                  "div",
                  {
                    staticClass: "demos no-demos",
                    attrs: { template: _vm.template ? _vm.template : false },
                  },
                  [
                    _c("demo-card", {
                      attrs: {
                        "demo-type": _vm.demoType,
                        dummy: "true",
                        mode: _vm.mode,
                        template: _vm.template,
                      },
                      on: {
                        "update-view": function ($event) {
                          return _vm.$emit(
                            "update-view",
                            "create",
                            _vm.demoType || null
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pagination,
              expression: "pagination",
            },
          ],
          staticClass: "demo-pagination",
        },
        [
          _c("el-pagination", {
            attrs: {
              layout: "prev, pager, next",
              total: _vm.totalDemos,
              pageSize: _vm.pageSize,
              "current-page": _vm.currentPage,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.handleChange,
              "next-click": _vm.handleNext,
              "prev-click": _vm.handlePrev,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { height: "400px" } }, [
      _c("h3", [_vm._v("You have not created any templates!")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "You can convert a demo in to a template in the demo's settings dialog."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }