var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("section", { staticClass: "content--narrow" }, [
        _c(
          "section",
          { staticClass: "content" },
          [
            _vm._l(_vm.productTypes, function (type) {
              return [
                _c(
                  "header",
                  { key: `${type}_header`, staticClass: "content__header" },
                  [
                    _c("h2", [
                      _vm._v("My \n            "),
                      type == "ZC"
                        ? _c(
                            "svg",
                            {
                              staticClass: "subscription__svg",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 145.1 47.6",
                              },
                            },
                            [
                              _c("g", { attrs: { "data-name": "Layer 2" } }, [
                                _c("g", { attrs: { "data-name": "Layer 1" } }, [
                                  _c("path", {
                                    attrs: {
                                      fill: "#00384a",
                                      d: "M0 37.5l11-12H.7v-4.8h17.5V25L6.9 37.3h11.7v4.8H0zM20.6 26.5h5.1v15.6h-5.1zM28 26.5h5v2.2a5.9 5.9 0 0 1 4.9-2.6c5.2 0 5.6 3.8 5.6 6v10h-5.1v-7.5c0-2.2.2-4.3-2.6-4.3-1.9 0-2.7 1.6-2.7 3.3v8.5H28zM60.9 40.2c0 2.6-.5 7.4-7.8 7.4-3.6 0-7-.8-7.7-5h5.1a1.7 1.7 0 0 0 1 1.3 4 4 0 0 0 1.8.4c2.4 0 2.6-1.8 2.6-3.7v-1.4a5 5 0 0 1-4.2 2.2c-4.7 0-6.8-3.3-6.8-7.7 0-4.1 2.4-7.6 6.8-7.6a4.5 4.5 0 0 1 4.2 2.4h.1v-2h4.9zM53 30c-2.3 0-3 2-3 3.9s.8 3.7 3 3.7 3-1.7 3-3.7-.8-3.9-3-3.9z",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      fill: "#00bbf1",
                                      d: "M79.2 27.5a5.1 5.1 0 0 0-5.3-4.3c-4.6 0-6.6 4-6.6 8.2s2 8.2 6.6 8.2c3.3 0 5.1-2.5 5.4-5.6H83a8.8 8.8 0 0 1-9.1 8.6c-6.4 0-10.3-5-10.3-11.2S67.5 20.2 74 20.2c4.8 0 8.6 2.7 9 7.3zM84.8 20.7h3.5v8a5.4 5.4 0 0 1 4.7-2.5c3.4 0 5.5 1.8 5.5 5.3V42H95v-9.7c0-2.5-1-3.5-3-3.5a3.8 3.8 0 0 0-3.7 4.1v9.2h-3.5zM114 38.7c0 .8.2 1.1.8 1.1a4.8 4.8 0 0 0 .9 0V42a8.6 8.6 0 0 1-2.2.4c-1.4 0-2.4-.5-2.6-1.9a8 8 0 0 1-5.4 2c-2.8 0-5.3-1.6-5.3-4.6 0-3.9 3-4.5 6-4.8 2.4-.5 4.6-.2 4.6-2.2 0-1.8-1.8-2.1-3.2-2.1-2 0-3.3.8-3.4 2.4h-3.4c.2-4 3.6-5.1 7-5.1 3 0 6.2 1.2 6.2 4.5zm-3.4-4.4c-1 .7-2.7.7-4.2 1s-2.8.7-2.8 2.5c0 1.6 2 2 3.1 2 1.5 0 3.9-.7 3.9-2.9zM117.3 26.6h3.2v3a5 5 0 0 1 4.6-3.4 8 8 0 0 1 1.3 0v3.4a12.3 12.3 0 0 0-1.5-.2c-2.4 0-4.2 2-4.2 5.3v7.4h-3.4zM127.4 26.6h2.5v-4.7h3.5v4.7h3v2.5h-3v8.3c0 1.4 0 2.2 1.6 2.2a5.9 5.9 0 0 0 1.5-.2v2.7l-2.3.2c-3.5 0-4.2-1.4-4.2-4v-9.1h-2.6z",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      fill: "#00b0e2",
                                      d: "M20 18.7h2.2v-2.2h2v2.2h2.2v2H24v2.2h-1.9v-2.3H20z",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      fill: "#c30",
                                      d: "M28.2 7.7h2.5V5.3h2v2.4H35v2h-2.4v2.4h-2V9.7h-2.5z",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      fill: "#7da82b",
                                      d: "M11.8 2.8h2.8V0H17v2.8h2.8v2.4H17V8h-2.4V5.2h-2.8z",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      fill: "#00bbf1",
                                      d: "M138 22.2v-.4h3v.4h-1.3v3.3h-.4v-3.3zM142 21.8l1.3 3.1 1.1-3.1h.7v3.7h-.4v-3.1l-1.2 3.1h-.4l-1.2-3.1v3.1h-.5v-3.7z",
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          )
                        : type == "ZG"
                        ? _c(
                            "svg",
                            {
                              staticClass:
                                "subscription__svg subscription__svg__zg",
                              attrs: {
                                viewBox: "0 0 200 43",
                                type: "header-logo-full",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  fill: "none",
                                  d: "M-1-1h202v45H-1z",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  d: "M0 29l14.6-16H1V6.6h23.3v5.7l-15 16.4h15.6v6.4H0v-6zm27.4-14.6h6.8V35h-6.8V14.4zm9.8 0h6.6v2.8h.1c1.2-2.1 4-3.4 6.5-3.4 7 0 7.5 5 7.5 8v13.3h-6.8v-10c0-2.8.3-5.7-3.4-5.7-2.6 0-3.7 2.1-3.7 4.4v11.3h-6.8V14.4zM70.6 19c-3 0-4 2.6-4 5.2 0 2.4 1.2 4.9 4 4.9 2.9 0 4.1-2.3 4.1-4.9 0-2.6-1-5.2-4-5.2m10.5 13.6c0 3.4-.7 9.8-10.4 9.8-4.8 0-9.4-1-10.2-6.6h6.8c.2.9.5 1.5 1.2 1.8.6.3 1.4.4 2.4.4 3.3 0 3.6-2.3 3.6-4.8v-2c-1.3 2-3.4 3-5.6 3-6.3 0-9.1-4.4-9.1-10.3 0-5.5 3.2-10.1 9-10.1a6 6 0 0 1 5.7 3.2v-2.6h6.6v18.2z",
                                  fill: "#00394a",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  d: "M110.8 35.1h-3.2l-.7-3.3a11.9 11.9 0 0 1-4 3.1c-1.4.6-2.9 1-4.6 1-2.2 0-4-.5-5.8-1.3-1.7-.8-3.1-1.8-4.3-3.2-1.1-1.3-2-3-2.7-4.7a17.6 17.6 0 0 1 2.7-16.4 12.8 12.8 0 0 1 10-4.4c1.6 0 3 .2 4.5.6 1.4.4 2.6 1 3.7 1.9a10.2 10.2 0 0 1 4.1 7.3h-4.9a6.3 6.3 0 0 0-4.7-5.4 9.3 9.3 0 0 0-6.6.6c-1 .6-2 1.4-2.7 2.5-.7 1-1.2 2.1-1.5 3.5a16.1 16.1 0 0 0 0 8 11 11 0 0 0 1.5 3.5 7.7 7.7 0 0 0 6.7 3.3c1.3 0 2.4-.1 3.4-.5 1-.3 1.8-.8 2.5-1.5s1.2-1.5 1.5-2.5c.4-1 .5-2.1.6-3.3h-7.6v-3.8h12.1v15zm2.5-20.6h4.3v4c.2-.6.4-1.2.8-1.7a7.6 7.6 0 0 1 3.2-2.5c.7-.3 1.4-.4 2-.4a10.4 10.4 0 0 1 1.8.1v4.4a23.7 23.7 0 0 0-2-.2c-.7 0-1.5.2-2.1.5a5 5 0 0 0-1.8 1.3c-.5.6-1 1.4-1.2 2.3-.3.8-.5 1.8-.5 3v9.8h-4.5V14.5zm14.2 0h4.6V35h-4.6V14.5zm0-8h4.6V11h-4.6V6.6zM138.6 25c0 .9.1 1.8.4 2.6.2.9.5 1.6 1 2.3a5.2 5.2 0 0 0 4.3 2.2 5.1 5.1 0 0 0 4.5-2.3 10.5 10.5 0 0 0 1.4-5c0-2.3-.6-4.1-1.6-5.4a5 5 0 0 0-4.2-2A5.2 5.2 0 0 0 140 20a7 7 0 0 0-1 2.4l-.3 2.7m16 10.1h-4.4v-2.8c-.7 1.2-1.6 2.1-2.7 2.6-1.2.5-2.4.8-3.8.8-1.6 0-3-.3-4.2-.9a8.5 8.5 0 0 1-3-2.3 10 10 0 0 1-1.8-3.5c-.4-1.4-.6-2.8-.6-4.3 0-2 .2-3.5.7-4.9a9.7 9.7 0 0 1 2-3.3c.9-1 1.8-1.6 3-2a9.1 9.1 0 0 1 5.2-.4 8.6 8.6 0 0 1 3.6 1.6c.5.4 1 .9 1.3 1.4V6.6h4.6V35z",
                                  fill: "#1fbaed",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  d: "M27.4 6.6h6.8v5.6h-6.8V6.6zm144.9.8h-6.9V.5h6.9v6.9zm13.7 0h-9.6V.5h9.6v6.9zm13.6 0h-9.5V.5h9.5v6.9zM172.3 21h-6.9v-9.5h6.9V21z",
                                  fill: "#00394a",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  d: "M186 21h-9.6v-9.5h9.6V21zm13.6 0h-9.5v-9.5h9.5V21z",
                                  fill: "#1fbaed",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  d: "M172.3 34.7h-6.9v-9.5h6.9v9.5z",
                                  fill: "#00394a",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  "data-v-da8bdbb4": "",
                                  d: "M186 34.7h-9.6v-9.5h9.6v9.5zm13.6 0h-9.5v-9.5h9.5v9.5z",
                                  fill: "#1fbaed",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" \n            Subscriptions"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "toggle", attrs: { for: "toggle" } },
                      [
                        _vm._v("Show Expired Licenses "),
                        _c("span", [
                          _c("input", {
                            attrs: { type: "checkbox", id: "toggle" },
                            domProps: { checked: _vm.showExpiredState },
                            on: {
                              click: function ($event) {
                                return _vm.toggleShowExpired()
                              },
                            },
                          }),
                          _c("span", { staticClass: "slider round" }),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { key: `${type}_list`, staticClass: "subscription__list" },
                  [
                    _vm._l(_vm.subscriptions, function (subscription, index) {
                      return [
                        subscription.plan.type == type &&
                        _vm.showExpired(subscription)
                          ? _c(
                              "div",
                              { key: index, staticClass: "subscription__card" },
                              [
                                _c(
                                  "header",
                                  {
                                    staticClass:
                                      "subscription__section subscription__header",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "subscription__name" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(subscription.plan.product) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "subscription__top" },
                                      [
                                        _c(
                                          "el-dropdown",
                                          {
                                            on: {
                                              command: _vm.delegateActions,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticStyle: {
                                                    color: "#FFF",
                                                  },
                                                  attrs: {
                                                    icon: ["fas", "cog"],
                                                    size: "1x",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      command: "info",
                                                      subid: subscription.id,
                                                    },
                                                  },
                                                  [_vm._v("Subscription Info")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      command: "invoice",
                                                      subid: subscription.id,
                                                    },
                                                  },
                                                  [_vm._v("Get Invoice")]
                                                ),
                                                _vm._v(" "),
                                                subscription.cancel_at_period_end
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: {
                                                          command: "reactivate",
                                                          subid:
                                                            subscription.id,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Reactivate Subscription"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: {
                                                          command: "cancel",
                                                          subid:
                                                            subscription.id,
                                                        },
                                                      },
                                                      [_vm._v("Unsubscribe")]
                                                    ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  { staticClass: "subscription__section" },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "subscription__domain-heading",
                                      },
                                      [
                                        _vm._v("\n                  Status: "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "400",
                                            },
                                            style: {
                                              color: _vm.subscriptionStatus(
                                                subscription,
                                                true
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.subscriptionStatus(
                                                  subscription
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.subscriptionStatus(subscription) ==
                                        "Pending"
                                          ? [
                                              _c(
                                                "el-tooltip",
                                                { attrs: { placement: "top" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: {
                                                        slot: "content",
                                                      },
                                                      slot: "content",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Payment is processing."
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "No actions required."
                                                      ),
                                                      _c("br"),
                                                      _vm._v(
                                                        "License can be used at this time."
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("el-button", {
                                                    attrs: {
                                                      type: "text",
                                                      icon: "el-icon-question",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    subscription.plan.interval == "year"
                                      ? _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "subscription__domain-heading",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  subscription.cancel_at_period_end
                                                    ? "Expires:"
                                                    : "Renews:"
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "400",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    new Date(
                                                      subscription.current_period_end *
                                                        1000
                                                    ).toLocaleDateString()
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "subscription__domain-heading",
                                      },
                                      [
                                        _vm._v("Price: "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "400",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "$" +
                                                _vm._s(
                                                  _vm.computeAmount(
                                                    subscription
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  subscription.plan.interval ==
                                                    "year"
                                                    ? "/ year"
                                                    : ""
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    subscription.plan.interval != "year"
                                      ? _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "subscription__domain-heading",
                                          },
                                          [_vm._v(" ")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showCompany(subscription.plan)
                                  ? _c(
                                      "section",
                                      { staticClass: "subscription__section" },
                                      [
                                        _c("hr"),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content":
                                                "space-between",
                                            },
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "subscription__domain-heading",
                                              },
                                              [_vm._v("Company")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          { staticClass: "domain__list" },
                                          [
                                            _c(
                                              "li",
                                              { staticClass: "domain__item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "domain__url",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        subscription &&
                                                          subscription.domains &&
                                                          subscription
                                                            .domains[0]
                                                          ? _vm.getLicenseCompany(
                                                              subscription
                                                                .domains[0]
                                                                .license
                                                            )
                                                          : ""
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "section",
                                      { staticClass: "subscription__section" },
                                      [
                                        _c("hr"),
                                        _vm._v(" "),
                                        subscription.plan.product !== "OEM"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "subscription__domain-heading",
                                                    },
                                                    [_vm._v("Domains")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "subscription__domain-count",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            _vm.domainCount(
                                                              subscription
                                                            )
                                                          ) +
                                                          " / " +
                                                          _vm._s(
                                                            subscription.maxDomains
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                { staticClass: "domain__list" },
                                                [
                                                  _vm._l(
                                                    subscription.domains,
                                                    function (
                                                      obj,
                                                      domainIndex
                                                    ) {
                                                      return [
                                                        domainIndex < 2
                                                          ? _c(
                                                              "li",
                                                              {
                                                                key: domainIndex,
                                                                staticClass:
                                                                  "domain__item",
                                                              },
                                                              [
                                                                domainIndex < 1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "domain__url",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            obj.domain
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : subscription.domains.filter(
                                                                      (
                                                                        domain
                                                                      ) =>
                                                                        typeof domain ===
                                                                        "object"
                                                                    ).length
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "domain__url",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "..."
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "subscription__domain-heading",
                                                    },
                                                    [_vm._v("Subdomains")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "subscription__domain-count",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            _vm.domainCount(
                                                              subscription,
                                                              true
                                                            )
                                                          ) +
                                                          " / " +
                                                          _vm._s(
                                                            subscription.maxSubdomains
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "ul",
                                                { staticClass: "domain__list" },
                                                [
                                                  _vm._l(
                                                    subscription.subdomains,
                                                    function (
                                                      obj,
                                                      subdomainIndex
                                                    ) {
                                                      return [
                                                        subdomainIndex < 3
                                                          ? _c(
                                                              "li",
                                                              {
                                                                key: subdomainIndex,
                                                                staticClass:
                                                                  "domain__item",
                                                              },
                                                              [
                                                                subdomainIndex <
                                                                2
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "domain__url",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            obj.domain
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : subscription.subdomains.filter(
                                                                      (
                                                                        subdomain
                                                                      ) =>
                                                                        typeof subdomain ===
                                                                        "object"
                                                                    ).length
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "domain__url",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "..."
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          : subscription &&
                                            subscription.plan &&
                                            subscription.plan.description
                                          ? [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "subscription__oem",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      subscription.plan
                                                        .description
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                _vm._v(" "),
                                !(
                                  subscription.plan.type === "ZC" &&
                                  subscription.plan.product === "OEM"
                                )
                                  ? _c(
                                      "section",
                                      {
                                        staticClass:
                                          "subscription__section subscription__footer",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "subscription__footer__btn-container",
                                          },
                                          [
                                            !(
                                              _vm.isEnterprise(
                                                subscription.plan
                                              ) ||
                                              _vm.isZingGridOem(
                                                subscription.plan
                                              )
                                            )
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "secondary",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openUpdateDomainDialog(
                                                          subscription
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Edit / View Domains"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.displayLicenseKey(
                                                      subscription
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("View License")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "subscription__card",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.openPlanDialog(type)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "domain__item--add" },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: ["fas", "plus-circle"],
                                size: "4x",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "demo__dummy-text" }, [
                              _vm._v("Start a new subscription"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]
            }),
            _vm._v(" "),
            _c("header", { staticClass: "content__header" }, [
              _c("h2", [_vm._v("Transaction History")]),
            ]),
            _vm._v(" "),
            _vm.flatTransactions.length > 0
              ? [
                  _c(
                    "zing-grid",
                    {
                      attrs: {
                        loading: "",
                        data: JSON.stringify(_vm.flatTransactions),
                        caption: "All Subscriptions",
                        search: "",
                        sort: "",
                        pager: "",
                        "page-size-options": "3,6,9",
                        "page-size": "3",
                      },
                    },
                    [
                      _c(
                        "zg-colgroup",
                        [
                          _c("zg-column", {
                            attrs: {
                              index: "date",
                              header: "Date",
                              "sort-desc": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("zg-column", {
                            attrs: { index: "name", header: "Name" },
                          }),
                          _vm._v(" "),
                          _c("zg-column", {
                            attrs: { index: "amount", header: "Amount" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : [_c("p", [_vm._v(" No transactions have been made ")])],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "plan-dialog",
          attrs: {
            title: "Subscribe to a Plan",
            visible: _vm.planDialogVisible,
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.planDialogVisible = $event
            },
            close: _vm.handlePlanDialogClose,
          },
        },
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.planSteps,
                "align-center": "",
                "finish-status": "success",
              },
            },
            [
              _c("el-step", { attrs: { title: "Plan" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Subscription Info" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Subscribe" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "Finish" } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activePlan !== null
            ? [
                _c("section", { staticClass: "plan-dialog__content" }, [
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.planSteps == 0,
                          expression: "planSteps == 0",
                        },
                      ],
                      staticClass: "plan__list",
                    },
                    [
                      _c(
                        "header",
                        [
                          _c("el-switch", {
                            staticStyle: {
                              display: "block",
                              "margin-bottom": "1rem",
                            },
                            attrs: {
                              "active-color": "#2196f3",
                              "inactive-color": "var(--background-dark)",
                              "active-text": "Perpetual",
                              "inactive-text": "Yearly",
                            },
                            model: {
                              value: _vm.pricingInterval,
                              callback: function ($$v) {
                                _vm.pricingInterval = $$v
                              },
                              expression: "pricingInterval",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("section", { staticClass: "plans" }, [
                        _c(
                          "header",
                          { staticClass: "plan-tabs" },
                          [
                            _vm._l(_vm.plans, function (plan, index) {
                              return [
                                plan.Name.slice(0, 2) === _vm.productType &&
                                plan.Sku.includes("-S-")
                                  ? _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "plan-tab",
                                        class: _vm.isPlanActive(index),
                                        on: {
                                          click: function ($event) {
                                            _vm.activePlanIndex = index
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "plan-tab__title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                plan && plan.name
                                                  ? plan.name
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.activePlan !== null
                          ? _c("section", { staticClass: "plan-content" }, [
                              _c(
                                "header",
                                { staticClass: "plan-content__header" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "plan-content__title" },
                                    [_vm._v(_vm._s(_vm.activePlan.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "plan-content__price" },
                                    [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.activePlan.plan.UnitPrice
                                          ) +
                                          " " +
                                          _vm._s(_vm.intervalDisplay)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("section", { staticClass: "plan-features" }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.getPlanFeatures(_vm.activePlan),
                                    function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item,
                                          staticClass: "plan-feature",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["fas", "check-circle"],
                                            },
                                          }),
                                          _vm._v(" " + _vm._s(item)),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "footer",
                        { staticClass: "plan-footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: { click: _vm.nextStep1 },
                            },
                            [_vm._v("Next")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.planSteps === 1,
                          expression: "planSteps === 1",
                        },
                      ],
                      staticClass: "plan__list",
                    },
                    [
                      [
                        _c(
                          "section",
                          { staticClass: "plan-company" },
                          [
                            _c("h4", [
                              _vm._v(
                                "Associate your company to the license subscription."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("h4", [_vm._v("Company:")]),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { autofocus: "" },
                              model: {
                                value: _vm.company,
                                callback: function ($$v) {
                                  _vm.company = $$v
                                },
                                expression: "company",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.zingchartEnterpriseLicense
                          ? _c(
                              "footer",
                              { staticClass: "plan-dialog__footer" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.planSteps--
                                      },
                                    },
                                  },
                                  [_vm._v("Back")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      disabled: _vm.disableCompany,
                                    },
                                    on: { click: _vm.nextStep2 },
                                  },
                                  [_vm._v("Next")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      _vm._v(" "),
                      !_vm.zingchartEnterpriseLicense
                        ? _c("domain-form", {
                            ref: "domainFormCreate",
                            attrs: {
                              "active-plan": _vm.activePlan,
                              "cancel-text": "Back",
                              disable: _vm.disableCompany,
                              domains: _vm.dialogNewDomains,
                              form: _vm.dialogDomainForm,
                              optional: true,
                              subdomains: _vm.dialogNewSubdomains,
                              "submit-text": "Next",
                            },
                            on: {
                              "cancel-action": function ($event) {
                                _vm.planSteps--
                              },
                              "remove-domain": _vm.removeNewDomain,
                              "submit-action": _vm.nextStep2,
                              "update:activePlan": function ($event) {
                                _vm.activePlan = $event
                              },
                              "update:active-plan": function ($event) {
                                _vm.activePlan = $event
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.planSteps === 2,
                          expression: "planSteps === 2",
                        },
                      ],
                      staticClass: "plan__list",
                    },
                    [
                      _c("h3", [_vm._v("Confirm Subscription")]),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticStyle: { flex: "1" } },
                        [
                          _c("p", [
                            _vm._v(
                              "By confirming subscription, you agree to ZingSoft Inc. charging an amount of $" +
                                _vm._s(_vm.activePlan.plan.UnitPrice) +
                                " " +
                                _vm._s(_vm.intervalDisplay) +
                                "."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("h4", [
                            _vm._v("License: " + _vm._s(_vm.activePlan.name)),
                          ]),
                          _vm._v(" "),
                          _c("h4", [_vm._v("Domains:")]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(
                              _vm.dialogNewDomains,
                              function (domain, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(_vm._s(domain)),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.dialogNewDomains &&
                                    _vm.dialogNewDomains.length === 0,
                                  expression:
                                    "dialogNewDomains && dialogNewDomains.length === 0",
                                },
                              ],
                            },
                            [_vm._v("None")]
                          ),
                          _vm._v(" "),
                          _c("h4", [_vm._v("Subdomains:")]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(
                              _vm.dialogNewSubdomains,
                              function (subdomain, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(_vm._s(subdomain)),
                                ])
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.dialogNewSubdomains &&
                                    _vm.dialogNewSubdomains.length === 0,
                                  expression:
                                    "dialogNewSubdomains && dialogNewSubdomains.length === 0",
                                },
                              ],
                            },
                            [_vm._v("None")]
                          ),
                          _vm._v(" "),
                          _c("h4", [_vm._v("Company:")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(this.company))]),
                          _vm._v(" "),
                          _c("h4", [_vm._v("Billing Card:")]),
                          _vm._v(" "),
                          !_vm.hasCard
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "h4",
                                      { staticStyle: { color: "#dc1257" } },
                                      [
                                        _vm._v(
                                          "You currently do not have a billing card attached to your account. Please add a card before subscribing."
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "success" },
                                        on: {
                                          click: function ($event) {
                                            _vm.cardDialogVisible = true
                                          },
                                        },
                                      },
                                      [_vm._v("Add Card")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("credit-card", {
                                  attrs: { mini: true },
                                  on: {
                                    update: _vm.updateCard,
                                    remove: function ($event) {
                                      _vm.hasCard = false
                                    },
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "footer",
                        { staticClass: "plan-dialog__footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", plain: "" },
                              on: {
                                click: function ($event) {
                                  _vm.planSteps--
                                },
                              },
                            },
                            [_vm._v("Back")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                disabled: !_vm.hasCard || _vm.disableCompany,
                                loading: _vm.subscribeLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.nextStep3(
                                    _vm.activePlan.plan.Sku,
                                    _vm.dialogNewDomains,
                                    _vm.dialogNewSubdomains
                                  )
                                },
                              },
                            },
                            [_vm._v("\n              Subscribe\n            ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.planSteps === 3,
                          expression: "planSteps === 3",
                        },
                      ],
                      staticClass: "plan__list",
                    },
                    [
                      _c("h3", [_vm._v("Subscription created")]),
                      _vm._v(" "),
                      _c("section", { staticClass: "plan__list__created" }, [
                        _c("p", [
                          _vm._v(
                            "Your subscription was successfully created! We have sent a receipt to your email."
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.productType === "ZC"
                          ? _c("p", [
                              _vm._v(
                                "Thank you for purchasing a " +
                                  _vm._s(
                                    _vm.activePlan.plan.interval === "year"
                                      ? "annual"
                                      : "perpetual"
                                  ) +
                                  " " +
                                  _vm._s(_vm.activePlan.name) +
                                  " License.\n              Your license file will be included within the email receipt we have sent.\n              If you have any questions, please reach out to "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "mailto:support@zingsoft.com",
                                  },
                                },
                                [_vm._v("support@zingsoft.com")]
                              ),
                              _vm._v("."),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "footer",
                        { staticClass: "plan-dialog__footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success" },
                              on: {
                                click: function ($event) {
                                  _vm.planDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Update Domain",
            visible: _vm.subscriptionUpdateDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.subscriptionUpdateDialogVisible = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("Domain")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.domain,
              callback: function ($$v) {
                _vm.domain = $$v
              },
              expression: "domain",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.subscriptionUpdateDialogVisible = false
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.updateSubscription()
                },
              },
            },
            [_vm._v("Update Domain")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "Subscription Info",
            visible: _vm.subscriptionInfoDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.subscriptionInfoDialogVisible = $event
            },
          },
        },
        [
          _c(
            "h2",
            { staticClass: "dialog__icon" },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "info-circle"], size: "3x" },
              }),
              _c("br"),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.subscriptionInfoTitle))]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "subscription-info" },
            _vm._l(_vm.subscriptionInfoItems, function (info) {
              return _c(
                "li",
                { key: info, staticClass: "subscription-info__item" },
                [_vm._v(_vm._s(info))]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog__controls" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.subscriptionInfoDialogVisible = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "Invoice",
            visible: _vm.subscriptionInvoiceDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.subscriptionInvoiceDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "subscription-info" },
            [
              _c(
                "el-form",
                {
                  ref: "invoiceForm",
                  attrs: { model: _vm.formInvoice },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("p", [_vm._v("Send invoice to:")]),
                      _vm._v(" "),
                      _c("input-email", {
                        attrs: {
                          form: "formInvoice",
                          value: _vm.formInvoice.email,
                        },
                        on: {
                          updated: (value) => (_vm.formInvoice.email = value),
                          change: _vm.checkInvoiceFormFilled,
                          validate: function ($event) {
                            return _vm.validateInvoiceForm()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.sendInvoice.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.formInvoice.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInvoice, "email", $$v)
                          },
                          expression: "formInvoice.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dialog__controls" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.subscriptionInvoiceDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                id: "sendInvoiceButtonSend",
                                disabled: _vm.disableInvoiceButton,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.sendInvoice()
                                },
                              },
                            },
                            [_vm._v("\n              Send\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "hide",
                              attrs: {
                                type: "success",
                                id: "sendInvoiceButtonSent",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.subscriptionInvoiceDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("\n              Completed!\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: `${_vm.toggleSubscriptionText} Subscription`,
            visible: _vm.toggleSubscriptionDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.toggleSubscriptionDialogVisible = $event
            },
          },
        },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.toggleSubscriptionText) + " Subscription"),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "subscription-info" }, [
            _c("p", [
              _vm._v(
                "To " +
                  _vm._s(_vm.toggleSubscriptionText.toLowerCase()) +
                  " your subscription, please contact us at "
              ),
              _c("a", { attrs: { href: "mailto:sales@zingsoft.com" } }, [
                _vm._v("sales@zingsoft.com"),
              ]),
              _vm._v("."),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog__controls" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.toggleSubscriptionDialogVisible = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "Unsubscribe",
            visible: _vm.unsubscribeDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.unsubscribeDialogVisible = $event
            },
          },
        },
        [
          _c(
            "h2",
            { staticClass: "dialog__icon", staticStyle: { color: "#f56c6c" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"], size: "3x" },
              }),
              _c("br"),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [
            _vm._v(
              "Are you sure you want to unsubscribe from your subscription?"
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Your subscription will remain active until the current session ends."
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog__controls" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.unsubscribeDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelSubscription()
                    },
                  },
                },
                [_vm._v("Unsubscribe")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            subscription: "cardDialog",
            title: "Add Card",
            visible: _vm.cardDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cardDialogVisible = $event
            },
          },
        },
        [
          _c("subscription-form", {
            attrs: {
              "form-pricing": _vm.formCard,
              submitAction: _vm.addCard,
              submitLoading: _vm.addLoading,
              "submit-text": "Add Card",
            },
            on: {
              "close-dialog": function ($event) {
                _vm.cardDialogVisible = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "plan-dialog",
          attrs: {
            visible: _vm.updateDomainDialogVisible,
            title: "Update domain",
          },
          on: {
            close: _vm.handlePlanDialogClose,
            "update:visible": function ($event) {
              _vm.updateDomainDialogVisible = $event
            },
          },
        },
        [
          _c(
            "section",
            { staticClass: "plan-dialog__content" },
            [
              _c("domain-form", {
                ref: "domainFormUpdate",
                attrs: {
                  "cancel-text": "Cancel",
                  domains: _vm.dialogNewDomains,
                  form: _vm.dialogDomainForm,
                  subdomains: _vm.dialogNewSubdomains,
                  "submit-text": "Update",
                  subscription: _vm.updateDomainSubscription,
                  updating: true,
                  visible: _vm.updateDomainDialogVisible,
                },
                on: {
                  "cancel-action": function ($event) {
                    _vm.updateDomainDialogVisible = false
                    _vm.handlePlanDialogClose()
                  },
                  "remove-domain": _vm.removeNewDomain,
                  "submit-action": function ($event) {
                    _vm.updateDomainDialogVisible = false
                    _vm.getUserSubscriptions()
                    _vm.handlePlanDialogClose()
                  },
                  "update:subscription": function ($event) {
                    _vm.updateDomainSubscription = $event
                  },
                  "update:visible": function ($event) {
                    _vm.updateDomainDialogVisible = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "plan-dialog small",
          attrs: { title: "License Key", visible: _vm.licenseKeyVisible },
          on: {
            close: function ($event) {
              _vm.licenseKeyVisible = false
              _vm.licenseKeyInfo = null
            },
            "update:visible": function ($event) {
              _vm.licenseKeyVisible = $event
            },
          },
        },
        [
          _c("license-key", {
            attrs: { subscription: _vm.licenseKeyInfo },
            on: {
              "update:subscription": function ($event) {
                _vm.licenseKeyInfo = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }