var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", { attrs: { "grid-body": true } }, [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Latest Active Users"),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "header__right" }, slot: "header__right" }, [
      _c(
        "span",
        { staticClass: "input-wrapper" },
        [
          _c("el-input", {
            attrs: { placeholder: "Search", search: "" },
            on: { change: _vm.searchGrid },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "el-input-icon",
            attrs: { icon: "search2", fill: "var(--color-primary-gray)" },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c(
          "zing-grid",
          {
            attrs: {
              id: "latestUsers",
              "cell-class": "renderNA",
              "default-display": "N/A",
              height: "650",
              layout: "row",
              "layout-controls": "disabled",
              "no-border": "",
              pager: "",
              "page-size": "10",
              search: "",
              selector: "",
              sort: "",
            },
          },
          [
            _c(
              "zg-data",
              [
                _c("zg-param", {
                  attrs: { name: "headers", value: _vm.headerVal },
                }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "src", value: _vm.srcValue } }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "countPath", value: "total" },
                }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "idKey", value: "id" } }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "loadByPage", value: "true" },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "limitToKey", value: "limit" },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "limitToValue", value: "10" },
                }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "pageKey", value: "page" } }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "recordPath", value: "result" },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "searchKey", value: "search" },
                }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "sortBy", value: "email" } }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "sortByKey", value: "sort" } }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "sortDirKey", value: "dir" } }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: {
                    name: "queryString",
                    value: "?exclude=zingsoft+pint",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "zg-colgroup",
              [
                _c("zg-column", {
                  attrs: {
                    index: "email, name, id",
                    header: "User",
                    renderer: "renderUser",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "last_login",
                    header: "Last Seen",
                    type: "date",
                    renderer: "renderDate",
                    "_type-date-from-now": "",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "last_ip", header: "Last IP" },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "logins_count", header: "# of Logins" },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "demo_count", header: "# of Demos" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }