var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "wrapper" },
    [
      _c("section", { staticClass: "view__wrap" }, [
        _c(
          "section",
          { staticClass: "content__wrap" },
          [_c("header-bar"), _vm._v(" "), _vm._t("default")],
          2
        ),
      ]),
      _vm._v(" "),
      _c("app-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }