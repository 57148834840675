var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "content--wide", attrs: { "group-admin": "" } },
    [
      _c(
        "el-button",
        {
          staticClass: "header__btn",
          attrs: { size: "small", type: "primary" },
          on: {
            click: function ($event) {
              _vm.editVisible = true
            },
          },
        },
        [_vm._v("Edit Group Info")]
      ),
      _vm._v(" "),
      _c("h2", [_vm._v("Groups you admin for.")]),
      _vm._v(" "),
      _c("p", [_vm._v("Group Name: " + _vm._s(_vm.group_))]),
      _vm._v(" "),
      _c("p", [_vm._v("Address: ")]),
      _vm._v(" "),
      _c(
        "zing-grid",
        {
          attrs: {
            "group-admin": "members",
            layout: "row",
            "page-size": "10",
            pager: "",
            search: "",
          },
        },
        [
          _c(
            "zg-caption",
            [
              _vm._v("\n      Member Permissions\n      "),
              _c(
                "el-button",
                {
                  attrs: {
                    "group-admin": "inviteBtn",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      _vm.inviteVisible = true
                    },
                  },
                },
                [_vm._v("Add Member")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "zg-colgroup",
            [
              _c("zg-column", {
                attrs: { index: "name", renderer: "renderName" },
              }),
              _vm._v(" "),
              _vm._l(_vm.permissions, function (permission) {
                return _c("zg-column", {
                  key: permission,
                  attrs: {
                    index: `features.${permission}.enabled`,
                    "header-auto-format": "formatHeaders",
                    renderer: "renderToggle",
                    type: "toggle",
                    "type-toggle-options": '["false", "true"]',
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "group-admin": "editDialog",
            title: "Edit Group Information",
            visible: _vm.editVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: { "label-width": "150px", model: _vm.editForm },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Group Name",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "Please input group name",
                        trigger: ["blur", "change"],
                      },
                      {
                        min: 3,
                        max: 50,
                        message: "Length should be 3 to 50",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.group_,
                      callback: function ($$v) {
                        _vm.group_ = $$v
                      },
                      expression: "group_",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Group Address", prop: "address" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "address", $$v)
                      },
                      expression: "editForm.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "button-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.editVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "group-admin": "inviteDialog",
            title: "Send Member Invites",
            visible: _vm.inviteVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.inviteVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "inviteForm",
              attrs: { "label-width": "150px", model: _vm.inviteForm },
            },
            [
              _vm._l(_vm.inviteForm.members, function (member, index) {
                return _c(
                  "el-form-item",
                  {
                    key: member.key,
                    attrs: {
                      "group-admin": "inputRow",
                      label: "Member " + parseInt(index + 1),
                      prop: "members." + index + ".email",
                      rules: [
                        {
                          required: true,
                          message: "Please input email address",
                          trigger: "blur",
                        },
                        {
                          type: "email",
                          message: "Please input correct email address",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: member.email,
                        callback: function ($$v) {
                          _vm.$set(member, "email", $$v)
                        },
                        expression: "member.email",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeMember(member)
                          },
                        },
                      },
                      [_vm._v("Remove")]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { "button-align": "right" } },
                [
                  _c("el-button", { on: { click: _vm.addMember } }, [
                    _vm._v("Add Member"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateAndSubmit("inviteForm")
                        },
                      },
                    },
                    [_vm._v("Invite")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }