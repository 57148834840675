var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter_wrapper" },
    [
      _c(
        "el-select",
        {
          attrs: { show: _vm.authenticated, value: _vm.selected },
          on: { change: _vm.updateSelected },
        },
        [
          _vm._l(_vm.defaultOptions, function (option) {
            return _c(
              "el-option",
              {
                key: option.id,
                attrs: { label: option.name, value: option.id },
              },
              [
                _c("div", { staticClass: "filter__option" }, [
                  _c("div", { staticClass: "filter__name" }, [
                    _vm._v(_vm._s(option.name)),
                  ]),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.options.length > 0
            ? _c(
                "el-option-group",
                { attrs: { label: "Groups" } },
                _vm._l(_vm.options, function (group) {
                  return _c(
                    "el-option",
                    {
                      key: group.id,
                      attrs: { label: group.name, value: group.id },
                    },
                    [
                      _c("div", { staticClass: "filter__option" }, [
                        _c("div", { staticClass: "filter__name" }, [
                          _vm._v(_vm._s(group.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "filter__count" }, [
                          _vm._v(_vm._s(_vm.getCount(group))),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("svg-icon", {
        attrs: { icon: "close--circle", height: "1rem", width: "1rem" },
      }),
      _vm._v(" "),
      _c("svg-icon", {
        staticClass: "el-input-icon",
        attrs: { icon: "angle" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }