var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "zingnetCard--column" }, [
    _c("h3", { staticClass: "zingnetCard--header" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "progressBar" }, [
      _vm.progress
        ? _c("div", {
            staticClass: "progressBar--completed",
            style: `width: ${_vm.progress}%`,
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.progress && _vm.progress < 100
        ? _c("div", { staticClass: "progressBar--divider" })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.progress
      ? _c("div", { staticClass: "progress" }, [
          _c("span", [
            _c("span", { staticClass: "progressText" }, [
              _vm._v(_vm._s(_vm.progress) + "%"),
            ]),
            _c("span", [_vm._v("completed")]),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("span", { staticClass: "progressText progressText__left" }, [
              _vm._v(_vm._s(_vm.left) + "%"),
            ]),
            _c("span", [_vm._v("left")]),
          ]),
        ])
      : _vm.fail
      ? _c("p", { staticClass: "progressText progressText__fail" }, [
          _vm._v("Deployment failed"),
        ])
      : _vm.time == 0
      ? _c("p", [_vm._v("Deployment is in queue")])
      : _c("p", [
          _vm._v(
            "There are no deployments in progress right now for the " +
              _vm._s(_vm.title.split(" ").shift()) +
              " gallery"
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }