import { render, staticRenderFns } from "./DemoViewer.vue?vue&type=template&id=7ba7d126"
import script from "./DemoViewer.vue?vue&type=script&lang=js"
export * from "./DemoViewer.vue?vue&type=script&lang=js"
import style0 from "./DemoViewer.vue?vue&type=style&index=0&id=7ba7d126&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ba7d126')) {
      api.createRecord('7ba7d126', component.options)
    } else {
      api.reload('7ba7d126', component.options)
    }
    module.hot.accept("./DemoViewer.vue?vue&type=template&id=7ba7d126", function () {
      api.rerender('7ba7d126', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/src/components/DemoViewer.vue"
export default component.exports