var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zingnet-card",
    [
      _c(
        "span",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v("\n    Site Error Tracker\n    "),
          _c("date-range", { on: { updateDateRange: _vm.updateDateRange } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { slot: "header__right" },
          on: { click: _vm.openTrackJS },
          slot: "header__right",
        },
        [_vm._v("View in TrackJS")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { slot: "body" },
          slot: "body",
        },
        [
          _c("section", { staticClass: "zingnetCard--row" }, [
            _c("section", { staticClass: "zingnetCard--column" }, [
              _c("h3", { staticClass: "zingnetCard--header" }, [
                _vm._v("Highest Impact"),
              ]),
              _vm._v(" "),
              _vm.errors
                ? _c(
                    "ol",
                    { staticClass: "zingnetCard--list" },
                    _vm._l(_vm.errors.highestImpact, function (li) {
                      return _c(
                        "li",
                        { key: li.message },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `https://${li.url}`,
                                target: "_blank",
                                rel: "noreferrer noopener",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "zingnetCard--list--item" },
                                [_vm._v(_vm._s(li.message))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("bubble-count", { attrs: { count: li.count } }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "zingnetCard--column" }, [
              _c("h3", { staticClass: "zingnetCard--header" }, [
                _vm._v("Top Errors"),
              ]),
              _vm._v(" "),
              _vm.errors
                ? _c(
                    "ol",
                    { staticClass: "zingnetCard--list" },
                    _vm._l(_vm.errors.top, function (li) {
                      return _c(
                        "li",
                        { key: li.message },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `https://${li.url}`,
                                target: "_blank",
                                rel: "noreferrer noopener",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "zingnetCard--list--item" },
                                [_vm._v(_vm._s(li.message))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("bubble-count", { attrs: { count: li.count } }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "zingnetCard--row" }, [
            _c("section", { staticClass: "zingnetCard--column" }, [
              _c("div", { attrs: { id: "chart-errorTracking" } }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }