var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "domain-modal": "",
        "license-modal": "",
        visible: _vm.visible,
        width: "1200px",
      },
      on: { close: _vm.triggerClose },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("p", { staticClass: "title" }, [_vm._v("Create License")]),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          model: {
            value: _vm.licenseForm,
            callback: function ($$v) {
              _vm.licenseForm = $$v
            },
            expression: "licenseForm",
          },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "column__left" }, [
              _c(
                "section",
                [
                  _c("p", { staticClass: "zingnetCard--header required" }, [
                    _vm._v("Type"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.licenseForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseForm, "type", $$v)
                        },
                        expression: "licenseForm.type",
                      },
                    },
                    _vm._l(_vm.licenseTypes, function (type) {
                      return _c("el-option", {
                        key: type,
                        attrs: { label: type, value: type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c("p", { staticClass: "zingnetCard--header required" }, [
                    _vm._v("Plan"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", large: "" },
                      model: {
                        value: _vm.licenseForm.plan,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseForm, "plan", $$v)
                        },
                        expression: "licenseForm.plan",
                      },
                    },
                    _vm._l(_vm.licenseOptions, function (license) {
                      return _c("el-option", {
                        key: license,
                        attrs: { label: license, value: license },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAnnualLicense
                ? _c(
                    "section",
                    [
                      _c("p", { staticClass: "zingnetCard--header required" }, [
                        _vm._v("Expires"),
                      ]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        attrs: { type: "date" },
                        model: {
                          value: _vm.licenseForm.expires,
                          callback: function ($$v) {
                            _vm.$set(_vm.licenseForm, "expires", $$v)
                          },
                          expression: "licenseForm.expires",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c("p", { staticClass: "zingnetCard--header required" }, [
                    _vm._v("User"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      'For "Studio Email/ID" option, provide user\'s email or studio ID to associate domain to.'
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      'Or select "Admin Account" option to just associate domain to admin account.'
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      'Or select "Create Account" and input customer\'s email address. This will associate domain to new studio account.'
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "input-with-select",
                      attrs: { placeholder: "Please input" },
                      model: {
                        value: _vm.licenseForm.user,
                        callback: function ($$v) {
                          _vm.$set(_vm.licenseForm, "user", $$v)
                        },
                        expression: "licenseForm.user",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { slot: "prepend", placeholder: "Select" },
                          on: { change: _vm.updateUserInput },
                          slot: "prepend",
                          model: {
                            value: _vm.userInput,
                            callback: function ($$v) {
                              _vm.userInput = $$v
                            },
                            expression: "userInput",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "user",
                            attrs: {
                              label: "Studio Email / ID",
                              value: "user",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "admin",
                            attrs: { label: "Admin Account", value: "admin" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "create",
                            attrs: { label: "Create Account", value: "create" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.accountCreateButtonDisplay
                    ? _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.accountCreateButtonDisabled },
                          on: { click: _vm.createAccount },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.accountCreateButtonText || "Create Account"
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.accountCreateMessage
                    ? _c("el-tag", { attrs: { type: _vm.tagType } }, [
                        _vm._v(_vm._s(_vm.accountCreateMessage)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAdminAccount
                ? _c(
                    "section",
                    [
                      _c("p", { staticClass: "zingnetCard--header required" }, [
                        _vm._v("Customer ID"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v("Provide user's\n            "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Can be found in Quickbooks customer url: "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("code", [
                                  _vm._v("/customerdetail?nameId=CUSTOMER_ID"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("Quickbooks Customer ID")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(".\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Customer ID" },
                        model: {
                          value: _vm.licenseForm.id_quickbooks,
                          callback: function ($$v) {
                            _vm.$set(_vm.licenseForm, "id_quickbooks", $$v)
                          },
                          expression: "licenseForm.id_quickbooks",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAdminAccount
                ? _c(
                    "section",
                    [
                      _c("p", { staticClass: "zingnetCard--header" }, [
                        _vm._v("Quickbooks ID"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v(
                            "Link Studio account by providing \n            "
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Can be found in Quickbooks customer url: "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("code", [
                                  _vm._v("/customerdetail?nameId=CUSTOMER_ID"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("Quickbooks Customer ID")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(".\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        model: {
                          value: _vm.licenseForm.id_quickbooks,
                          callback: function ($$v) {
                            _vm.$set(_vm.licenseForm, "id_quickbooks", $$v)
                          },
                          expression: "licenseForm.id_quickbooks",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("section", [
                _c("p", { staticClass: "zingnetCard--header required" }, [
                  _vm._v("Invoice ID"),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _vm._v("Provide  \n           "),
                    _c(
                      "el-popover",
                      { attrs: { placement: "top-start", trigger: "hover" } },
                      [
                        _c("p", [
                          _vm._v("Can be found in Quickbooks invoice url: "),
                          _c("br"),
                          _vm._v(" "),
                          _c("code", [_vm._v("/invoice?txnId=INVOICE_ID")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v("Quickbooks invoice ID")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(".\n          "),
                    _c("el-input", {
                      model: {
                        value: _vm.licenseForm.quickbooks_invoice_id,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.licenseForm,
                            "quickbooks_invoice_id",
                            $$v
                          )
                        },
                        expression: "licenseForm.quickbooks_invoice_id",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column__right" }, [
              _vm.isOemLicense
                ? _c(
                    "section",
                    [
                      _c("p", { staticClass: "zingnetCard--header required" }, [
                        _vm._v("Company Name"),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        model: {
                          value: _vm.licenseForm.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.licenseForm, "companyName", $$v)
                          },
                          expression: "licenseForm.companyName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isOemLicense
                ? _c(
                    "section",
                    [
                      _c("p", { staticClass: "zingnetCard--header required" }, [
                        _vm._v("Register Domains"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.licenseForm.domains, function (domain, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: domain.key,
                            staticClass: "flex",
                            attrs: { prop: "domains." + index + ".value" },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-append",
                                model: {
                                  value: domain.domain,
                                  callback: function ($$v) {
                                    _vm.$set(domain, "domain", $$v)
                                  },
                                  expression: "domain.domain",
                                },
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: {
                                    slot: "append",
                                    label: "Subdomain",
                                    size: "medium",
                                  },
                                  slot: "append",
                                  model: {
                                    value: domain.sub,
                                    callback: function ($$v) {
                                      _vm.$set(domain, "sub", $$v)
                                    },
                                    expression: "domain.sub",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-button", {
                              attrs: { type: "danger", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDomain(domain)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-button", { on: { click: _vm.addDomain } }, [
                            _vm._v("Add domain"),
                          ]),
                          _vm._v(" "),
                          _c("el-button", { on: { click: _vm.resetDomain } }, [
                            _vm._v("Reset domain"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c("p", { staticClass: "zingnetCard--header" }, [
                    _vm._v("Notes"),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4, cols: 50 },
                    model: {
                      value: _vm.licenseForm.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.licenseForm, "notes", $$v)
                      },
                      expression: "licenseForm.notes",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.disable || !_vm.fieldsCompleted },
              on: { click: _vm.createLicense },
            },
            [_vm._v("Create License")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }