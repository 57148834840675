var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "settings__content" },
    [
      _c("h2", [_vm._v("Delete Account")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n  Please enter your username to confirm account deletion:\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form__item" },
        [
          _c(
            "label",
            { staticClass: "form__label", attrs: { id: "username" } },
            [_vm._v("Username")]
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "form__field",
            attrs: { placeholder: "Username", for: "username" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-button", { attrs: { type: "danger" } }, [
        _vm._v("Delete Account"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }