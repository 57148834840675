var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "scroll"
    ? _c(
        "label",
        {
          attrs: {
            id: "toggleCheck",
            "data-field": "checkbox",
            "zing-theme-builder": "toggle-check",
          },
        },
        [
          _c("el-checkbox", {
            attrs: { "zing-theme-builder": "check" },
            on: { change: _vm._inputChecked },
            model: {
              value: _vm.checkVal,
              callback: function ($$v) {
                _vm.checkVal = $$v
              },
              expression: "checkVal",
            },
          }),
          _vm._v(" "),
          _c("span", { attrs: { "zing-theme-builder": "toggle-label" } }, [
            _vm._v(_vm._s(_vm.checkVal ? "Scroll Enabled" : "Scroll Disabled")),
          ]),
        ],
        1
      )
    : _vm.type === "guide"
    ? _c(
        "label",
        {
          attrs: {
            id: "guideCheck",
            "data-field": "checkbox",
            "zing-theme-builder": "toggle-check",
          },
        },
        [
          _c("el-checkbox", {
            attrs: { "zing-theme-builder": "check" },
            on: { change: _vm._inputChecked },
            model: {
              value: _vm.checkVal,
              callback: function ($$v) {
                _vm.checkVal = $$v
              },
              expression: "checkVal",
            },
          }),
          _vm._v(" "),
          _c("span", { attrs: { "zing-theme-builder": "toggle-label" } }, [
            _vm._v(_vm._s(_vm.checkVal ? "Guide Enabled" : "Guide Disabled")),
          ]),
        ],
        1
      )
    : _vm.type === "checkall"
    ? _c(
        "label",
        {
          attrs: {
            id: "featureCheck",
            "data-field": "checkbox",
            "zing-theme-builder": "toggle-check",
          },
        },
        [
          _c("el-checkbox", {
            attrs: { "zing-theme-builder": "check" },
            on: { change: _vm._inputChecked },
            model: {
              value: _vm.checkVal,
              callback: function ($$v) {
                _vm.checkVal = $$v
              },
              expression: "checkVal",
            },
          }),
          _vm._v(" "),
          _c("span", { attrs: { "zing-theme-builder": "toggle-label" } }, [
            _vm._v(_vm._s(_vm.checkVal ? "Check All" : "Uncheck All")),
          ]),
        ],
        1
      )
    : _c("el-checkbox", {
        attrs: { "zing-theme-builder": "check" },
        on: { change: _vm._inputChecked },
        model: {
          value: _vm.checkVal,
          callback: function ($$v) {
            _vm.checkVal = $$v
          },
          expression: "checkVal",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }