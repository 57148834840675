var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _vm.adminAccess
      ? _c(
          "section",
          { staticClass: "content--narrow" },
          [
            _c(
              "header",
              { staticClass: "content__header content__header--expanded" },
              [
                _vm.profile.avatar != "#"
                  ? _c("img", {
                      staticClass: "profile__avatar",
                      attrs: { src: _vm.profile.avatar, alt: "Avatar" },
                    })
                  : _c("font-awesome-icon", {
                      staticClass: "profile__avatar",
                      attrs: { icon: ["fas", "user-circle"], size: "10x" },
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "profile__info" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.profile.email))]),
                  _vm._v(" "),
                  _vm.profile.name
                    ? _c("h3", [_vm._v(_vm._s(_vm.profile.name))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.profile.company
                    ? _c(
                        "p",
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "building"], size: "1x" },
                          }),
                          _vm._v(_vm._s(_vm.profile.company)),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.profile.location
                    ? _c(
                        "p",
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: ["fas", "map-marker-alt"],
                              size: "1x",
                            },
                          }),
                          _vm._v(_vm._s(_vm.profile.location)),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("demo-viewer", {
              attrs: {
                mode: "grid",
                create: "false",
                "user-id": _vm.id,
                search: true,
                pagination: true,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }