var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("pre", [_vm._v(_vm._s(_vm.licenseKey))]),
    _vm._v(" "),
    _c("textarea"),
    _vm._v(" "),
    _c(
      "footer",
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.copyCode },
          },
          [_vm._v(_vm._s(_vm.buttonText))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }