var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "settings__dialog",
      attrs: { title: "Settings", visible: _vm.visibility },
      on: {
        "update:visible": function ($event) {
          _vm.visibility = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { tabPosition: _vm.isMobile ? "top" : "left" },
          on: { "tab-click": _vm.currentTab },
        },
        [
          _vm.showSettings("general")
            ? _c(
                "el-tab-pane",
                { attrs: { label: "General" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "border-right": "1px solid var(--border-light)",
                          },
                          attrs: { span: 18 },
                        },
                        [
                          _c("section", { staticClass: "settings__entry" }, [
                            _c("label", [_vm._v("Title")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "Untitled Demo",
                                    type: "text",
                                    size: "mini",
                                    maxlength: "75",
                                  },
                                  model: {
                                    value: _vm.title,
                                    callback: function ($$v) {
                                      _vm.title = $$v
                                    },
                                    expression: "title",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("section", { staticClass: "settings__entry" }, [
                            _c("label", [_vm._v("Description")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticClass: "demo-description",
                                  attrs: {
                                    placeholder: "Demo Description",
                                    type: "textarea",
                                    autosize: "",
                                    rows: "10",
                                    maxlength: "250",
                                  },
                                  model: {
                                    value: _vm.description,
                                    callback: function ($$v) {
                                      _vm.description = $$v
                                    },
                                    expression: "description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("section", { staticClass: "settings__entry" }, [
                            _c("label", [_vm._v("Tags")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _vm._l(_vm.tags, function (tag) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag.name,
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.removeTag(tag.id, tag.name)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(tag.name) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _vm.inputVisible
                                  ? [
                                      _vm.adminTagAccess
                                        ? _c(
                                            "el-select",
                                            {
                                              staticClass: "input-new-tag",
                                              staticStyle: {
                                                "max-width": "150px",
                                              },
                                              attrs: { filterable: "" },
                                              on: { change: _vm.addTag },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.addTag.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.inputValue,
                                                callback: function ($$v) {
                                                  _vm.inputValue = $$v
                                                },
                                                expression: "inputValue",
                                              },
                                            },
                                            _vm._l(
                                              _vm.adminTagOptions,
                                              function (tag, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: tag,
                                                    value: tag,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _c("el-input", {
                                            ref: "saveTagInput",
                                            staticClass: "input-new-tag",
                                            staticStyle: {
                                              "max-width": "150px",
                                            },
                                            attrs: { size: "mini" },
                                            on: { blur: _vm.addTag },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.addTag.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.inputValue,
                                              callback: function ($$v) {
                                                _vm.inputValue = $$v
                                              },
                                              expression: "inputValue",
                                            },
                                          }),
                                    ]
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "button-new-tag el-tag",
                                        attrs: { size: "small" },
                                        on: { click: _vm.showInput },
                                      },
                                      [_vm._v("+ New Tag")]
                                    ),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "settings__entry" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    disabled: _vm.demo && !_vm.demo.uid,
                                    size: "mini",
                                  },
                                  on: { click: _vm.deleteDemo },
                                },
                                [_vm._v("Delete Demo")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { offset: 2, span: 4 } }, [
                        _vm.adminTemplateCreate
                          ? _c(
                              "section",
                              { staticClass: "settings__entry" },
                              [
                                _c("label", [_vm._v("Public")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("el-switch", {
                                  model: {
                                    value: _vm.isPublic,
                                    callback: function ($$v) {
                                      _vm.isPublic = $$v
                                    },
                                    expression: "isPublic",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "section",
                          { staticClass: "settings__entry" },
                          [
                            _c("label", [_vm._v("Run On Click")]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("el-switch", {
                              model: {
                                value: _vm.runOnClick,
                                callback: function ($$v) {
                                  _vm.runOnClick = $$v
                                },
                                expression: "runOnClick",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-row", [
                    _c(
                      "section",
                      {
                        staticClass: "settings__entry",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                          "align-items": "flex-end",
                          flex: "1",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$emit("toggle-visibility")
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.saveData },
                          },
                          [_vm._v("Save")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings("upload")
            ? _c("el-tab-pane", { attrs: { label: "Files" } }, [
                _c("header", [
                  _c("h3", { staticClass: "upload-dialog__heading" }, [
                    _vm._v("Uploaded Files"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "upload-dialog__files" },
                  [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.files.length === 0,
                            expression: "files.length === 0",
                          },
                        ],
                      },
                      [_vm._v("No files uploaded")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.files, function (file, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "upload-dialog__file" },
                        [
                          _vm.fileThumbnailStyle(file)
                            ? _c("div", {
                                staticClass:
                                  "upload-dialog__file__thumbnail--image",
                                style: _vm.fileThumbnailStyle(file),
                              })
                            : _c("svg-icon", {
                                staticClass:
                                  "upload-dialog__file__thumbnail--text",
                                attrs: { icon: "file", height: "28" },
                              }),
                          _vm._v(" "),
                          _c("input", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "text" },
                            domProps: { value: file },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyLink(file)
                                    },
                                  },
                                },
                                [_vm._v("copy link")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFile(file)
                                    },
                                  },
                                },
                                [_vm._v("delete")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "section",
                  [
                    _c("header", [
                      _c("h3", { staticClass: "upload-dialog__heading" }, [
                        _vm._v("Upload Files"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.openFilestack },
                      },
                      [_vm._v("Upload")]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Maximum upload size is 5MB")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Accepted filetypes are limited to image and text."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v("To set image as thumbnail:")]),
                    _vm._v(" "),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Find an image you want to set as the demo thumbnail"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          'Rename selected image to "demo_thumbnail" (ex. demo_thumbnail.png)'
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          'Upload the image (Name of file will be "DEMO_ID/RANDOM_HASH_demo_thumbnail.png")'
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Save demo")]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("toggle-visibility")
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings("share")
            ? _c("el-tab-pane", { attrs: { label: "Share" } }, [
                _c(
                  "h4",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.demo && !_vm.demo.uid,
                        expression: "demo && !demo.uid",
                      },
                    ],
                    staticStyle: { color: "#dc1257" },
                  },
                  [
                    _vm._v(
                      "\n            Save the demo before sharing!\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticStyle: { "margin-bottom": "1rem" } },
                  [
                    _c("header", [
                      _c("h3", [_vm._v("Share Link")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "demo-control__item" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Select", size: "mini" },
                              model: {
                                value: _vm.shareValue,
                                callback: function ($$v) {
                                  _vm.shareValue = $$v
                                },
                                expression: "shareValue",
                              },
                            },
                            _vm._l(_vm.shareOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("svg-icon", {
                            staticClass: "el-input-icon",
                            attrs: { icon: "angle" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-input",
                      {
                        attrs: { disabled: _vm.demo && !_vm.demo.uid },
                        model: {
                          value: _vm.shareLink,
                          callback: function ($$v) {
                            _vm.shareLink = $$v
                          },
                          expression: "shareLink",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "append",
                              disabled: _vm.demo && !_vm.demo.uid,
                            },
                            on: { click: _vm.copyShareLink },
                            slot: "append",
                          },
                          [_vm._v("COPY")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  [
                    _c("h3", [_vm._v("Download the demo as an HTML file")]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: _vm.demo && !_vm.demo.uid,
                        },
                        on: { click: _vm.download },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fas", "download"] },
                        }),
                        _vm._v(" DOWNLOAD"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings("editor")
            ? _c("el-tab-pane", { attrs: { label: "Editor" } }, [
                _c(
                  "section",
                  { staticStyle: { "margin-bottom": "1rem" } },
                  [
                    _c("h3", [_vm._v("Auto-update preview")]),
                    _vm._v("\n          OFF "),
                    _c("el-switch", {
                      on: { change: _vm.updateAutoUpdateSettings },
                      model: {
                        value: _vm.autoUpdate,
                        callback: function ($$v) {
                          _vm.autoUpdate = $$v
                        },
                        expression: "autoUpdate",
                      },
                    }),
                    _vm._v(" ON\n        "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hide,
                        expression: "hide",
                      },
                    ],
                    staticStyle: { "margin-bottom": "1rem" },
                  },
                  [
                    _c("h3", [_vm._v("Documentation Hints")]),
                    _vm._v("\n          OFF "),
                    _c("el-switch", {
                      on: { change: _vm.updateDocsToolipsSettings },
                      model: {
                        value: _vm.docsTooltip,
                        callback: function ($$v) {
                          _vm.docsTooltip = $$v
                        },
                        expression: "docsTooltip",
                      },
                    }),
                    _vm._v(" ON\n        "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticStyle: { "margin-bottom": "1rem" } },
                  [
                    _c("h3", [_vm._v("Auto-complete")]),
                    _vm._v(" "),
                    _c("el-tree", {
                      attrs: {
                        data: _vm.autoCompleteTree,
                        "default-checked-keys": _vm.autoComplete,
                        "default-expand-all": "",
                        "node-key": "id",
                        "show-checkbox": "",
                        props: _vm.defaultAutoCompleteProps,
                      },
                      on: { "check-change": _vm.updateAutoCompleteSettings },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings("template")
            ? _c("el-tab-pane", { attrs: { label: "Template" } }, [
                _c("section", { staticClass: "settings__entry" }, [
                  _c("h3", [_vm._v("Template")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.isTemplate,
                          callback: function ($$v) {
                            _vm.isTemplate = $$v
                          },
                          expression: "isTemplate",
                        },
                      }),
                      _vm._v(" Mark as template"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Template Type")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "demo-control__item" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Select" },
                          model: {
                            value: _vm.demoTemplate,
                            callback: function ($$v) {
                              _vm.demoTemplate = $$v
                            },
                            expression: "demoTemplate",
                          },
                        },
                        _vm._l(_vm.templateTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.id },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("svg-icon", {
                        staticClass: "el-input-icon",
                        attrs: { icon: "angle" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.premiumContentSet || _vm.adminTemplateCreate
                  ? _c("section", { staticClass: "settings__entry" }, [
                      _c("h3", [_vm._v("Admin Settings")]),
                      _vm._v(" "),
                      _vm.premiumContentSet
                        ? _c(
                            "div",
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.isPremium,
                                  callback: function ($$v) {
                                    _vm.isPremium = $$v
                                  },
                                  expression: "isPremium",
                                },
                              }),
                              _vm._v(
                                " Premium (Only premium users can view)\n          "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.adminTemplateCreate
                        ? _c(
                            "div",
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.isPublic,
                                  callback: function ($$v) {
                                    _vm.isPublic = $$v
                                  },
                                  expression: "isPublic",
                                },
                              }),
                              _vm._v(
                                ' Public (Mark both "Template" and "Public" creates a template for all users)\n          '
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass: "settings__entry",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                      "align-items": "flex-end",
                      flex: "1",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("toggle-visibility")
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveData },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings("metadata") && _vm.adminMetadataUpdate
            ? _c("el-tab-pane", { attrs: { label: "Metadata" } }, [
                _c(
                  "section",
                  {
                    staticClass: "settings__entry",
                    staticStyle: { "margin-bottom": "1rem" },
                  },
                  [
                    _c("h3", [_vm._v("Metadata")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.adminMetadataUpdate,
                            expression: "adminMetadataUpdate",
                          },
                        ],
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "To include demo in gallery, add the following"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              '"zc-gallery" or "zg-gallery" tag (in "General" tab)'
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              '"zingchart.com-demo" or "zinggrid.com-demo" tag (in "General" tab)'
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [_vm._v('"vanityUrl" metadata')]),
                          _vm._v(" "),
                          _c("li", [_vm._v('"feature" metadata')]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.metadata && !_vm.insertingMetadata,
                            expression: "!metadata && !insertingMetadata",
                          },
                        ],
                      },
                      [_vm._v("Demo has no metadata.")]
                    ),
                    _vm._v(" "),
                    _c("div", { attrs: { "demo-settings": "metadata" } }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { "demo-settings": "metadata__add--gallery" },
                        on: {
                          click: function ($event) {
                            _vm.galleryMetadataVisible = true
                          },
                        },
                      },
                      [_vm._v("Add Gallery Metadata")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass: "settings__entry",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                      "align-items": "flex-end",
                      flex: "1",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("toggle-visibility")
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveData },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("textarea", {
        ref: "copyBuffer",
        staticStyle: { height: "0", width: "0", opacity: "0" },
      }),
      _vm._v(" "),
      _c("gallery-metadata", {
        attrs: {
          zgRef: _vm.zgRef,
          demoType: _vm.demo.type,
          metadata: _vm.demo.metadata,
          visible: _vm.galleryMetadataVisible,
        },
        on: {
          close: function ($event) {
            _vm.galleryMetadataVisible = false
          },
          open: function ($event) {
            _vm.galleryMetadataVisible = true
          },
          "update:demoType": function ($event) {
            return _vm.$set(_vm.demo, "type", $event)
          },
          "update:demo-type": function ($event) {
            return _vm.$set(_vm.demo, "type", $event)
          },
          "update:metadata": function ($event) {
            return _vm.$set(_vm.demo, "metadata", $event)
          },
          "update:visible": function ($event) {
            _vm.galleryMetadataVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }