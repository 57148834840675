var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `zingnetCard${_vm.gridBody ? " gridBody" : ""}${
        _vm.span ? " span" : ""
      }`,
    },
    [
      _c("div", { staticClass: "zingnetCard--header--wrapper" }, [
        _c("h2", { staticClass: "zingnetCard--header" }, [
          _c("span", { staticClass: "header__left" }, [_vm._t("header")], 2),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "header__right" },
            [_vm._t("header__right")],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "header__subtitle" }, [_vm._t("subtitle")], 2),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: `zingnetCard--body${_vm.flex ? " cardFlex" : ""}` },
        [_vm._t("body")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }