var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { "domain-modal": "", visible: _vm.visible, width: "1200px" },
      on: { open: _vm.getData, close: _vm.triggerClose },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("User info for " + _vm._s(_vm.email)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "flex",
        },
        [
          _c("div", [
            _c("section", [
              _c("p", { staticClass: "zingnetCard--header" }, [
                _vm._v("General"),
              ]),
              _vm._v(" "),
              _vm.name ? _c("p", [_vm._v(_vm._s(_vm.name))]) : _vm._e(),
              _vm._v(" "),
              _vm.email || _vm.dataEmail
                ? _c("p", [_vm._v(_vm._s(_vm.email || _vm.dataEmail))])
                : _vm._e(),
              _vm._v(" "),
              _vm.company || _vm.dataCompany
                ? _c("p", [_vm._v(_vm._s(_vm.company || _vm.dataCompany))])
                : _vm._e(),
              _vm._v(" "),
              _vm.ip || _vm.dataIp
                ? _c("p", [_vm._v(_vm._s(_vm.ip || _vm.dataIp))])
                : _vm._e(),
              _vm._v(" "),
              _vm.noData ? _c("p", [_vm._v("No data on user")]) : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.quickbooks
              ? _c("section", [
                  _c("p", { staticClass: "zingnetCard--header" }, [
                    _vm._v("Quickbooks"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: `https://c11.qbo.intuit.com/app/customerdetail?nameId=${_vm.quickbooks}`,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Customer")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.email
              ? _c("section", [
                  _c("p", { staticClass: "zingnetCard--header" }, [
                    _vm._v("TrackJS"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { href: _vm.trackJsUrl, target: "_blank" } },
                    [_vm._v("Errors by User")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "section",
              [
                _c("p", { staticClass: "zingnetCard--header" }, [
                  _vm._v("Licensing"),
                ]),
                _vm._v(" "),
                _vm.licenses && _vm.licenses.length > 0
                  ? [
                      _c(
                        "ul",
                        [
                          _vm._l(_vm.licenses, function (license) {
                            return [
                              _c(
                                "a",
                                {
                                  key: license.id,
                                  attrs: {
                                    href: `https://c11.qbo.intuit.com/app/invoice?txnId=${license.id}`,
                                    target: "_blank",
                                  },
                                },
                                [_c("li", [_vm._v(_vm._s(license.name))])]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c(
                        "callout",
                        { attrs: { type: "danger", icon: "info" } },
                        [
                          _c(
                            "p",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "This domain is not associated with a ZingChart or ZingGrid license"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("section", [
              _c("p", { staticClass: "zingnetCard--header" }, [
                _vm._v("Total Spendings"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("$" + _vm._s(_vm.totalSpending || 0))]),
            ]),
            _vm._v(" "),
            _c("section", [
              _c("p", { staticClass: "zingnetCard--header" }, [
                _vm._v("Balance"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("$" + _vm._s(_vm.balance || 0))]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }