var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { pricing: "box" },
    },
    [
      _c("h2", { attrs: { pricing: "title" } }, [
        _vm._v(_vm._s(_vm.plan && _vm.plan.name ? _vm.plan.name : null)),
      ]),
      _vm._v(" "),
      _c("p", { attrs: { pricing: "tags" } }, [
        _vm._v(
          _vm._s(_vm.plan && _vm.plan.name ? _vm.planTags(_vm.plan.name) : null)
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { pricing: "body" } }, [
        _c("div", { attrs: { pricing: "lead" } }, [
          _c("div", { attrs: { pricing: "price-box" } }, [
            _c("strong", { attrs: { pricing: "price-value" } }, [
              _vm._v(
                "$" +
                  _vm._s(
                    _vm.planInterval.UnitPrice
                      ? _vm.planInterval.UnitPrice
                      : "-"
                  )
              ),
            ]),
            _vm._v(" "),
            _c("span", { attrs: { pricing: "price-type" } }, [
              _vm._v(_vm._s(_vm.interval === "perpetual" ? "" : "/ year")),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { pricing: "toggle-box", perpetual: _vm.isPerpetual },
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle")
                },
              },
            },
            [
              _c("span", { attrs: { pricing: "toggle-year" } }, [
                _vm._v("Yearly"),
              ]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: { viewBox: "0 0 24 24", type: "toggle-off" },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zM7 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z",
                      fill: "var(--color-tertiary-1)",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "svg",
                {
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: { viewBox: "0 0 24 24", type: "toggle-on" },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z",
                      fill: "var(--color-tertiary-1)",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", { attrs: { pricing: "toggle-perpetual" } }, [
                _vm._v("Perpetual"),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { pricing: "features" } }, [
          _vm.planInterval
            ? _c(
                "ul",
                _vm._l(_vm.planInterval.features, function (feature) {
                  return _c("li", { key: feature }, [_vm._v(_vm._s(feature))])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }