var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "secondary-layout",
    { attrs: { slim: "true", logout: "", isAuthing: _vm.isAuthing } },
    [
      _c("section", { staticClass: "content__wrap content__wrap--login" }, [
        _c(
          "div",
          {
            staticClass: "content content--narrow",
            attrs: { logout: "container" },
          },
          [
            _c(
              "entry-container",
              { attrs: { slim: "true", logout: "center" } },
              [
                _vm.isExpired
                  ? _c(
                      "h3",
                      { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                      [_vm._v("Session Expired")]
                    )
                  : _vm.emailChange
                  ? _c(
                      "h3",
                      { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                      [_vm._v("Email Change")]
                    )
                  : _vm.passwordChange
                  ? _c(
                      "h3",
                      { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                      [_vm._v("Password Change")]
                    )
                  : _c(
                      "h3",
                      { attrs: { slot: "entryHeader" }, slot: "entryHeader" },
                      [_vm._v("Log out successful")]
                    ),
                _vm._v(" "),
                _vm.isExpired
                  ? _c(
                      "div",
                      {
                        attrs: { slot: "entryBody", logout: "container__body" },
                        slot: "entryBody",
                      },
                      [
                        _c("h1", [
                          _vm._v(
                            "You're now logged out of the ZingSoft Studio"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("Please log back in "),
                          _c("br"),
                          _vm._v(" to renew your session"),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "button button--bottom button--center",
                            on: { click: _vm.redirect },
                          },
                          [_c("p", [_c("span", [_vm._v("Login")])])]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        attrs: { slot: "entryBody", logout: "container__body" },
                        slot: "entryBody",
                      },
                      [
                        _vm.emailChange
                          ? [
                              _c("h1", [_vm._v("Email successfully changed")]),
                              _vm._v(" "),
                              _c("p", { attrs: { logout: "margin--bottom" } }, [
                                _vm._v("Please login with new email!"),
                              ]),
                            ]
                          : _vm.passwordChange
                          ? [
                              _c("h1", [
                                _vm._v("Password successfully changed"),
                              ]),
                              _vm._v(" "),
                              _c("p", { attrs: { logout: "margin--bottom" } }, [
                                _vm._v("Please login with new password!"),
                              ]),
                            ]
                          : [
                              _c("h1", [
                                _vm._v(
                                  "You're now logged out of the ZingSoft Studio"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { attrs: { logout: "margin--bottom" } }, [
                                _vm._v("We hope to see you again soon!"),
                              ]),
                            ],
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "button button--bottom button--center",
                            on: { click: _vm.redirect },
                          },
                          [_c("p", [_c("span", [_vm._v("Log Back In")])])]
                        ),
                        _vm._v(" "),
                        _c("p", { attrs: { logout: "link--return" } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.zinggrid.com",
                                logout: "link",
                              },
                            },
                            [_vm._v("Return to homepage")]
                          ),
                        ]),
                      ],
                      2
                    ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }