var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "studio-editor": "container" } }, [
    _c(
      "div",
      { attrs: { "studio-editor": "controls" } },
      [
        _c(
          "el-popover",
          {
            attrs: {
              placement: "bottom",
              trigger: "click",
              content: "Copied!",
              "popper-class": "studioEditor_popoverCopy",
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: { slot: "reference", type: "text", size: "mini" },
                on: { click: _vm.codeCopy },
                slot: "reference",
              },
              [_vm._v("Copy")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "text", size: "mini" },
            on: { click: _vm.codeFold },
          },
          [_vm._v(_vm._s(_vm.toggleFold))]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("textarea", { attrs: { "studio-editor": "clipboard" } }),
    _vm._v(" "),
    _c("div", { ref: "editor", staticClass: "editor" }),
    _vm._v(" "),
    _c("input", { attrs: { type: "hidden", customAttribute: _vm.options } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }