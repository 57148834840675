var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("secondary-layout", { attrs: { isAuthing: _vm.isAuthing } }, [
    _c("section", { staticClass: "content__wrap content__wrap--login" }, [
      _c(
        "div",
        { staticClass: "content content--narrow" },
        [
          _vm.planId && _vm.planInterval
            ? _c("div", { staticClass: "purchaseNotification" }, [
                _vm._v(
                  "\n        Please login or sign up to continue your purchase.\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("entry-container", [
            _c("h3", { attrs: { slot: "entryHeader" }, slot: "entryHeader" }, [
              _vm._v("Create An Account"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "entryBody" }, slot: "entryBody" },
              [
                _c(
                  "div",
                  [
                    _c(
                      "google-entry",
                      {
                        attrs: { "regenerate-session": _vm.regenerateSession },
                      },
                      [_vm._v("Continue with Google")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "margin--small--laptop" }, [
                  _vm._v("or"),
                ]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "signupForm",
                    attrs: { model: _vm.form, "signup-form": "" },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("input-email", {
                      attrs: { form: "form" },
                      on: {
                        validate: function ($event) {
                          return _vm.validateForm("email")
                        },
                      },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        ref: "popover",
                        staticClass: "tooltip_validate",
                        attrs: {
                          trigger: "focus",
                          effect: "dark",
                          placement: _vm.validationPlacement,
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n                Password must:\n                "
                          ),
                          _c("ul", { staticClass: "password-validation" }, [
                            _c(
                              "li",
                              {
                                staticClass: "password",
                                class: _vm.validClass("atLeast8Chars"),
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: _vm.validIcon("atLeast8Chars"),
                                  },
                                }),
                                _vm._v(" Be a minimum of 8 characters"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "password",
                                class: _vm.validClass("atLeast1Lower"),
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: _vm.validIcon("atLeast1Lower"),
                                  },
                                }),
                                _vm._v(" Contain at least lower case letter"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "password",
                                class: _vm.validClass("atLeast1Upper"),
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: _vm.validIcon("atLeast1Upper"),
                                  },
                                }),
                                _vm._v(" Contain at least 1 upper case letter"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "password",
                                class: _vm.validClass("atLeast1Number"),
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    icon: _vm.validIcon("atLeast1Number"),
                                  },
                                }),
                                _vm._v(" Contain at least 1 number"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass: "password",
                                class: _vm.validClass("notEmail"),
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: _vm.validIcon("notEmail") },
                                }),
                                _vm._v(" Not match email"),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { error: _vm.passwordError } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "password",
                              rules: [
                                {
                                  required: true,
                                  message: "Please input password",
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validateLength,
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validateUpper,
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validateLower,
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validateNumber,
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validateNotEmail,
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover:popover",
                                    arg: "popover",
                                  },
                                ],
                                attrs: {
                                  tabindex: "2",
                                  type: "password",
                                  placeholder: "Create a password",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.validateForm("password")
                                  },
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "auth0-lock-icon auth0-lock-icon-box",
                                      attrs: {
                                        width: "11",
                                        height: "14",
                                        viewBox: "0 0 13 16",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z",
                                          "fill-rule": "evenodd",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { error: _vm.passwordConfirmError } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "confirm",
                              rules: [
                                {
                                  required: true,
                                  message: "Please input password",
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validateConfirm,
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  tabindex: "3",
                                  type: "password",
                                  placeholder: "Confirm your password",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.validateForm("confirm")
                                  },
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.signup.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.form.confirm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "confirm", $$v)
                                  },
                                  expression: "form.confirm",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "auth0-lock-icon auth0-lock-icon-box",
                                      attrs: {
                                        width: "11",
                                        height: "14",
                                        viewBox: "0 0 13 16",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z",
                                          "fill-rule": "evenodd",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "button button--bottom button--center",
                            attrs: {
                              type: "primary",
                              disabled: !_vm.validSignup,
                              loading: _vm.creatingAccount,
                            },
                            on: { click: _vm.signup },
                          },
                          [_vm._v("\n                Sign Up\n              ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "margin--small--laptop p--terms" }, [
                  _vm._v(
                    "\n            By creating an account you agree to the\n            "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "term_link",
                      attrs: { href: "https://www.zinggrid.com/legal" },
                    },
                    [_vm._v("Terms of Service")]
                  ),
                  _vm._v("\n            and \n            "),
                  _c(
                    "a",
                    {
                      staticClass: "term_link",
                      attrs: { href: "https://www.zinggrid.com/privacy" },
                    },
                    [_vm._v("Privacy Policy")]
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "signup__container" }, [
            _c("p", { staticClass: "margin--small--laptop" }, [
              _vm._v("\n          Already have an account?\n          "),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.passQueryParam(true)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "signup__link" }, [
                    _vm._v("Log In."),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }