var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasUser,
          expression: "hasUser",
        },
      ],
      attrs: { "header-bar": "bar" },
    },
    [
      _c("div", { attrs: { "header-bar": "inner" } }, [
        _c(
          "p",
          [
            _c("strong", [_vm._v("Reminder:")]),
            _vm._v(" Complete your "),
            _c("router-link", { attrs: { to: "/account" } }, [
              _vm._v("profile"),
            ]),
            _vm._v(" to help us better serve you!"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "trigger",
          on: {
            click: function ($event) {
              return _vm.toggleBar()
            },
          },
        },
        [
          _c("svg-icon", {
            staticClass: "spin",
            attrs: { icon: "close--circle", height: "1rem", width: "1rem" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }