<template>
  <zingnet-card>
    <span slot="header">Quickbooks</span>
    <div slot="body">
      <section class="zingnetCard--row">
        <!-- QUICKBOOKS CONNECT -->
        <div class="zingnetCard--column">
          <h3 class="zingnetCard--header">Client</h3>
          <p>When users are unable to view or purchase licenses, the access token must be renewed manually.
            Usually, token is refreshed every hour and should last 100 days.</p>
          <el-button @click="connectQuickbooks" quickbooksClient="connect">Connect to Quickbooks<svg-icon icon="run"></svg-icon></el-button>
        </div>

        <!-- QUICKBOOKS PRODUCT UPDATE -->
        <div class="zingnetCard--column">
          <h3 class="zingnetCard--header">Product Update</h3>
          <p>After making changes to products on Quickbooks, the products in database must also be updated
            in order to see changes applied to products displayed in Studio.</p>
          <el-button @click="updateProducts">Update Products<svg-icon icon="run"></svg-icon></el-button>
        </div>
      </section>
    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import SvgIcon from '../../../../components/SvgIcon.vue';
import ZingnetCard from '../../components/ZingnetCard.vue';

export default {
  components: {
    SvgIcon,
    ZingnetCard,
  },
  data() {
    return {
      cardInfo: {
        "card": {
          "expYear": "2019",
          "expMonth": "10",
          "address": {
            "region": "CA",
            "postalCode": "91731",
            "streetAddress": "123 college street",
            "country": "US",
            "city": "CA"
          },
          "name": "Jeanette Test",
          "cvc": "123",
          "number": "5105105105105100"
        }
      },
    }
  },
  mounted() {
    this.createAccessToken();
  },
  methods: {
    /**
     * @description After authorizing Quickbooks, use query parameters `code` and `realmId` to
     * create and store access token
     */
    createAccessToken() {
      let code = this.$route.query.code;
      let realmId = this.$route.query.realmId;

      if (code && realmId) {
        axios({
          url: `/api/admin/quickbooks/token?code=${code}&realmId=${realmId}`,
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
        }).then((response) => {
          window.close();
        }).catch((err) => {
          this.$message({
            duration: 10000,
            message: 'Unable to create Quickbooks access token',
            showClose: true,
            type: 'error',
          });
        });
      }
    },
    /**
     * @description Connects to Quickbooks by making an authorization request to receive access token.
     * Access token is used to access Quickbooks API
     */
    connectQuickbooks() {
      axios({
        url: '/api/admin/quickbooks/authorize',
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
      }).then((response) => {
        let redirectPath = response.data;
        window.open(redirectPath);
      }).catch((err) => {
        this.$message({
          duration: 10000,
          message: 'Unable to authorize Quickbooks',
          showClose: true,
          type: 'error',
        });
      });
    },
    /**
     * @description Makes a request to Quickbooks to update product data stored in database
     */
    updateProducts() {
      axios({
        url: '/api/admin/quickbooks/update',
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
      }).then((response) => {
        this.$message({
          duration: 10000,
          message: 'Product data retrieved from Quickbooks. Please allow a few moments for data to be updated.',
          showClose: true,
          type: 'success',
        });
      }).catch((err) => {
        this.$message({
          duration: 10000,
          message: 'Unable to update product data.',
          showClose: true,
          type: 'error',
        });
      });
    }
  },
}
</script>

<style scoped>
  [icon="run"] {
    margin-left: 0.3125rem;
  }
</style>