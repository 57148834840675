var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("ZingChart License Generator"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "subtitle" }, slot: "subtitle" },
      [
        _c("p", [
          _vm._v(
            "Please choose the proper license form to fill out when generating a license for a client."
          ),
        ]),
        _vm._v(" "),
        _c(
          "collapse-section",
          {
            attrs: {
              title: "License Generator Notes",
              open: false,
              "button-text": "Notes",
            },
          },
          [
            _c(
              "div",
              { attrs: { slot: "body" }, slot: "body" },
              [
                _c("p", [
                  _vm._v(
                    "Example scenarios of domains and subdomains that would get covered by license key."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Note that when subdomain is omitted or asterisk (*) is used, this makes the license key cover more subdomains."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "It is best if keys are generated to be more strict if license key is for subdomain."
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "domain",
                        label: "Domain / Subdomain",
                        width: "300",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "generated",
                        label: "Covered by keys generated for",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("section", { staticClass: "zingnetCard--row" }, [
        _c("section", { staticClass: "zingnetCard--column" }, [
          _c(
            "section",
            { staticClass: "section" },
            [
              _c(
                "h3",
                { staticClass: "zingnetCard--header" },
                [
                  _vm._v("\n            Output Filename\n            "),
                  _c("bubble-count", {
                    attrs: { count: 1, label: _vm.label, after: true },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [_vm._v("Providing the output filename is optional")]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.fileNameModel,
                  callback: function ($$v) {
                    _vm.fileNameModel = $$v
                  },
                  expression: "fileNameModel",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "section" },
            [
              _c(
                "h3",
                { staticClass: "zingnetCard--header" },
                [
                  _vm._v("\n            License Generation\n            "),
                  _c("bubble-count", {
                    attrs: { count: 2, label: _vm.label, after: true },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", [_vm._v("Separate with commas")]),
              _vm._v(" "),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "*.zingchart.com,api.zingchart.com",
                },
                model: {
                  value: _vm.licenseVal,
                  callback: function ($$v) {
                    _vm.licenseVal = $$v
                  },
                  expression: "licenseVal",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { span: "" }, on: { click: _vm._handleLicense } },
                [
                  _vm._v("\n            Generate License\n            "),
                  _c("svg-icon", {
                    attrs: {
                      icon: "generate",
                      fill: "var(--color-primary-gray)",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "zingnetCard--column" },
          [
            _c("h3", { staticClass: "zingnetCard--header" }, [
              _vm._v("Additional Generation Options"),
            ]),
            _vm._v(" "),
            _c(
              "collapse-section",
              { attrs: { title: "Enterprise License Generation", open: true } },
              [
                _c(
                  "div",
                  { attrs: { slot: "body" }, slot: "body" },
                  [
                    _c(
                      "h3",
                      { staticClass: "zingnetCard--header" },
                      [
                        _vm._v(
                          "\n              License Generation\n              "
                        ),
                        _c("bubble-count", {
                          attrs: { count: 2, label: _vm.label, after: true },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Separate with commas")]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { type: "textarea", placeholder: "Company Name" },
                      model: {
                        value: _vm.enterpriseLicenseVal,
                        callback: function ($$v) {
                          _vm.enterpriseLicenseVal = $$v
                        },
                        expression: "enterpriseLicenseVal",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { span: "" },
                        on: { click: _vm._handleEnterpriseLicense },
                      },
                      [
                        _vm._v(
                          "\n              Generate License\n              "
                        ),
                        _c("svg-icon", {
                          attrs: {
                            icon: "generate",
                            fill: "var(--color-primary-gray)",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "collapse-section",
              { attrs: { title: "PlantomJS License Generation" } },
              [
                _c(
                  "div",
                  { attrs: { slot: "body" }, slot: "body" },
                  [
                    _c(
                      "h3",
                      { staticClass: "zingnetCard--header" },
                      [
                        _vm._v(
                          "\n              License Generation\n              "
                        ),
                        _c("bubble-count", {
                          attrs: { count: 2, label: _vm.label, after: true },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Separate with commas")]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { type: "textarea", placeholder: "Company Name" },
                      model: {
                        value: _vm.phantomLicenseVal,
                        callback: function ($$v) {
                          _vm.phantomLicenseVal = $$v
                        },
                        expression: "phantomLicenseVal",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { span: "" },
                        on: { click: _vm._handlePhantomLicense },
                      },
                      [
                        _vm._v(
                          "\n              Generate License\n              "
                        ),
                        _c("svg-icon", {
                          attrs: {
                            icon: "generate",
                            fill: "var(--color-primary-gray)",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                ref: "downloadAnchorTag",
                attrs: {
                  href: _vm.fileData,
                  download: _vm.fileName,
                  hidden: "",
                },
              },
              [_vm._v("Hidden Anchor Tag")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }