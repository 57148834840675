var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { "zing-theme-builder": "item", name: _vm.name, value: _vm.value },
    },
    [
      _c("div", { staticClass: "form-field" }, [
        _c(
          "label",
          { attrs: { "data-field": "checkbox" } },
          [
            _c("form-checkbox", { attrs: { checked: _vm.selected } }),
            _vm._v(" "),
            _c("span", {
              attrs: { "zing-theme-builder": "control-cell" },
              domProps: { innerHTML: _vm._s(_vm.tooltip) },
            }),
            _vm._v(" "),
            _c("span", { attrs: { "zing-theme-builder": "control-cell" } }, [
              _vm.resolvedVal
                ? _c("span", {
                    attrs: {
                      defaultVal: _vm.defaultVal,
                      "zing-theme-builder": "default",
                    },
                    domProps: { innerHTML: _vm._s(_vm.resolvedVal) },
                  })
                : _c(
                    "span",
                    {
                      attrs: {
                        "zing-theme-builder": "default",
                        defaultVal: _vm.defaultVal,
                      },
                    },
                    [_c("em", [_vm._v(_vm._s(_vm.defaultVal))])]
                  ),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "text", "zing-theme-builder": "value" },
                domProps: { value: _vm.value },
                on: { keyup: _vm._controlTextChange },
              }),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }