var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Quickbooks"),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "body" }, slot: "body" }, [
      _c("section", { staticClass: "zingnetCard--row" }, [
        _c(
          "div",
          { staticClass: "zingnetCard--column" },
          [
            _c("h3", { staticClass: "zingnetCard--header" }, [
              _vm._v("Client"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "When users are unable to view or purchase licenses, the access token must be renewed manually.\n          Usually, token is refreshed every hour and should last 100 days."
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { quickbooksClient: "connect" },
                on: { click: _vm.connectQuickbooks },
              },
              [
                _vm._v("Connect to Quickbooks"),
                _c("svg-icon", { attrs: { icon: "run" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "zingnetCard--column" },
          [
            _c("h3", { staticClass: "zingnetCard--header" }, [
              _vm._v("Product Update"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "After making changes to products on Quickbooks, the products in database must also be updated\n          in order to see changes applied to products displayed in Studio."
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              { on: { click: _vm.updateProducts } },
              [
                _vm._v("Update Products"),
                _c("svg-icon", { attrs: { icon: "run" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }