var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `scorecard ${_vm.secondary ? "secondary" : "primary"}` },
    [
      _c(
        "p",
        { staticClass: "value" },
        [
          _vm.icon
            ? _c("svg-icon", {
                attrs: {
                  icon: _vm.icon,
                  height: _vm.iconSize,
                  width: _vm.iconSize,
                },
              })
            : _vm._e(),
          _vm._v(
            "\n    " +
              _vm._s(_vm.currency ? "$" : "") +
              _vm._s(_vm.addCommas(_vm.value)) +
              "\n  "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }