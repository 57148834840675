var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avatar-upload" },
    [
      _c("div", { staticClass: "avatar-upload__image", style: _vm.avatar }),
      _vm._v(" "),
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content:
              "Coming Soon! Currently available when Google accounts are linked",
            placement: "bottom",
          },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "avatar-upload__action",
              attrs: { type: "primary", size: "mini", plain: "" },
            },
            [_vm._v("Edit Avatar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }