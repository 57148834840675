var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { noHide: !_vm.allowHide, hidden: _vm.hideToggleGrid } },
    [
      _c(
        "div",
        {
          attrs: {
            "toggle-grid": "container",
            heading: _vm.heading,
            hideList: _vm.hideList,
            hidden: _vm.hideGrid,
          },
        },
        [
          _c(
            "zing-grid",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    (!_vm.hideList && !_vm.groupAdmin) ||
                    (_vm.groupAdmin && _vm.groupingAdminDashboardView),
                  expression:
                    "(!hideList && !groupAdmin) || (groupAdmin && groupingAdminDashboardView)",
                },
              ],
              attrs: {
                height: "625",
                pager: "",
                "page-size": "10",
                sorter: "",
                "toggle-grid": "list",
              },
            },
            [
              _c(
                "zg-caption",
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.caption ? _vm.caption : "") +
                      "\n        "
                  ),
                  _vm.create
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.hideCreate,
                              expression: "!hideCreate",
                            },
                          ],
                          attrs: {
                            "toggle-grid": "createButton",
                            size: "small",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.createVisible = true
                            },
                          },
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-colgroup",
                [
                  _vm.listUsers
                    ? _c("zg-column", { attrs: { index: "id", hidden: "" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.setRole
                    ? _c("zg-column", {
                        attrs: { index: "id_user", hidden: "" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("zg-column", { attrs: { index: "id_role", hidden: "" } }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      index: "name",
                      header: _vm.listHeader,
                      renderer: "renderName",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      hidden: !_vm.setRole || _vm.hideRoleSet,
                      index: "id_role, set",
                      header: "Role",
                      type: "custom",
                      renderer: "renderSetRoles",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      hidden: !_vm.setRole || _vm.hideRoleView,
                      index: "id_role, view",
                      header: "Role",
                      type: "custom",
                      renderer: "renderViewRoles",
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      hidden: !_vm.setRoleDefault,
                      index: "type",
                      header: "Type",
                      "sort-asc": "",
                      renderer: "formatSnakeCase",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "zg-column",
                    {
                      attrs: {
                        hidden: _vm.removeDetail || _vm.hidePermissionView,
                        index: "permissions_edit",
                        header: "Permissions",
                        type: "custom",
                        width: "175",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "mini", type: "primary" } },
                        [_vm._v(_vm._s(_vm.buttonText))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.requestDelete
                    ? _c(
                        "zg-column",
                        {
                          attrs: {
                            hidden: _vm.hideDelete,
                            header: " ",
                            index: "remove_button",
                            sort: "false",
                            type: "custom",
                            width: "125",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "danger" } },
                            [_vm._v(_vm._s(_vm.removeText))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-data",
                [
                  !_vm.setRole && !_vm.hideList
                    ? _c("zg-param", {
                        attrs: {
                          name: "src",
                          value: `${_vm.requestList}${
                            _vm.groupId ? "/" + _vm.groupId : ""
                          }`,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "headers", value: _vm.headerVal },
                  }),
                  _vm._v(" "),
                  _vm.paging
                    ? _c("zg-param", {
                        attrs: { name: "countPath", value: "total" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paging
                    ? _c("zg-param", {
                        attrs: { name: "loadByPage", value: "true" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paging
                    ? _c("zg-param", {
                        attrs: { name: "nextPath", value: "next" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paging
                    ? _c("zg-param", {
                        attrs: { name: "pageKey", value: "page" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paging
                    ? _c("zg-param", {
                        attrs: { name: "prevPath", value: "previous" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.paging
                    ? _c("zg-param", {
                        attrs: { name: "recordPath", value: "results" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "zing-grid",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.groupAdmin || !_vm.hideDetailGrid,
                  expression: "!groupAdmin || !hideDetailGrid",
                },
              ],
              class: _vm.hideList ? "main" : "hide",
              attrs: { height: "680", "toggle-grid": "details" },
            },
            [
              _c(
                "zg-caption",
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.caption ? _vm.caption : _vm.captionSecondary) +
                      _vm._s(
                        _vm.detailCaption ? `: ${_vm.detailCaption}` : ""
                      ) +
                      "\n        "
                  ),
                  !_vm.hideList
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.hidePermissions },
                        },
                        [_vm._v("Back")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-colgroup",
                [
                  _c("zg-column", { attrs: { index: "id", hidden: "" } }),
                  _vm._v(" "),
                  _vm.admin && _vm.setRoleDefault
                    ? _c("zg-column", {
                        attrs: {
                          hidden: _vm.hideDetailSet,
                          index: "id, name, active",
                          header: " ",
                          renderer: "renderCheckbox",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("zg-column", {
                    attrs: {
                      index: "title, description",
                      header: "Permission",
                      renderer: "renderFeatureName",
                      width: _vm.admin ? "50%" : "75%",
                    },
                  }),
                  _vm._v(" "),
                  _vm.admin && _vm.setFeatureActive
                    ? _c("zg-column", {
                        attrs: {
                          hidden: _vm.hideStatus,
                          index: "id, name, status",
                          header: "Active",
                          renderer: "renderActive",
                          width: "100",
                        },
                      })
                    : _vm.admin || _vm.groupAdmin
                    ? _c("zg-column", {
                        attrs: {
                          hidden: _vm.hideDetailSet,
                          index: "id, name, enabled",
                          header: "Enabled",
                          renderer: "renderAdminEnabled",
                          width: "100",
                        },
                      })
                    : _c("zg-column", {
                        attrs: {
                          index: "id, name, enabled, editable",
                          header: "Enabled",
                          renderer: "renderEnabled",
                          width: "100",
                        },
                      }),
                  _vm._v(" "),
                  (_vm.admin || _vm.groupAdmin) && _vm.setAll
                    ? _c("zg-column", {
                        attrs: {
                          hidden: _vm.hideDetailSet,
                          index: "id, name, editable",
                          header: "Editable",
                          renderer: "renderEditable",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.admin || _vm.groupAdmin) && _vm.setAll
                    ? _c("zg-column", {
                        attrs: {
                          hidden: _vm.hideDetailSet,
                          index: "id, name, visible",
                          header: "Visisble",
                          renderer: "renderVisisble",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.admin || _vm.groupAdmin) && _vm.setAll
                    ? _c("zg-column", {
                        attrs: {
                          hidden: !_vm.hideDetailSet,
                          index: "enabled",
                          header: "Enabled",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.admin || _vm.groupAdmin) && _vm.setAll
                    ? _c("zg-column", {
                        attrs: {
                          hidden: !_vm.hideDetailSet,
                          index: "editable",
                          header: "Editable",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.admin || _vm.groupAdmin) && _vm.setAll
                    ? _c("zg-column", {
                        attrs: {
                          hidden: !_vm.hideDetailSet,
                          index: "visible",
                          header: "Visisble",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { title: "Create", visible: _vm.createVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.createVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px", model: _vm.createForm } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Name" } },
                    [
                      _c("el-input", {
                        attrs: { autofocus: "", maxlength: _vm.MAX_LENGTH },
                        model: {
                          value: _vm.createForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "name", $$v)
                          },
                          expression: "createForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Description" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: _vm.MAX_LENGTH },
                        model: {
                          value: _vm.createForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.createForm, "description", $$v)
                          },
                          expression: "createForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Type", size: "large" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Select" },
                          model: {
                            value: _vm.createForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.createForm, "type", $$v)
                            },
                            expression: "createForm.type",
                          },
                        },
                        _vm._l(_vm.createTypeOptions, function (option, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: `${
                                option.value[0].toUpperCase() +
                                option.value.slice(1)
                              }`,
                              value: option.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { "button-align": "right", size: "large" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitCreate },
                        },
                        [_vm._v("Create")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "dialog--condense",
              attrs: { visible: _vm.deleteVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.deleteVisible = $event
                },
              },
            },
            [
              _c(
                "h2",
                {
                  staticClass: "dialog__icon",
                  staticStyle: { color: "#f56c6c" },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: ["fas", "exclamation-triangle"],
                      size: "3x",
                    },
                  }),
                  _c("br"),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", [
                _vm._v(
                  "Are you sure you want to " +
                    _vm._s(_vm.removeText.toLowerCase()) +
                    "?"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "You will not be able to revert your changes once deletion is confirmed."
                ),
              ]),
              _vm._v(" "),
              _vm.setRoleFeature && _vm.requiresMigrate
                ? _c(
                    "div",
                    { staticClass: "dialog__delete" },
                    [
                      _c("h3", {}, [_vm._v("Cannot delete this role!")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "There are users in this role. Please manually remove all users from the role or select a role to move all users to."
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Move current users to" },
                          model: {
                            value: _vm.migrateTo,
                            callback: function ($$v) {
                              _vm.migrateTo = $$v
                            },
                            expression: "migrateTo",
                          },
                        },
                        [
                          _vm._l(_vm.roles, function (role, index) {
                            return [
                              role
                                ? _c("el-option", {
                                    key: role ? role.id : index,
                                    attrs: {
                                      label: role
                                        ? _vm.formatSnakeCase(role.name)
                                        : "",
                                      value: role ? role.id : "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog__controls",
                  attrs: { "button-align": "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.deleteVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmDelete()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.removeText))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h1",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.type === "features" || _vm.type === "permissions_admin",
              expression: "type === 'features' || type === 'permissions_admin'",
            },
          ],
        },
        [_vm._v("Sorry, you do not access to view")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }