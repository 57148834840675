var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "zingnetCardSection" }, [
    _c(
      "div",
      { staticClass: "zingnetCardRow" },
      [_c("error-tracking"), _vm._v(" "), _c("uptime-monitoring")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }