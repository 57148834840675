var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { pricing: "wrap" } },
    [
      _c(
        "el-button",
        { attrs: { pricing: "btn--closeform" }, on: { click: _vm.closeView } },
        [_vm._v("Back to Pricing")]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "inner" },
          [
            _c("div", { attrs: { pricing: "intro", loaded: "true" } }, [
              _c("h1", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _vm._v(" "),
            _vm.view === 0
              ? _c("price-box", {
                  staticClass: "desktop--hide",
                  attrs: {
                    plan: _vm.plan,
                    "plan-interval": _vm.planInterval,
                    interval: _vm.interval,
                    isPerpetual: _vm.isPerpetual,
                  },
                  on: {
                    toggle: _vm.toggleInterval,
                    "update:plan": function ($event) {
                      _vm.plan = $event
                    },
                    "update:planInterval": function ($event) {
                      _vm.planInterval = $event
                    },
                    "update:plan-interval": function ($event) {
                      _vm.planInterval = $event
                    },
                    "update:interval": function ($event) {
                      _vm.interval = $event
                    },
                    "update:isPerpetual": function ($event) {
                      _vm.isPerpetual = $event
                    },
                    "update:is-perpetual": function ($event) {
                      _vm.isPerpetual = $event
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.view === 0
              ? _c("div", { attrs: { pricing: "container--box" } }, [
                  _c(
                    "div",
                    { staticClass: "price-box-wrapper" },
                    [
                      _vm.view === 0
                        ? _c("price-box", {
                            staticClass: "mobile--hide tablet--hide",
                            attrs: {
                              plan: _vm.plan,
                              "plan-interval": _vm.planInterval,
                              interval: _vm.interval,
                              isPerpetual: _vm.isPerpetual,
                            },
                            on: {
                              toggle: _vm.toggleInterval,
                              "update:plan": function ($event) {
                                _vm.plan = $event
                              },
                              "update:planInterval": function ($event) {
                                _vm.planInterval = $event
                              },
                              "update:plan-interval": function ($event) {
                                _vm.planInterval = $event
                              },
                              "update:interval": function ($event) {
                                _vm.interval = $event
                              },
                              "update:isPerpetual": function ($event) {
                                _vm.isPerpetual = $event
                              },
                              "update:is-perpetual": function ($event) {
                                _vm.isPerpetual = $event
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr", { attrs: { pricing: "divider--vertical" } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("h2", { attrs: { pricing: "h2" } }, [
                        _vm._v("License & Payment Details"),
                      ]),
                      _vm._v(" "),
                      _c("hr", { attrs: { pricing: "divider--horizontal" } }),
                      _vm._v(" "),
                      _c("subscription-form", {
                        attrs: {
                          "form-pricing": _vm.formPricing,
                          "include-domain-fields": _vm.includeDomainFields,
                          plan: _vm.plan,
                          submitAction: _vm.nextStep,
                        },
                        on: {
                          "update:plan": function ($event) {
                            _vm.plan = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.view === 1
              ? _c(
                  "div",
                  {
                    attrs: {
                      pricing: _vm.loginOnly
                        ? "container--medium--box"
                        : "container--large",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { pricing: "container--flex-column" } },
                      [
                        _c("h2", { attrs: { pricing: "h2" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.loginOnly
                                ? "Login to complete account creation!"
                                : "Already have an account?"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr", { attrs: { pricing: "divider--horizontal" } }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.pushAccountDataLayer(
                                  "existing",
                                  "google"
                                )
                              },
                            },
                          },
                          [
                            _c("google-entry", { attrs: { popup: "" } }, [
                              _vm._v("Log in with Google"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { attrs: { pricing: "entry-text" } }, [
                          _vm._v("or use you email to log in"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form",
                          {
                            ref: "formLogin",
                            attrs: {
                              pricing: "formLogin",
                              model: _vm.formLogin,
                              "hide-required-asterisk": "",
                              "label-position": "top",
                              rules: _vm.formLoginValidationRules,
                            },
                            on: { validate: _vm.formLoginValidation },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Email", prop: "email" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "Enter email address",
                                    type: "email",
                                  },
                                  model: {
                                    value: _vm.formLogin.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formLogin, "email", $$v)
                                    },
                                    expression: "formLogin.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Password", prop: "password" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "Enter password",
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.formLogin.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formLogin, "password", $$v)
                                    },
                                    expression: "formLogin.password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      disabled: _vm.formLoginIncomplete,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.userEntry("login")
                                      },
                                    },
                                  },
                                  [_vm._v("Log In")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                _vm.resetModalVisible = true
                              },
                            },
                          },
                          [_vm._v("Forgot your password")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.loginOnly
                      ? _c(
                          "div",
                          { attrs: { pricing: "container--box--flex-column" } },
                          [
                            _c("h2", { attrs: { pricing: "h2" } }, [
                              _vm._v("Don't have an account?"),
                            ]),
                            _vm._v(" "),
                            _c("hr", {
                              attrs: { pricing: "divider--horizontal" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.pushAccountDataLayer(
                                      "new",
                                      "google"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "google-entry",
                                  { attrs: { popup: "", signup: "" } },
                                  [_vm._v("Sign Up with Google")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", { attrs: { pricing: "entry-text" } }, [
                              _vm._v("or use you email to log in"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form",
                              {
                                ref: "formSignup",
                                attrs: {
                                  pricing: "formSignup",
                                  model: _vm.formSignup,
                                  "hide-required-asterisk": "",
                                  "label-position": "top",
                                  rules: _vm.formSignupValidationRules,
                                },
                                on: { validate: _vm.formSignupValidation },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "Email", prop: "email" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Enter email address",
                                        type: "email",
                                      },
                                      model: {
                                        value: _vm.formSignup.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formSignup, "email", $$v)
                                        },
                                        expression: "formSignup.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Choose a Password",
                                      prop: "password",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Enter password",
                                        type: "password",
                                      },
                                      model: {
                                        value: _vm.formSignup.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formSignup,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "formSignup.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Confirm Password",
                                      prop: "passwordConfirm",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "Confirm password",
                                        type: "password",
                                      },
                                      model: {
                                        value: _vm.formSignup.passwordConfirm,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formSignup,
                                            "passwordConfirm",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formSignup.passwordConfirm",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: _vm.formSignupIncomplete,
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.userEntry("signup")
                                          },
                                        },
                                      },
                                      [_vm._v("Sign Up")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.view === 2
              ? _c("div", { attrs: { pricing: "container--large--box" } }, [
                  _c("div", { attrs: { pricing: "confirmation" } }, [
                    _c("h2", { attrs: { pricing: "h2" } }, [
                      _vm._v("Account Successfully Created"),
                    ]),
                    _vm._v(" "),
                    _c("hr", { attrs: { pricing: "divider--horizontal" } }),
                    _vm._v(" "),
                    _c("div", { attrs: { pricing: "text--light" } }, [
                      _c(
                        "p",
                        { attrs: { pricing: "text-box" } },
                        [
                          _vm.entryState == "signup"
                            ? [
                                _vm._v("An account associated with the email "),
                                _c("span", { attrs: { pricing: "bold" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formSignup.email || _vm.userEmail
                                    )
                                  ),
                                ]),
                                _vm._v(" has been created successfully"),
                              ]
                            : _vm.entryState == "login"
                            ? [
                                _vm._v(
                                  "You've successfully logged in to the account associated with the email "
                                ),
                                _c("span", { attrs: { pricing: "bold" } }, [
                                  _vm._v(
                                    _vm._s(_vm.formLogin.email || _vm.userEmail)
                                  ),
                                ]),
                              ]
                            : [
                                _vm._v(
                                  "Your account has been successfully associated with this subscription."
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "When you complete this order, your subscription will appear in the Subscriptions section in the ZingSoft Studio."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "You can manage your licensed domain and subdomain in the Subscriptions section anytime."
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr", { attrs: { pricing: "divider--vertical" } }),
                  _vm._v(" "),
                  _c("div", { attrs: { pricing: "confirmation--flex-1" } }, [
                    _c("h2", { attrs: { pricing: "h2" } }, [
                      _vm._v(
                        "Confirm License " +
                          _vm._s(_vm.renewalProcess ? "Renewal" : "") +
                          " Details"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr", { attrs: { pricing: "divider--horizontal" } }),
                    _vm._v(" "),
                    _c("div", { attrs: { pricing: "text--light" } }, [
                      _c("p", [
                        _c("span", { attrs: { pricing: "bold--primary" } }, [
                          _vm._v("Subscription: "),
                        ]),
                        _vm._v(_vm._s(_vm.plan && _vm.plan.name) + " License"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { attrs: { pricing: "bold--primary" } }, [
                          _vm._v("Name: "),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formPricing.nameFirst) +
                            " " +
                            _vm._s(_vm.formPricing.nameLast)
                        ),
                      ]),
                      _vm._v(" "),
                      !_vm.renewalProcess
                        ? _c("p", [
                            _c(
                              "span",
                              { attrs: { pricing: "bold--primary" } },
                              [_vm._v("Licensed Domain:")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formPricing.domainOptional) +
                                " (you can edit this later)"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.renewalProcess
                        ? _c("p", [
                            _c(
                              "span",
                              { attrs: { pricing: "bold--primary" } },
                              [_vm._v("Licensed Subdomain:")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formPricing.subdomainOptional) +
                                " (you can edit this later)"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { attrs: { pricing: "bold--primary" } }, [
                          _vm._v("Company: "),
                        ]),
                        _vm._v(_vm._s(_vm.formPricing.company)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { attrs: { pricing: "bold--primary" } }, [
                          _vm._v("Credit Card Number: "),
                        ]),
                        _vm._v(
                          "**** **** **** " +
                            _vm._s(
                              _vm.formPricing.cardNumber.substring(
                                _vm.formPricing.cardNumber.length - 4
                              )
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", { attrs: { pricing: "bold--primary" } }, [
                          _vm._v("Credit Card Expiration: "),
                        ]),
                        _vm._v(" " + _vm._s(_vm.formPricing.cardExpiration)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { pricing: "button-flex" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { plain: "", type: "danger" },
                            on: {
                              click: function ($event) {
                                _vm.view = _vm.view - 2
                              },
                            },
                          },
                          [_vm._v("Edit Info")]
                        ),
                        _vm._v(" "),
                        _vm.renewalProcess
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.hasUser,
                                  loading: _vm.placingOrder,
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.placeOrder("renew")
                                  },
                                },
                              },
                              [_vm._v("Renew")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.hasUser,
                                  loading: _vm.placingOrder,
                                  type: "primary",
                                },
                                on: { click: _vm.placeOrder },
                              },
                              [_vm._v("Place Order")]
                            ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.view === 3
              ? _c(
                  "div",
                  {
                    attrs: {
                      pricing:
                        "container--small--box--center--flex-column--nowrap",
                    },
                  },
                  [
                    _c("h2", { attrs: { pricing: "h2" } }, [
                      _vm._v(
                        "Your subscription is now " +
                          _vm._s(_vm.renewalProcess ? "renewed" : "active") +
                          "!"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr", { attrs: { pricing: "divider--horizontal" } }),
                    _vm._v(" "),
                    _vm.entryState
                      ? [
                          _c(
                            "p",
                            {
                              attrs: { pricing: "entry-text--bold--highlight" },
                            },
                            [_vm._v("Payment successfully processed.")]
                          ),
                          _vm._v(" "),
                          _c("p", { attrs: { pricing: "entry-text" } }, [
                            _vm._v("Thanks for your purchase."),
                          ]),
                          _vm._v(" "),
                          _c("p", { attrs: { pricing: "entry-text" } }, [
                            _vm._v(
                              "Visit the Studio to manage your subscription."
                            ),
                          ]),
                        ]
                      : [
                          _c("p", { attrs: { pricing: "entry-text" } }, [
                            _vm._v("Thanks for your purchase."),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              attrs: { pricing: "entry-text--bold--highlight" },
                            },
                            [
                              _vm._v(
                                "Your payment has been processed successfully."
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(1),
                        ],
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { pricing: "button-flex" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.openTab("account")
                              },
                            },
                          },
                          [_vm._v("View Account")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              pricing: "button-secondary",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openTab("subscriptions")
                              },
                            },
                          },
                          [_vm._v("Manage License")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("reset-modal", {
        attrs: { embed: "false", visible: _vm.resetModalVisible },
        on: {
          close: function ($event) {
            _vm.resetModalVisible = false
          },
          "update:visible": function ($event) {
            _vm.resetModalVisible = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "my-dialog",
        {
          attrs: {
            title: "Please enable 3rd Party Cookies",
            type: "danger",
            visible: _vm.privacyModalVisible,
          },
        },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c("p", [
              _vm._v(
                "To continue checkout process, please change your browser settings to allow third-party cookies."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "options" }, slot: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.checkPrivacySettings(true)
                    },
                  },
                },
                [_vm._v("Reload Page")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "my-dialog",
        {
          attrs: {
            title: "Your Purchase Could Not Be Completed",
            type: "danger",
            visible: _vm.supportModalVisible,
          },
          on: {
            close: function ($event) {
              _vm.supportModalVisible = false
            },
          },
        },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c("p", [
              _vm._v(
                "A sales representative will later contact and assist you."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Or contact us at "),
              _c("a", { attrs: { href: "mailto:sales@zingsoft.com" } }, [
                _vm._v("sales@zingsoft.com"),
              ]),
              _vm._v("."),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "options" }, slot: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.supportModalVisible = false
                    },
                  },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("license-purchased-dialog", {
        attrs: {
          visible: _vm.licensePurchasedDialogVisible,
          plan: _vm.planInterval,
          domains: _vm.formPricing ? [_vm.formPricing.domainOptional] : [],
          subdomains: _vm.formPricing ? [_vm.formPricing.domainOptional] : [],
          userId: _vm.userId,
        },
        on: {
          close: function ($event) {
            _vm.licensePurchasedDialogVisible = false
          },
          renew: function ($event) {
            _vm.renewalProcess = true
          },
          purchase: function ($event) {
            return _vm.placeOrder("purchase")
          },
          "update:visible": function ($event) {
            _vm.licensePurchasedDialogVisible = $event
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "none" } }, [
        _vm._v(
          _vm._s(_vm.userPopupAuth) +
            _vm._s(_vm.userPopupAuthSignup) +
            _vm._s(_vm.setupEntry)
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { attrs: { pricing: "bold--primary" } }, [
        _vm._v("Cread Card CVC: "),
      ]),
      _vm._v("***"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { attrs: { pricing: "entry-text" } }, [
      _c("a", { attrs: { href: "/account/subscriptions", target: "_blank" } }, [
        _vm._v("Visit the Studio"),
      ]),
      _vm._v(" to manage your subscription."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }