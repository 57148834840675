var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Page Speed Test"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "section",
          { staticClass: "zingnetCard--row" },
          _vm._l(_vm.pages, function (page) {
            return _c(
              "section",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: page.loading,
                    expression: "page.loading",
                  },
                ],
                key: `pageSpeed-${page.site}`,
                staticClass: "zingnetCard--column",
              },
              [_c("div", { attrs: { id: `chart-pageSpeed-${page.site}` } })]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }