var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "secondary--section",
      attrs: {
        authing: _vm.isAuthing,
        noJustify: _vm.noJustify,
        slim: _vm.slim,
      },
    },
    [
      _c("div", { staticClass: "background--grid_wrap" }, [
        _c(
          "div",
          { staticClass: "background--grid content__wrap" },
          [
            !_vm.slim ? _c("secondary-nav") : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content_wrapper" },
              [_vm._t("default")],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.slim
        ? _c("app-footer", { attrs: { footer: "secondary" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }