var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "my-dialog": "",
        licensePurchaseDialog: "",
        visible: _vm.isVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
      },
    },
    [
      _vm.phase1
        ? _c("div", [
            _c("h2", [_vm._v("License Already Purchased")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "It looks like you already own a " +
                  _vm._s(_vm.planName) +
                  " license."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Are you trying to renew your existing license or purchase another one?"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialog__controls" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.buySubscription()
                      },
                    },
                  },
                  [_vm._v("Continue Purchase")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.continueRenewSubscription()
                      },
                    },
                  },
                  [_vm._v("Renew")]
                ),
              ],
              1
            ),
          ])
        : _c("div", [
            _c("h2", [_vm._v("Renew License")]),
            _vm._v(" "),
            _c("p", [_vm._v("Your purchase has been updated to a renewal.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                'In the next view, review the updated information and click the "Renew" button.'
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dialog__controls" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.continueRenewal()
                      },
                    },
                  },
                  [_vm._v("Continue Renewal")]
                ),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }