var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app-shell" } },
    [
      _vm.isLogout ? [_c("logout-view")] : _vm._e(),
      _vm._v(" "),
      _vm.socialSignup && !_vm.hasUser && _vm.name
        ? [_c("login-view", { attrs: { social: true, name: _vm.name } })]
        : _vm.isEmailVerify
        ? [_c("email-verify")]
        : _vm.isSubscribing
        ? [_c("subscribe-view")]
        : _vm.authenticated && _vm.hasUser
        ? [_c("app")]
        : _vm.isDemosCreate && !_vm.hasUser && !_vm.authenticated
        ? [_c("demos-create-view")]
        : _vm.isForgot
        ? [_c("reset-password")]
        : _vm.isSiteLogin ||
          (_vm.isLogin && !_vm.authenticated && !_vm.signupProcess)
        ? [
            _c("login-view", {
              attrs: { isAuthing: _vm.localStorageAuthState },
            }),
          ]
        : _vm.isSignup
        ? [
            _c("signup-view", {
              attrs: { isAuthing: _vm.localStorageAuthState },
            }),
          ]
        : _vm.isUnsuccessful
        ? [
            _c("unsuccessful-view", {
              attrs: { isAuthing: _vm.localStorageAuthState },
            }),
          ]
        : _vm.isError
        ? [_c("page-warning")]
        : [_c("div")],
      _vm._v(" "),
      _c(
        "my-dialog",
        {
          attrs: {
            title: "New Version Available",
            type: "warning",
            "show-close": false,
            visible: _vm.chunkWarningVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.chunkWarningVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c("p", [
              _vm._v(
                "A new version of the app is available. Please reload page to continue."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "options" }, slot: "options" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.reloadPage } },
                [_vm._v("Reload")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }