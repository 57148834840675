var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: _vm.classname, style: _vm.after ? "margin-right: auto;" : "" },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.label ? ` ${_vm.label}` : _vm.label) +
          _vm._s(_vm.displayValue) +
          _vm._s(_vm.labelRight ? ` ${_vm.labelRight}` : _vm.labelRight) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }