var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "collapse--container" }, [
    _c(
      "div",
      { staticClass: "collapse--header" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _vm.buttonView
          ? _c("el-button", { on: { click: _vm.hide } }, [
              _vm._v("Hide " + _vm._s(_vm.buttonText || "Options")),
            ])
          : _c("el-button", { on: { click: _vm.view } }, [
              _vm._v("View " + _vm._s(_vm.buttonText || "Options")),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: `collapse--body${_vm.toCollapse ? " collapse" : ""}` },
      [_vm._t("body")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }