var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "plan__list" }, [
    _vm.updating
      ? _c("h4", [
          _vm._v("Update your domains to the subscription. You have "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.countHTML) } }),
          _vm._v(" free update(s) left."),
        ])
      : _c("h4", [
          _vm._v(
            "Add your domains to the subscription. This can also be modified at a later date."
          ),
        ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "plan-domains" },
      [
        _c("header", { staticClass: "plan-domains__header" }, [
          _c("h4", [
            _vm._v(
              "Domains (" +
                _vm._s(_vm.domains.length) +
                " / " +
                _vm._s(
                  _vm.activePlanMaxDomains &&
                    _vm.activePlanMaxDomains === "unlimited"
                    ? "unlimited"
                    : `${_vm.activePlanMaxDomains} used`
                ) +
                ") - " +
                _vm._s(_vm.optional ? "optional" : "requires at least one")
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "plan-domains__container",
            attrs: { "data-domain-scroll": "" },
          },
          _vm._l(_vm.domains, function (domain, index) {
            return _c(
              "section",
              { key: `domain${index}`, staticClass: "plan-domains__list" },
              [
                _c("div", { staticClass: "plan-domains__count" }, [
                  _vm._v(_vm._s(index + 1)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "plan-domains__url" }, [
                  _vm._v(_vm._s(_vm.getDomain(domain))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "plan-domains__delete",
                    on: {
                      click: function ($event) {
                        return _vm.removeNewDomain(domain)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "trash-alt"], size: "1x" },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "dialogDomainForm",
            attrs: { model: _vm.form },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.activePlanMaxDomains &&
                      (_vm.domains.length < _vm.activePlanMaxDomains ||
                        _vm.activePlanMaxDomains === "unlimited"),
                    expression:
                      "activePlanMaxDomains && (domains.length < activePlanMaxDomains || activePlanMaxDomains === 'unlimited')",
                  },
                ],
                staticClass: "plan-domains__add",
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "dialogNewDomain",
                      rules: _vm.validateDomain(),
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "text", autofocus: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleDialogAddDomain(false)
                        },
                      },
                      model: {
                        value: _vm.form.dialogNewDomain,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dialogNewDomain", $$v)
                        },
                        expression: "form.dialogNewDomain",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDialogAddDomain(false)
                          },
                        },
                      },
                      [_vm._v("Add")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("header", { staticClass: "plan-domains__header" }, [
          _c("h4", [
            _vm._v(
              "Subdomains (" +
                _vm._s(_vm.subdomains.length) +
                " / " +
                _vm._s(
                  _vm.activePlanMaxSubdomains &&
                    _vm.activePlanMaxSubdomains === "unlimited"
                    ? "unlimited"
                    : `${_vm.activePlanMaxSubdomains} used`
                ) +
                ") - " +
                _vm._s(_vm.optional ? "optional" : "requires at least one")
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "plan-domains__container",
            attrs: { "data-subdomain-scroll": "" },
          },
          _vm._l(_vm.subdomains, function (subdomain, index) {
            return _c(
              "section",
              { key: `subdomain${index}`, staticClass: "plan-domains__list" },
              [
                _c("div", { staticClass: "plan-domains__count" }, [
                  _vm._v(_vm._s(index + 1)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "plan-domains__url" }, [
                  _vm._v(_vm._s(_vm.getDomain(subdomain))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "plan-domains__delete",
                    on: {
                      click: function ($event) {
                        return _vm.removeNewDomain(subdomain, true)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "trash-alt"], size: "1x" },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "dialogSubdomainForm",
            attrs: { model: _vm.form },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.activePlanMaxSubdomains &&
                      (_vm.subdomains.length < _vm.activePlanMaxSubdomains ||
                        _vm.activePlanMaxSubdomains === "unlimited"),
                    expression:
                      "activePlanMaxSubdomains && (subdomains.length < activePlanMaxSubdomains || activePlanMaxSubdomains === 'unlimited')",
                  },
                ],
                staticClass: "plan-domains__add",
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "dialogNewSubdomain",
                      rules: _vm.validateSubdomain(),
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "text", autofocus: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleDialogAddDomain(true)
                        },
                      },
                      model: {
                        value: _vm.form.dialogNewSubdomain,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dialogNewSubdomain", $$v)
                        },
                        expression: "form.dialogNewSubdomain",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDialogAddDomain(true)
                          },
                        },
                      },
                      [_vm._v("Add")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "plan-alerts" },
          [
            _vm.exceedDomainLimit
              ? _c("el-alert", {
                  attrs: {
                    title: "Number of domains exceeded",
                    description: "Please remove domain(s) or upgrade license.",
                    closable: false,
                    type: "error",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.exceedSubdomainLimit
              ? _c("el-alert", {
                  attrs: {
                    title: "Number of subdomains exceeded",
                    description:
                      "Please remove subdomain(s) or upgrade license.",
                    closable: false,
                    type: "error",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.exceedDomainUpdate
              ? _c("el-alert", {
                  attrs: {
                    title: "Number of updated exceeded",
                    description:
                      "Please contact support@zingsoft.com to request further domain updates.",
                    closable: false,
                    type: "error",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.notSubdomain
              ? _c("el-alert", {
                  attrs: {
                    title: "Not a subdomain",
                    description: `${_vm.subdomain} is not a subdomain of given domain(s).`,
                    closable: false,
                    type: "error",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.missingDomains
              ? _c("el-alert", {
                  attrs: {
                    title: "Please register a domain",
                    description:
                      "It is required to register at least one domain.",
                    closable: false,
                    type: "warning",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "plan-dialog__footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "success", plain: "" },
            on: { click: _vm.cancelUpdateDomains },
          },
          [_vm._v(_vm._s(_vm.cancelText))]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: {
              type: "success",
              disabled:
                _vm.disable ||
                _vm.exceedDomainLimit ||
                _vm.exceedSubdomainLimit ||
                _vm.exceedDomainUpdate ||
                _vm.missingDomains ||
                _vm.notSubdomain,
              loading: _vm.loading,
            },
            on: { click: _vm.updateDomains },
          },
          [_vm._v("\n      " + _vm._s(_vm.submitText))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }