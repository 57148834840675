var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group_wrapper" },
    [
      _c("group-filter-selector", {
        ref: "filterSelector",
        attrs: {
          defaultOptions: _vm.defaultOptions,
          getCount: _vm.getCount,
          options: _vm.myGroups,
          selected: _vm.selected,
        },
        on: {
          group: _vm.filter,
          "update:options": function ($event) {
            _vm.myGroups = $event
          },
          "update:selected": function ($event) {
            _vm.selected = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }