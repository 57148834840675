var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form__item" },
    [
      _c("label", { staticClass: "form__label", attrs: { id: "location" } }, [
        _vm._v("Billing Address"),
      ]),
      _vm._v(" "),
      _c("el-input", {
        staticClass: "form__field",
        attrs: {
          for: "location",
          maxlength: "200",
          placeholder: "Your Billing Address",
          rows: 2,
          "show-word-limit": true,
          type: "textarea",
        },
        model: {
          value: _vm.location_,
          callback: function ($$v) {
            _vm.location_ = $$v
          },
          expression: "location_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }