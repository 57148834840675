var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      attrs: {
        prop: "email",
        rules: [
          {
            required: true,
            message: "Please input email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input valid email address",
            trigger: "blur",
          },
        ],
      },
    },
    [
      _c(
        "el-input",
        {
          attrs: {
            form: _vm.form,
            placeholder: "Enter your email",
            tabindex: "1",
            type: "email",
          },
          on: {
            change: _vm.handleInput,
            blur: function ($event) {
              return _vm.validate()
            },
          },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v
            },
            expression: "email",
          },
        },
        [
          _c("template", { slot: "prepend" }, [
            _c(
              "svg",
              {
                staticClass: "auth0-lock-icon",
                attrs: {
                  width: "14",
                  height: "13",
                  viewBox: "0 0 32 26",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M30 23c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h26c.55 0 1 .45 1 1v20zm0-23H2C.89 0 0 .89 0 2v22a2 2 0 0 0 2 2h28c1.1 0 2-.9 2-2V2a2 2 0 0 0-2-2zm-8 6h4v4h-4V6zm-2 6h8V4h-8v8zM4 16h14v-2H4v2zm0 4h14v-2H4v2z",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }