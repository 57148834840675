var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "content--wide", attrs: { "group-member": "" } },
    [
      _c("h2", [_vm._v("Groups you are member of.")]),
      _vm._v(" "),
      _c("p", [_vm._v("Group Name: ")]),
      _vm._v(" "),
      _c("p", [_vm._v("Address: ")]),
      _vm._v(" "),
      _c(
        "zing-grid",
        {
          attrs: {
            caption: "Your Permissions",
            "group-member": "permissions",
            layout: "row",
            data: '[\n      { "feature": "read", "permission": true },\n      { "feature": "write", "permission": true },\n      { "feature": "billing", "permission": false },\n      { "feature": "support", "permission": false },\n      { "feature": "admin", "permission": false }\n    ]',
          },
        },
        [
          _c(
            "zg-colgroup",
            [
              _c("zg-column", { attrs: { index: "feature" } }),
              _vm._v(" "),
              _c("zg-column", {
                attrs: { index: "permission", renderer: "renderToggle" },
              }),
              _vm._v(" "),
              _c("zg-column", {
                attrs: {
                  index: "permission",
                  header: " ",
                  renderer: "renderRequestButton",
                  width: "200",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }