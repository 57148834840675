var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "demo-cards",
      attrs: { mode: _vm.mode, template: _vm.template ? _vm.template : false },
    },
    [
      _c("header", { staticClass: "demo-controls" }, [
        _c("div", { staticClass: "demo-controls__lvl1" }, [
          _c("div", { staticClass: "demo-controls__left" }, [
            _vm.adminDashboardView && !_vm.templateType
              ? _c(
                  "div",
                  { staticClass: "demo-control__item" },
                  [
                    _c("group-selector", {
                      ref: "groupSelector",
                      attrs: {
                        groupBy: _vm.group,
                        template: _vm.templateType,
                        type: _vm.zingType ? _vm.demoType : null,
                      },
                      on: {
                        "update:groupBy": function ($event) {
                          _vm.group = $event
                        },
                        "update:group-by": function ($event) {
                          _vm.group = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "demo-control__item" },
              [
                _c("tag-selector", {
                  ref: "tagSelector",
                  attrs: {
                    filterBy: _vm.tag,
                    group: _vm.group,
                    template: _vm.templateType,
                    type: _vm.zingType ? _vm.demoType : null,
                  },
                  on: {
                    "update:filterBy": function ($event) {
                      _vm.tag = $event
                    },
                    "update:filter-by": function ($event) {
                      _vm.tag = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "demo-control__item" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select",
                      disabled: _vm.template && _vm.zingType,
                    },
                    model: {
                      value: _vm.sortBy,
                      callback: function ($$v) {
                        _vm.sortBy = $$v
                      },
                      expression: "sortBy",
                    },
                  },
                  _vm._l(_vm.sortOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("svg-icon", {
                  staticClass: "el-input-icon",
                  attrs: { icon: "angle" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "demo-controls__right" }, [
            _c(
              "div",
              { staticClass: "demo-control__item demo-control__item--search" },
              [
                _c("el-input", {
                  attrs: {
                    search: "",
                    placeholder: "Search By...",
                    maxlength: "75",
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.checkSearchStr.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.watchSearch,
                    callback: function ($$v) {
                      _vm.watchSearch = $$v
                    },
                    expression: "watchSearch",
                  },
                }),
                _vm._v(" "),
                _c("svg-icon", {
                  staticClass: "el-input-icon",
                  attrs: { icon: "search" },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.adminDashboardView
          ? _c("div", { staticClass: "demo-controls__lvl2" }, [
              _c(
                "div",
                { staticClass: "demo-control__item" },
                [
                  _c("metadata-selector", {
                    attrs: { type: "metadata", metadataBy: _vm.metadataType },
                    on: {
                      "update:metadataBy": function ($event) {
                        _vm.metadataType = $event
                      },
                      "update:metadata-by": function ($event) {
                        _vm.metadataType = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "demo-control__item" },
                [
                  _c("metadata-selector", {
                    attrs: {
                      type: "value",
                      metadata: _vm.metadataType,
                      metadataBy: _vm.metadataValue,
                      scrollable: true,
                      demoType: _vm.zingType ? _vm.demoType : null,
                    },
                    on: {
                      "update:metadataBy": function ($event) {
                        _vm.metadataValue = $event
                      },
                      "update:metadata-by": function ($event) {
                        _vm.metadataValue = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "zing-grid-wrapper" },
        [
          _c("div", { staticClass: "custom-load" }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden" },
            domProps: { value: _vm.preDemoSrc },
          }),
          _vm._v(" "),
          _c(
            "zing-grid",
            {
              attrs: {
                "demo-viewer": "",
                layout: "card",
                "layout-controls": "disabled",
                pager: "",
                "page-size": _vm.updatePageSize("size"),
                "page-size-options": _vm.pageSizeOptions,
                loading: "",
              },
            },
            [
              _c(
                "zg-data",
                [
                  _c("zg-param", {
                    attrs: { name: "headers", value: _vm.headerVal },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "src", value: _vm.demoSrc },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "recordPath", value: "results" },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: {
                      name: "createOptions",
                      value: '{"exclude": true}',
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: {
                      name: "deleteOptions",
                      value: '{"exclude": true}',
                    },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "loadByPage", value: "true" },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "countPath", value: "size" },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "limitToKey", value: "limit" },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "limitToValue", value: _vm.pageSize },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "startAtKey", value: "start" },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "nextPath", value: "_links.next" },
                  }),
                  _vm._v(" "),
                  _c("zg-param", {
                    attrs: { name: "prevPath", value: "_links.prev" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-colgroup",
                [
                  _c("zg-column", {
                    attrs: {
                      index:
                        "uid, created, title, image, tags, last_updated, description, template_type, thumbnail_image",
                      header: " ",
                      renderer: "renderCard",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("demo-settings", {
        attrs: {
          demo: _vm.demo,
          settings: ["general", "share", "template"],
          settingsVisible: _vm.settingsVisible,
        },
        on: {
          save: _vm.saveDemo,
          "toggle-visibility": function ($event) {
            _vm.settingsVisible = !_vm.settingsVisible
          },
          "update-demo": _vm.updateDemoData,
          "update:demo": function ($event) {
            _vm.demo = $event
          },
          "update:settingsVisible": function ($event) {
            _vm.settingsVisible = $event
          },
          "update:settings-visible": function ($event) {
            _vm.settingsVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }