var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zingnet-card", [
    _c("span", { attrs: { slot: "header" }, slot: "header" }, [
      _vm._v("Licenses"),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { slot: "header__right" }, slot: "header__right" }, [
      _c(
        "span",
        { staticClass: "input-wrapper" },
        [
          _c("el-input", {
            attrs: { placeholder: "Search", search: "" },
            on: { change: _vm.searchGrid },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _vm._v(" "),
          _c("svg-icon", {
            staticClass: "el-input-icon",
            attrs: { icon: "search2", fill: "var(--color-primary-gray)" },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("p", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
      _vm._v(
        "\n    A brief breakdown of the licenses currently in our system."
      ),
      _c("br"),
      _vm._v(
        "\n    The account, admin@zingsoft.com, carries licenses of clients not currently registered to ZingSoft Studio."
      ),
      _c("br"),
      _vm._v(
        "\n    NOTE: Do not open items on Quickbooks when making edits to this table!!!"
      ),
      _c("br"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _c(
          "div",
          {
            attrs: { "zg-custom-creator": "" },
            on: {
              click: function ($event) {
                _vm.licenseModalVisible = true
              },
            },
          },
          [_c("svg-icon", { attrs: { icon: "create", fill: "#fff" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "zing-grid",
          {
            ref: "licensingGrid",
            attrs: {
              "cell-class": "_renderNA",
              "default-display": "N/A",
              editor: "modal",
              "editor-controls": "remover",
              filter: "",
              layout: "row",
              "layout-controls": "disabled",
              "no-border": "",
              pager: "",
              "page-size": "5",
              "page-size-options": "5,15,50",
              sort: "",
              search: "",
              selector: "",
            },
          },
          [
            _c(
              "zg-colgroup",
              [
                _c("zg-column", {
                  attrs: {
                    index: "type",
                    type: "select",
                    "type-select-options": "zingchart,zinggrid",
                    renderer: "renderProductType",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "created",
                    header: "Created",
                    _type: "date",
                    "_type-date-format": "MM-DD-YYYY hh:mm",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "last_updated",
                    header: "Last Updated",
                    _type: "date",
                    "_type-date-format": "MM-DD-YYYY HH:mm",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "id_user",
                    header: "ID User",
                    sort: "disabled",
                    hidden: "",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", { attrs: { index: "email", header: "User" } }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "quickbooks_invoice_id",
                    header: "Invoice ID",
                    sort: "disabled",
                    type: "url",
                    "type-url-src":
                      "https://c11.qbo.intuit.com/app/invoice?txnId=[[index.quickbooks_invoice_id]]",
                    validation: "string",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "plan",
                    sort: "disabled",
                    editor: "disabled",
                    renderer: "_renderPlan",
                    width: "300px",
                    type: "select",
                    "type-select-options": _vm.licenses,
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "domain", sort: "disabled" },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "sub",
                    header: "Is Subdomain?",
                    type: "boolean",
                    sort: "disabled",
                    renderer: "renderBoolean",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "update", type: "number", sort: "disabled" },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "expires", type: "date", sort: "disabled" },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: {
                    index: "unsubscribed",
                    header: "Is Unsubscribed?",
                    type: "boolean",
                    sort: "disabled",
                    renderer: "renderBoolean",
                  },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "license", sort: "disabled" },
                }),
                _vm._v(" "),
                _c("zg-column", {
                  attrs: { index: "notes", editor: "textareaEditor" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "zg-data",
              [
                _c("zg-param", { attrs: { name: "src", value: _vm.srcValue } }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "headers", value: _vm.token },
                }),
                _vm._v(" "),
                _c("zg-param", { attrs: { name: "idKey", value: "id" } }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "newIndexPath", value: "id" },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: {
                    name: "createCustomFunction",
                    value: "customCreate",
                  },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: { name: "updateCellMethod", value: "POST" },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: {
                    name: "updateCellCustomFunction",
                    value: "customUpdate",
                  },
                }),
                _vm._v(" "),
                _c("zg-param", {
                  attrs: {
                    name: "updateRowCustomFunction",
                    value: "customUpdate",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("license-modal", {
          attrs: { visible: _vm.licenseModalVisible },
          on: {
            close: function ($event) {
              _vm.licenseModalVisible = false
            },
            "update:visible": function ($event) {
              _vm.licenseModalVisible = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }