var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { class: _vm.dummy ? "" : "demo__card", attrs: { "demo-viewer": "" } },
    [
      _vm.dummy
        ? _c(
            "div",
            {
              staticClass: "demo__card demo__card--dummy demo__card--start",
              on: {
                click: function ($event) {
                  return _vm.$emit("update-view")
                },
              },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "button demo__button demo__button--dummy not-hover-hide list-hide",
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "13",
                        height: "13",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z",
                          fill: "#2BBEEE",
                        },
                      }),
                    ]
                  ),
                  _vm._v("\n      Create a Demo\n    "),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "demo__card demo__header grid-hide" }, [
                _c("div", { staticClass: "demo__header__right" }, [
                  _c("h3", { staticClass: "demo__title" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "demo__icon",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "#2BBEEE",
                          width: "13",
                          height: "13",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z",
                          },
                        }),
                      ]
                    ),
                    _vm._v("\n          Create a Demo\n        "),
                  ]),
                ]),
              ]),
            ]
          )
        : _c(
            "div",
            {
              on: {
                click: function ($event) {
                  _vm.template
                    ? _vm.delegateActions("fork", _vm.demo)
                    : _vm.goToDemo(_vm.demo, $event)
                },
              },
            },
            [
              _c("div", { staticClass: "demo__header" }, [
                _c("div", { staticClass: "demo__header__right" }, [
                  _c("h3", { staticClass: "demo__title" }, [
                    _c("span", { staticClass: "demo__icon demo__icon--open" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "demo__icon__svg boxed",
                          attrs: {
                            fill: "#0D80A5",
                            width: "12",
                            height: "12",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "demo__title__text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.demo && _vm.demo.title
                            ? _vm.demo.title
                            : "Untitled Demo"
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.template
                    ? _c("h4", { staticClass: "demo__description list-hide" }, [
                        _vm._v(_vm._s(_vm.demo ? _vm.demo.description : null)),
                      ])
                    : _c("h4", { staticClass: "demo__date mobile--hide" }, [
                        _c("span", { staticClass: "tablet--hide" }, [
                          _vm._v("Last edited "),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(
                              _vm.demo && _vm.demo.date ? _vm.demo.date : ""
                            )
                          )
                        ),
                      ]),
                ]),
                _vm._v(" "),
                !_vm.template
                  ? _c("div", { staticClass: "demo__header__left" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-dropdown desktop-lg--hide",
                          on: {
                            click: function ($event) {
                              return _vm.toggle($event)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "el-dropdown-link el-dropdown-selfdefine focusing",
                              attrs: {
                                "aria-haspopup": "list",
                                "aria-controls": "dropdown-menu",
                                role: "button",
                                tabindex: "0",
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "demo__icon",
                                  attrs: {
                                    fill: "#ABB2B9",
                                    width: "14",
                                    height: "4",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M1.75 0C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0zm10.5 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75S14 2.712 14 1.75C14 .787 13.213 0 12.25 0zM7 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75s1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass: "el-dropdown-menu el-popper",
                              staticStyle: {
                                "transform-origin": "center top",
                                "z-index": "2008",
                                width: "110px",
                              },
                            },
                            [
                              _c(
                                "li",
                                {
                                  staticClass: "el-dropdown-menu__item",
                                  attrs: { tabindex: "-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "edit",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        right: "2px",
                                        top: "3px",
                                      },
                                      attrs: {
                                        width: "15",
                                        height: "15",
                                        fill: "#fff",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "edit",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm.75 11.25h-1.5v-4.5h1.5v4.5zm0-6h-1.5v-1.5h1.5v1.5z",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              Edit Info\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass: "el-dropdown-menu__item",
                                  attrs: { tabindex: "-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "fork",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "svg-inline--fa fa-code-branch fa-w-12 fa-1x",
                                      staticStyle: {
                                        color: "rgb(175, 175, 175)",
                                      },
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-prefix": "fas",
                                        "data-icon": "code-branch",
                                        role: "img",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 384 512",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "fork",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v("\n              Fork\n            "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass: "el-dropdown-menu__item",
                                  attrs: { tabindex: "-1" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "delete",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "svg-inline--fa fa-trash-alt fa-w-14 fa-1x",
                                      staticStyle: {
                                        color: "rgb(175, 175, 175)",
                                      },
                                      attrs: {
                                        "aria-hidden": "true",
                                        "data-prefix": "fas",
                                        "data-icon": "trash-alt",
                                        role: "img",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 448 512",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "delete",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              Delete\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "zg-button",
                                {
                                  staticClass: "el-dropdown-menu__item",
                                  attrs: { action: "removerecord" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "edit",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "zg-icon",
                                    {
                                      attrs: { slot: "icon", name: "" },
                                      slot: "icon",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "svg-inline--fa fa-trash-alt fa-w-14 fa-1x",
                                          staticStyle: {
                                            color: "rgb(175, 175, 175)",
                                          },
                                          attrs: {
                                            "aria-hidden": "true",
                                            "data-prefix": "fas",
                                            "data-icon": "trash-alt",
                                            role: "img",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 448 512",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              Delete\n            "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "popper__arrow",
                                attrs: { "x-arrow": "" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "demo__header__left--list" },
                  [
                    _vm.demo.tags
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "tag-container desktop-sm--hide tablet--hide mobile--hide",
                          },
                          [
                            _vm._l(
                              _vm.demo.tags.slice(0, 4),
                              function (tag, index) {
                                return [
                                  index < 3
                                    ? _c(
                                        "span",
                                        { key: tag.name, staticClass: "tag" },
                                        [_vm._v(_vm._s(tag.name))]
                                      )
                                    : _c("span", [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "tag-button el-button el-button--text el-popover__reference",
                                            staticStyle: {
                                              position: "relative",
                                            },
                                            attrs: {
                                              type: "button",
                                              tabindex: "0",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggle($event)
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.demo.tags.length - 3
                                                ) + " more..."
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "el-dropdown-menu el-popper",
                                                staticStyle: {
                                                  "transform-origin":
                                                    "center top",
                                                  "z-index": "2008",
                                                  "text-align": "center",
                                                  width: "fit-content",
                                                },
                                              },
                                              [
                                                _vm._l(
                                                  _vm.demo.tags.slice(3),
                                                  function (tag2) {
                                                    return _c("li", {
                                                      key: tag2.name,
                                                      staticClass:
                                                        "el-dropdown-menu__item",
                                                      attrs: { tabindex: "-1" },
                                                    })
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "popper__arrow",
                                                  attrs: { "x-arrow": "" },
                                                }),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ]),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.demo.tags && _vm.demo.tags.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "el-dropdown desktop-lg--hide",
                            on: {
                              click: function ($event) {
                                return _vm.toggle($event)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "el-dropdown-link el-dropdown-selfdefine focusing",
                                attrs: {
                                  "aria-haspopup": "list",
                                  "aria-controls": "dropdown-menu",
                                  role: "button",
                                  tabindex: "0",
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "tags"], size: "1x" },
                                }),
                                _c("br"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass: "el-dropdown-menu el-popper",
                                staticStyle: {
                                  "transform-origin": "center top",
                                  "z-index": "2008",
                                  "text-align": "center",
                                  width: "fit-content",
                                },
                              },
                              [
                                _vm._l(_vm.demo.tags, function (tag) {
                                  return _c(
                                    "li",
                                    {
                                      key: tag.name,
                                      staticClass: "el-dropdown-menu__item",
                                      attrs: { tabindex: "-1" },
                                    },
                                    [
                                      _c("span", { staticClass: "tag" }, [
                                        _vm._v(_vm._s(tag.name)),
                                      ]),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "popper__arrow",
                                  attrs: { "x-arrow": "" },
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.template
                      ? _c(
                          "h4",
                          {
                            staticClass:
                              "demo__description mobile--hide tablet-hide",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.demo ? _vm.demo.description : null)
                            ),
                          ]
                        )
                      : [
                          _c(
                            "div",
                            {
                              staticClass:
                                "button-container desktop-sm--hide tablet--hide mobile--hide",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "edit",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "14",
                                        viewBox: "0 0 8 9",
                                        fill: "#fff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "edit",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M4 .5c-2.208 0-4 1.792-4 4s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm.4 6h-.8V4.1h.8v2.4zm0-3.2h-.8v-.8h.8v.8z",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              Edit Info\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "fork",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "12",
                                        height: "16",
                                        fill: "#fff",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "fork",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M12 4.5a2.5 2.5 0 1 0-3.203 2.4c-.019.503-.131.89-.344 1.153-.481.6-1.54.7-2.662.803-.882.082-1.794.169-2.541.528v-4.5A2.499 2.499 0 0 0 2.5 0a2.5 2.5 0 0 0-.75 4.884v6.229A2.506 2.506 0 0 0 0 13.5a2.5 2.5 0 1 0 3.4-2.331 1.1 1.1 0 0 1 .466-.419c.506-.256 1.262-.325 2.065-.4 1.319-.122 2.813-.262 3.694-1.356.438-.544.66-1.244.675-2.122A2.51 2.51 0 0 0 12 4.5zM2.5 2c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm0 12a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5s.5.225.5.5-.225.5-.5.5zm7-10c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "  \n              Fork\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delegateActions(
                                        "delete",
                                        _vm.demo
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "12",
                                        height: "15",
                                        fill: "#fff",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "delete",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M0 2.362v-.787C0 1.201.287.9.643.9h3l.252-.526A.633.633 0 0 1 4.468 0h3.061a.64.64 0 0 1 .576.374L8.357.9h3c.356 0 .643.3.643.675v.787a.33.33 0 0 1-.321.338H.32A.33.33 0 0 1 0 2.362zm11.143 1.575v9.113c0 .745-.576 1.35-1.286 1.35H2.143c-.71 0-1.286-.605-1.286-1.35V3.937A.33.33 0 0 1 1.18 3.6h9.642a.33.33 0 0 1 .322.337zM3.857 5.85a.441.441 0 0 0-.428-.45.441.441 0 0 0-.429.45v6.3c0 .248.193.45.429.45a.441.441 0 0 0 .428-.45v-6.3zm2.572 0A.441.441 0 0 0 6 5.4a.441.441 0 0 0-.429.45v6.3c0 .248.193.45.429.45a.441.441 0 0 0 .429-.45v-6.3zM9 5.85a.441.441 0 0 0-.429-.45.441.441 0 0 0-.428.45v6.3c0 .248.193.45.428.45A.441.441 0 0 0 9 12.15v-6.3z",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              Delete\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-dropdown desktop-lg--hide",
                              on: {
                                click: function ($event) {
                                  return _vm.toggle($event)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "el-dropdown-link el-dropdown-selfdefine focusing",
                                  attrs: {
                                    "aria-haspopup": "list",
                                    "aria-controls": "dropdown-menu",
                                    role: "button",
                                    tabindex: "0",
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "demo__icon",
                                      attrs: {
                                        fill: "#ABB2B9",
                                        width: "14",
                                        height: "4",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M1.75 0C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0zm10.5 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75S14 2.712 14 1.75C14 .787 13.213 0 12.25 0zM7 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75s1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass: "el-dropdown-menu el-popper",
                                  staticStyle: {
                                    "transform-origin": "center top",
                                    "z-index": "2008",
                                    width: "110px",
                                  },
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "el-dropdown-menu__item",
                                      attrs: { tabindex: "-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "edit",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticStyle: {
                                            position: "relative",
                                            right: "2px",
                                            top: "3px",
                                          },
                                          attrs: {
                                            width: "15",
                                            height: "15",
                                            fill: "#fff",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delegateActions(
                                                "edit",
                                                _vm.demo
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm.75 11.25h-1.5v-4.5h1.5v4.5zm0-6h-1.5v-1.5h1.5v1.5z",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                Edit Info\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "el-dropdown-menu__item",
                                      attrs: { tabindex: "-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "fork",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "svg-inline--fa fa-code-branch fa-w-12 fa-1x",
                                          staticStyle: {
                                            color: "rgb(175, 175, 175)",
                                          },
                                          attrs: {
                                            "aria-hidden": "true",
                                            "data-prefix": "fas",
                                            "data-icon": "code-branch",
                                            role: "img",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 384 512",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delegateActions(
                                                "fork",
                                                _vm.demo
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                Fork\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "el-dropdown-menu__item",
                                      attrs: { tabindex: "-1" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "delete",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "svg-inline--fa fa-trash-alt fa-w-14 fa-1x",
                                          staticStyle: {
                                            color: "rgb(175, 175, 175)",
                                          },
                                          attrs: {
                                            "aria-hidden": "true",
                                            "data-prefix": "fas",
                                            "data-icon": "trash-alt",
                                            role: "img",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 448 512",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delegateActions(
                                                "delete",
                                                _vm.demo
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                Delete\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "zg-button",
                                    {
                                      staticClass: "el-dropdown-menu__item",
                                      attrs: { action: "removerecord" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delegateActions(
                                            "delete",
                                            _vm.demo
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "zg-icon",
                                        {
                                          attrs: { slot: "icon", name: "" },
                                          slot: "icon",
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              staticClass:
                                                "svg-inline--fa fa-trash-alt fa-w-14 fa-1x",
                                              staticStyle: {
                                                color: "rgb(175, 175, 175)",
                                              },
                                              attrs: {
                                                "aria-hidden": "true",
                                                "data-prefix": "fas",
                                                "data-icon": "trash-alt",
                                                role: "img",
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 448 512",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                Delete\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "popper__arrow",
                                    attrs: { "x-arrow": "" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "demo__body" }, [
                _c("div", { staticClass: "demo__image__wrapper list-hide" }, [
                  _c("img", {
                    staticClass: "demo__image",
                    attrs: {
                      src: _vm.template
                        ? _vm.demoTemplate(
                            _vm.demo && _vm.demo.template_type
                              ? _vm.demo.template_type
                              : null
                          )
                        : _vm.demo && _vm.demo.image
                        ? _vm.demo.image
                        : null,
                      alt: "",
                      onerror: "this.style.display = 'none'",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "demo__image demo__image__default",
                    class: _vm.template
                      ? " demo__image__default--template"
                      : "",
                  },
                  [
                    _c("span", { staticClass: "demo__image__default__text" }, [
                      _vm._v(
                        _vm._s(_vm.template ? "" : "Generating Screenshot")
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "demo__action" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "13",
                        height: "13",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z",
                          fill: "#2BBEEE",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [_vm._v("Create Demo")]),
                ]),
              ]),
            ]
          ),
      _vm._v(" "),
      _c("demo-settings", {
        attrs: {
          demo: _vm.demoSettings,
          settings: ["general", "share", "template", "metadata"],
          settingsVisible: _vm.settingsVisible,
        },
        on: {
          save: _vm.saveDemo,
          "toggle-visibility": function ($event) {
            _vm.settingsVisible = !_vm.settingsVisible
          },
          "update-demo": _vm.updateDemoData,
          "update:demo": function ($event) {
            _vm.demoSettings = $event
          },
          "update:settingsVisible": function ($event) {
            _vm.settingsVisible = $event
          },
          "update:settings-visible": function ($event) {
            _vm.settingsVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "demo__header__left demo__header--dummy demo__header__left--dummy hover-hide list-hide",
      },
      [
        _c("p", { staticClass: "demo__title--dummy" }, [
          _vm._v("Looks like you haven't made any demos yet."),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "demo__text--dummy" }, [
          _vm._v("Want to get started?"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }